<div class="about">
    <div class="position-relative">
        <img alt="Background" src="/assets/img/pages/about.png" class="image" />
        <CDBMask class="mask" overlay="darkLight"></CDBMask>
        <header class="page-header">
            <div class="navigation">
                <CDBNavbar class="px-0" [dark]=true expand="md" [scrolling]=true>
                    <CDBNavBrand href="/">
                        <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                    </CDBNavBrand>
                    <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
                    <CDBCollapse #content [navbar]=true expand="md" class="w-100">
                        <CDBNavbarNav [left]=true>
                            <CDBNavItem>
                                <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                    <CDBNavLink [active]=true style="font-weight: 600;" to="#">Resources</CDBNavLink>
                                </CDBBtn>
                            </CDBNavItem>
                            <CDBNavItem>
                                <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                    <CDBNavLink style="font-weight: 600;" to="#">Blog</CDBNavLink>
                                </CDBBtn>
                            </CDBNavItem>
                            <CDBNavItem>
                                <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                    <CDBNavLink style="font-weight: 600;" to="#">Contact</CDBNavLink>
                                </CDBBtn>
                            </CDBNavItem>
                            <CDBNavItem>
                                <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                    <CDBNavLink style="font-weight: 600;" to="#">Support</CDBNavLink>
                                </CDBBtn>
                            </CDBNavItem>
                        </CDBNavbarNav>
                        <CDBNavbarNav [right]=true>
                            <CDBNavItem class="mr-3 d-flex align-items-center">
                                <CDBNavLink style="font-weight: 600;" to="#">
                                    <CDBIcon [fas]=true class="text-white moon" icon="moon"></CDBIcon>
                                </CDBNavLink>
                            </CDBNavItem>
                            <CDBNavItem [active]=true>
                                <CDBBtn [flat]=true [outline]=true
                                    class="d-flex align-items-center py-1 px-3 m-0 bg-transparent"
                                    style="border-color: #0000cc">
                                    <CDBNavLink style="font-weight: 600;" style="color: #0000cc" to="#">Hi, Sammy
                                    </CDBNavLink>
                                    <CDBIcon style="color: #0000cc" icon="location-arrow"></CDBIcon>
                                </CDBBtn>
                            </CDBNavItem>
                        </CDBNavbarNav>
                    </CDBCollapse>
                </CDBNavbar>
            </div>
            <!-- <CDBContainer class="about-container"> -->
            <div class="container about-container">
                <CDBCardTitle class="big">About Us</CDBCardTitle>
                <CDBCardText class="text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident
                    voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus
                    veniam.
                </CDBCardText>
            </div>
            <!-- </CDBContainer> -->
        </header>
    </div>
    <div class="body">
        <section class="mt-4rem mb-2">
            <CDBCardTitle class="intro-title big">Our Amazing Team</CDBCardTitle>
            <CDBCardText>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident
                voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.
            </CDBCardText>
        </section>

        <section class="mt-4rem card-container">
            <div class="card-group">
                <CDBCard class="card border-0">
                    <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card1.png"></CDBCardImage>
                    <CDBCardTitle class="card-title">Clark Williams</CDBCardTitle>
                    <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                    <CDBCardText [muted]=true>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
                        amet
                        numquam iure provident voluptate esse quasi.</CDBCardText>
                    <div class="mt-3">
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                            <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                        </CDBBtn>
                    </div>
                </CDBCard>
                <CDBCard class="card border-0">
                    <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card2.png"></CDBCardImage>
                    <CDBCardTitle class="card-title">Madison Jane</CDBCardTitle>
                    <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                    <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                        Fugit, error amet numquam iure provident voluptate esse quasi.
                    </CDBCardText>
                    <div class="mt-3">
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                            <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                        </CDBBtn>
                    </div>
                </CDBCard>
                <CDBCard class="card border-0">
                    <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card3.png"></CDBCardImage>
                    <CDBCardTitle class="card-title">Sarina Micheal</CDBCardTitle>
                    <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                    <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                        Fugit, error amet numquam iure provident voluptate esse quasi.
                    </CDBCardText>
                    <div class="mt-3">
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                            <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                        </CDBBtn>
                    </div>
                </CDBCard>
                <CDBCard class="card border-0">
                    <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card4.png"></CDBCardImage>
                    <CDBCardTitle class="card-title">Stephanie Dani</CDBCardTitle>
                    <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                    <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi.
                    </CDBCardText>
                    <div class="mt-3">
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                            <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                        </CDBBtn>
                    </div>
                </CDBCard>
                <CDBCard class="card border-0">
                    <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card5.png"></CDBCardImage>
                    <CDBCardTitle class="card-title">Tiff Amanda</CDBCardTitle>
                    <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                    <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Fugit, error amet numquam iure provident voluptate esse quasi.
                    </CDBCardText>
                    <div class="mt-3">
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                            <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                        </CDBBtn>
                    </div>
                </CDBCard>
                <CDBCard class="card border-0">
                    <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card6.png"></CDBCardImage>
                    <CDBCardTitle class="card-title">Amara Wayne</CDBCardTitle>
                    <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                    <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi.
                    </CDBCardText>
                    <div class="mt-3">
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                            <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                        </CDBBtn>
                        <CDBBtn [flat]=true color="dark" class="p-2">
                            <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                        </CDBBtn>
                    </div>
                </CDBCard>
            </div>
        </section>

        <section class="mt-4rem information align-items-center">
            <div>
                <CDBCardTitle class="mb-4 big">We create awesome products</CDBCardTitle>
                <CDBCardText>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure
                    provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur
                    accusamus veniam.</CDBCardText>
            </div>
            <CDBView>
                <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
            </CDBView>
        </section>
        <section class="mt-4rem">
            <CDBCardTitle class="big contact-title">Want to get in touch?</CDBCardTitle>
            <div class="contact">
                <!-- <CDBContainer class="contact-form"> -->
                <div class="container contact-form">
                    <label htmlFor="contactName" class="text-muted m-0">
                        Name
                    </label>
                    <CDBInput id="contactName" hint="Name" [background]=true class="mt-n3" type="text"></CDBInput>
                    <label htmlFor="contactEmail" class="text-muted m-0">
                        Email Address
                    </label>
                    <CDBInput id="contactEmail" hint="Email Address" [background]=true class="mt-n3" type="email">
                    </CDBInput>
                    <label htmlFor="contactMessage" class="text-muted m-0">
                        Your Message
                    </label>
                    <CDBInput id="contactMessage" height="150px" hint="Type Message" [background]=true class="mt-n3"
                        type="textarea"></CDBInput>
                    <CDBBtn color="dark" class="btn-block">
                        Send Message
                    </CDBBtn>
                </div>
                <!-- </CDBContainer> -->
                <!-- <CDBContainer class="contact-info"> -->
                <div class="container contact-info">
                    <CDBCardTitle class="mb-5">Contact Information</CDBCardTitle>
                    <CDBCardText class="mb-2">
                        <CDBIcon class="mr-5" icon="map-marker-alt"></CDBIcon>New York, 94126 USA
                    </CDBCardText>
                    <CDBCardText class="mb-2">
                        <CDBIcon class="mr-5" icon="phone-alt"></CDBIcon>+ 01 234 567 89
                    </CDBCardText>
                    <CDBCardText class="mb-2">
                        <CDBIcon class="mr-5" icon="comment-alt"></CDBIcon>contact@example.com
                    </CDBCardText>
                </div>
                <!-- </CDBContainer> -->
            </div>
        </section>
    </div>
    <footer class="footer">
        <CDBLink to="/" class="footer-logo">
            <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
            <span class="mb-0 ml-4 h5 font-weight-bold">Devwares</span>
        </CDBLink>
        <small>&copy; Devwares, 2020. All rights reserved.</small>
        <div class="footer-contact">
            <CDBBtn [flat]=true color="dark" class="p-2">
                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
            </CDBBtn>
            <CDBBtn [flat]=true color="dark" class="p-2 mx-3">
                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
            </CDBBtn>
            <CDBBtn [flat]=true color="dark" class="p-2">
                <CDBIcon [fab]=true icon="instagram"></CDBIcon>
            </CDBBtn>
        </div>
    </footer>
</div>