<div [class]="pageClassName">
    <div class="d-flex">
      <div style="height: 100vh; overflow: hidden initial">
        <Sidebar bgColor="#fff" color="#333"></Sidebar>
      </div>
      <div class="text-dark"
        style="
          flex: 1 1 auto;
          display: flex;
          flex-flow: column;
          height: 100vh;
          overflow-y: scroll;
        "
      >
        <div style="height: 100%; width: 100%; line-height: 2em; padding: 16px; padding-bottom: 50px;">
            <ng-content></ng-content>

        </div>
      </div>
    </div>
</div>
