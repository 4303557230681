<div class="hero2">
    <div class="position-relative">
        <img alt="Background" src="/assets/img/pages/hero.png" class="image" />
        <CDBMask overlay="darkLight"></CDBMask>
        <div class="scroll-down">
            <CDBIcon [fas]=true icon="angle-double-down"></CDBIcon>
            <span class="ml-2">scroll down</span>
        </div>
    </div>
    <div class="page-body">
        <header class="navigation">
            <CDBNavbar class="bg-transparent px-0" expand="md" [dark]=true [scrolling]=true>
                <CDBNavBrand href="/">
                    <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                </CDBNavBrand>
                <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
                <CDBCollapse #content [navbar]=true expand="md" class="w-100">
                    <CDBNavbarNav class="navbarnav" [left]=true>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink [active]=true to="#" style="font-weight: 600;">Home</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#" style="font-weight: 600;">Resources</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#" style="font-weight: 600;">Blog</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#" style="font-weight: 600;">About</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#" style="font-weight: 600;">Contact</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#" style="font-weight: 600;">Support</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                    </CDBNavbarNav>
                </CDBCollapse>
            </CDBNavbar>
        </header>
        <div class="page-info">
            <CDBCardTitle class="h1 font-weight-bold" style="max-width: 350px">Creativity is but skin deep
            </CDBCardTitle>
            <CDBCardText class="my-5" style="max-width: 500px">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam
                eum porro a pariatur accusamus veniam.</CDBCardText>
            <div class="d-flex">
                <div style="width: 40%;" class="mr-4">
                    <CDBBtn [flat]=true style="background: #8080ff; border: 2px #8080ff solid;" class="w-100">
                        Start Now
                    </CDBBtn>
                </div>
                <div style="width: 40%;">
                    <CDBBtn [flat]=true class="border-white bg-transparent text-white w-100">Start Now
                    </CDBBtn>
                </div>
            </div>
        </div>
    </div>
</div>