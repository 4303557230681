<page-layout>
    <section id="pagination">
        <div class="blockcode pt-5">
            <div class="header">Pagination</div>
            <div class="example">
                <CDBPagination circle=true>
                    <CDBPagelink style="color: white;">Prev</CDBPagelink>
                    <CDBPageitem>1</CDBPageitem>
                    <CDBPageitem>2</CDBPageitem>
                    <CDBPageitem>3</CDBPageitem>
                    <CDBPagelink style="color: white;">Next</CDBPagelink>
                </CDBPagination>
                <CDBPagination circle='true' size="big" color="success">
                    <CDBPagelink style="color: white;">Prev</CDBPagelink>
                    <CDBPageitem>1</CDBPageitem>
                    <CDBPageitem>2</CDBPageitem>
                    <CDBPageitem>3</CDBPageitem>
                    <CDBPagelink style="color: white;">Next</CDBPagelink>
                </CDBPagination>
                <CDBPagination size="small" color="secondary">
                    <CDBPagelink style="color: white;">Prev</CDBPagelink>
                    <CDBPageitem>1</CDBPageitem>
                    <CDBPageitem>2</CDBPageitem>
                    <CDBPageitem>3</CDBPageitem>
                    <CDBPagelink style="color: white;">Next</CDBPagelink>
                </CDBPagination>
                <CDBPagination color="warning" sm=true>
                    <CDBPagelink>Prev</CDBPagelink>
                    <CDBPageitem>1</CDBPageitem>
                    <CDBPageitem>2</CDBPageitem>
                    <CDBPageitem>3</CDBPageitem>
                    <CDBPagelink>Next</CDBPagelink>
                </CDBPagination>
                <CDBPagination color="white" sm=true>
                    <CDBPagelink>Prev</CDBPagelink>
                    <CDBPageitem>1</CDBPageitem>
                    <CDBPageitem>2</CDBPageitem>
                    <CDBPageitem>3</CDBPageitem>
                    <CDBPagelink>Next</CDBPagelink>
                </CDBPagination>
                <CDBPagination color="light">
                    <CDBPagelink>Prev</CDBPagelink>
                    <CDBPageitem>1</CDBPageitem>
                    <CDBPageitem>2</CDBPageitem>
                    <CDBPageitem>3</CDBPageitem>
                    <CDBPagelink>Next</CDBPagelink>
                </CDBPagination>
                <CDBPagination color="danger">
                    <CDBPagelink style="color: white;">Prev</CDBPagelink>
                    <CDBPageitem>1</CDBPageitem>
                    <CDBPageitem>2</CDBPageitem>
                    <CDBPageitem>3</CDBPageitem>
                    <CDBPagelink style="color: white;">Next</CDBPagelink>
                </CDBPagination>
                <CDBPagination>
                    <CDBPagelink style="color: white;">Prev</CDBPagelink>
                    <CDBPageitem>1</CDBPageitem>
                    <CDBPageitem>2</CDBPageitem>
                    <CDBPageitem>3</CDBPageitem>
                    <CDBPagelink style="color: white;">Next</CDBPagelink>
                </CDBPagination>
            </div>
        </div>
    </section>
</page-layout>