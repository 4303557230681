<div class="hero">
    <header class="navigation">
        <CDBNavbar class="px-0 bg-transparent" [light]=true expand="lg" [scrolling]=true>
            <CDBNavBrand style="padding-left: 0px;" href="/">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
            </CDBNavBrand>
            <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
            <CDBCollapse #content [navbar]=true expand="lg" class="w-100">
                <CDBNavbarNav [left]=true>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" [active]=true to="#">Home</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Resources</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Blog</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Contact</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Support</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
                <CDBNavbarNav [right]=true>
                    <CDBNavItem [active]=true>
                        <CDBBtn [outline]=true color="dark" class="py-1 px-3 ">
                            <CDBNavLink style="font-weight: 600;" [active]=true to="#">Sign In</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
            </CDBCollapse>
        </CDBNavbar>
    </header>
    <div class="page-body">
        <div class="page-info">
            <CDBCardTitle class="h1 font-weight-bold" style="max-width: 350px">Creativity is but skin deep
            </CDBCardTitle>
            <CDBCardText class="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet
                numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a
                pariatur accusamus veniam.</CDBCardText>
            <CDBBtn [flat]=true style="background: #8080ff; border: 2px #8080ff solid; width: 40%;">Start Now</CDBBtn>
        </div>
        <div class="image-container">
            <img alt="sideImage" class="image" src="/assets/img/pages/heroImage3.png" />
            <div class="btn-container">
                <CDBBtn color="dark" class="mr-1">
                    <CDBIcon [fas]=true icon="arrow-left"></CDBIcon>
                </CDBBtn>
                <CDBBtn color="dark">
                    <CDBIcon [fas]=true icon="arrow-right"></CDBIcon>
                </CDBBtn>
            </div>
        </div>
    </div>
</div>