<page-layout>
    <div>
        <div class="blockcode pt-5">
            <div class="header">Mask patterns</div>
            <div class="description">Mask patterns</div>
            <div class="example2">
                <CDBView>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-7EEiLpGbYU2-SvLeWS6qem7PHt5X7i0TZA&usqp=CAU"
                        class="img-fluid" alt="" style="width: 100%" />
                    <CDBMask pattern="pattern1" class="flex-center"></CDBMask>
                </CDBView>
                <CDBView>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-7EEiLpGbYU2-SvLeWS6qem7PHt5X7i0TZA&usqp=CAU"
                        class="img-fluid" alt="" style="width: 100%" />
                    <CDBMask pattern="pattern2" class="flex-center"></CDBMask>
                </CDBView>
                <CDBView>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-7EEiLpGbYU2-SvLeWS6qem7PHt5X7i0TZA&usqp=CAU"
                        class="img-fluid" alt="" style="width: 100%" />
                    <CDBMask pattern="pattern3" class="flex-center"></CDBMask>
                </CDBView>
                <CDBView>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-7EEiLpGbYU2-SvLeWS6qem7PHt5X7i0TZA&usqp=CAU"
                        class="img-fluid" alt="" style="width: 100%" />
                    <CDBMask pattern="pattern4" class="flex-center"></CDBMask>
                </CDBView>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Mask Light Overlays</div>
            <div class="description">Mask Light Overlays</div>
            <div class="example2">
                <CDBView>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-7EEiLpGbYU2-SvLeWS6qem7PHt5X7i0TZA&usqp=CAU"
                        class="img-fluid" alt="" style="width: 100%" />
                    <CDBMask overlay="blueLight" class="flex-center"></CDBMask>
                </CDBView>
                <CDBView>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-7EEiLpGbYU2-SvLeWS6qem7PHt5X7i0TZA&usqp=CAU"
                        class="img-fluid" alt="" style="width: 100%" />
                    <CDBMask overlay="redLight" class="flex-center"></CDBMask>
                </CDBView>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Mask Strong Overlays</div>
            <div class="description">Mask Strong Overlays</div>
            <div class="example2">
                <CDBView>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-7EEiLpGbYU2-SvLeWS6qem7PHt5X7i0TZA&usqp=CAU"
                        class="img-fluid" alt="" style="width: 100%" />
                    <CDBMask overlay="blueStrong" class="flex-center"></CDBMask>
                </CDBView>
                <CDBView>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-7EEiLpGbYU2-SvLeWS6qem7PHt5X7i0TZA&usqp=CAU"
                        class="img-fluid" alt="" style="width: 100%" />
                    <CDBMask overlay="redStrong" class="flex-center"></CDBMask>
                </CDBView>
            </div>
        </div>
    </div>
</page-layout>