<div class="hero5">
    <div style="position: relative">
        <img alt="Background" src="/assets/img/pages/hero1.png" style="width: 100%; height: 100vh; object-fit: cover" />
        <CDBMask overlay="darkLight"></CDBMask>
    </div>
    <div style="position: absolute; top: 0px; left: 0px; right: 0px">
        <div style="max-width: 1320px; margin: 0 auto">
            <header class="page-header">
                <div class="navigation">
                    <CDBNavbar class="bg-transparent p-0" expand="md" [dark]=true [scrolling]=true>
                        <CDBNavBrand href="/">
                            <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                        </CDBNavBrand>
                        <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
                        <CDBCollapse #content class="w-100" [navbar]=true expand="md">
                            <CDBNavbarNav class="navbarnav" [left]=true>
                                <CDBNavItem>
                                    <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                        <CDBNavLink style="font-weight: bold;" [active]=true to="#">Home</CDBNavLink>
                                    </CDBBtn>
                                </CDBNavItem>
                                <CDBNavItem>
                                    <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                        <CDBNavLink style="font-weight: bold;" to="#">Resources</CDBNavLink>
                                    </CDBBtn>
                                </CDBNavItem>
                                <CDBNavItem>
                                    <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                        <CDBNavLink style="font-weight: bold;" to="#">Blog</CDBNavLink>
                                    </CDBBtn>
                                </CDBNavItem>
                                <CDBNavItem>
                                    <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                        <CDBNavLink style="font-weight: bold;" to="#">Contact</CDBNavLink>
                                    </CDBBtn>
                                </CDBNavItem>
                                <CDBNavItem>
                                    <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                        <CDBNavLink style="font-weight: bold;" to="#">Support</CDBNavLink>
                                    </CDBBtn>
                                </CDBNavItem>
                            </CDBNavbarNav>
                            <CDBNavbarNav class="navbarnav" [right]=true>
                                <CDBNavItem>
                                    <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                        <CDBNavLink [active]=true style="font-weight: bold;" to="#">Sign In</CDBNavLink>
                                    </CDBBtn>
                                </CDBNavItem>
                                <CDBNavItem>
                                    <CDBBtn [flat]=true [outline]=true
                                        class="my-2 py-1 px-2 border-white bg-transparent">
                                        <CDBNavLink [active]=true style="font-weight: bold;" to="#">Sign Up</CDBNavLink>
                                    </CDBBtn>
                                </CDBNavItem>
                            </CDBNavbarNav>
                        </CDBCollapse>
                    </CDBNavbar>
                </div>
            </header>
            <div class="text-white text-center" style="width: 80%; margin: 12rem auto 0 auto">
                <CDBCardTitle class="h1 font-weight-bold mx-auto">Introducing the innovating, captivating art
                    gallery.</CDBCardTitle>
                <CDBCardText class="my-5 mx-auto" style="max-width: 70%">Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam
                    voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</CDBCardText>
                <CDBBtn [flat]=true class="px-4" style="background: #8080ff; border: #8080ff">Explore</CDBBtn>
                <CDBIcon [fas]=true class="d-block mt-3" icon="angle-double-down"></CDBIcon>
            </div>
        </div>
    </div>
</div>