<page-layout>
    <section id="notification">
        <div class="blockcode pt-5">
          <div class="header">
            Custom Notification Types
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component</CDBBadge
            >
          </div>
          <div class="description">Custom Notification Types</div>
          <div class="example3">
            <CDBNotification
              [show]="true"
              [fade]="true"
              [autohide]="2"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
            <CDBNotification
              [show]="true"
              [fade]="true"
              iconClassName="text-primary"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
            <CDBNotification
              [show]="true"
              [fade]="true"
              iconClassName="text-secondary"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Custom Notification Types
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component</CDBBadge
            >
          </div>
          <div class="description">Notification with Hidden Message</div>
          <div class="example3">
            <CDBNotification
              [show]="true"
              [fade]="true"
              [hideMessages]="true"
              [autohide]="5"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
            <CDBNotification
              [show]="true"
              [fade]="true"
              [hideMessages]="true"
              iconClassName="text-success"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
            <CDBNotification
              [show]="true"
              [fade]="true"
              [hideMessages]="true"
              iconClassName="text-warning"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Change Color of IconBox
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component</CDBBadge
            >
          </div>
          <div class="description">Change Color of IconBox</div>
          <div class="example3">
            <CDBNotification
              [show]="true"
              [fade]="true"
              color="success"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
            <CDBNotification
              [show]="true"
              [fade]="true"
              color="danger"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
      
            <CDBNotification
              [show]="true"
              [fade]="true"
              color="secondary"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
            <CDBNotification
              [show]="true"
              [fade]="true"
              color="primary"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            ></CDBNotification>
          </div>
        </div>
        <div>
          <div class="blockcode">
            <div class="header">
              Notification with Icons
              <CDBBadge style="background-color: #9871ff" size="large">
                Pro Component</CDBBadge
              >
            </div>
            <div class="description">Notification with Icons</div>
            <div class="example3">
              <CDBNotification
                [show]="true"
                [fade]="true"
                title="Contrast"
                icon="award"
                message="Hello, world! This is a primary message."
                text="11 mins ago"
              ></CDBNotification>
              <CDBNotification
                [show]="true"
                [fade]="true"
                iconClassName="text-primary"
                title="Contrast"
                icon="bus"
                message="Hello, world! This is a primary message."
                text="11 mins ago"
              ></CDBNotification>
      
              <CDBNotification
                [show]="true"
                [fade]="true"
                iconClassName="text-danger"
                title="Contrast"
                icon="burn"
                message="Hello, world! This is a primary message."
                text="11 mins ago"
              ></CDBNotification>
              <CDBNotification
                [show]="true"
                [fade]="true"
                iconClassName="text-success"
                icon="car-crash"
                title="Contrast"
                message="Hello, world! This is a primary message."
                text="11 mins ago"
              ></CDBNotification>
            </div>
          </div>
          <div class="blockcode">
            <div class="header">
              Positioning
              <CDBBadge style="background-color: #9871ff" size="large"
                >Pro Component
              </CDBBadge>
            </div>
            <div class="description">Top Left, Bottom Right</div>
            <div class="example4">
              <CDBNotification
                [show]="true"
                [fade]="true"
                title="Contrast"
                message="Hello, world! This is a positioned notification."
                text="11 mins ago"
                position="top-left"
              >
              </CDBNotification>
              <CDBNotification
                [show]="true"
                [fade]="true"
                title="Contrast"
                message="Hello, world! This is a positioned notification."
                text="11 mins ago"
                position="bottom-right"
              >
              </CDBNotification>
            </div>
          </div>
        </div>
      </section>      
</page-layout>
