<div class="signIn">
    <div class="position-relative">
        <img alt="Background" src="../../../assets/img/pages/signIn.png" class="image" />
    </div>
    <div class="body">
        <CDBNavbar class="navigation bg-transparent" [light]='true' expand="md" [scrolling]='true'>
            <CDBNavBrand href="/" class="d-flex align-items-center">
                <img alt="logo" src="../../../assets/img/pages/logo.png" width="25px" />
                <span class="ml-3 h4 my-0 font-weight-bold">Devwares</span>
            </CDBNavBrand>
        </CDBNavbar>
        <CDBCard class="shadow-lg form">
            <CDBCardBody class="mx-auto py-5 w-75" style="min-width:280px">
                <div class="mt-4 mb-5">
                    <p class="h4 font-weight-bold">Sign in</p>
                </div>
                <label htmlFor="defaultRegisterUsername" class="text-muted m-0">
                    Username
                </label>
                <CDBInput id="defaultRegisterUsername" [background]='true' hint="Username" class="mt-n3" type="text" ></CDBInput>
                <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                    Password
                </label>
                <CDBInput id="defaultRegisterPassword" [background]='true' hint="Password" class="mt-n3" type="password" ></CDBInput>
                <CDBLink style="font-weight:600" class="d-inline p-0 text-dark" to="#">Forgot Password?</CDBLink>
                <CDBBtn color="dark" class="btn-block w-100 mb-3 mt-5">
                    Sign in
                </CDBBtn>
                <div class="d-flex align-items-center">
                    <span style="border:0.5px solid black; width:45%" ></span>
                    <span class="text-center" style="width:10%">OR</span>
                    <span style="border:0.5px solid black; width:45%" ></span>
                </div>
                <div class="row my-3 d-flex justify-content-center">
                    <CDBBtn size="sm" [flat]='true' color="dark">
                        <CDBIcon [fab]='true' icon="facebook-f" ></CDBIcon>
                    </CDBBtn>
                    <CDBBtn size="sm" color="dark" [flat]='true' class="mx-2">
                        <CDBIcon [fab]='true' icon="twitter" ></CDBIcon>
                    </CDBBtn>
                    <CDBBtn size="sm" [flat]='true' color="dark">
                        <CDBIcon [fab]='true' icon="google-plus-g" ></CDBIcon>
                    </CDBBtn>
                </div>
                <p class="text-muted text-center">Don't have an Account? <CDBLink style="font-weight:600"
                        class="d-inline p-0 text-dark" to="#">Sign Up</CDBLink>
                </p>
            </CDBCardBody>
        </CDBCard>
    </div>
</div>