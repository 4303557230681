<page-layout>
    <section id="input">
        <div class="blockcode pt-5">
          <div class="header">Default Input types</div>
          <div class="description"></div>
          <div class="example2">
            <CDBInput id="username" label="Username"></CDBInput>
            <CDBInput hint="Your e-mail" type="email"></CDBInput>
            <CDBInput type="number"></CDBInput>
            <CDBInput [material]="true" type="text"></CDBInput>
            <CDBInput [background]="true" type="text"></CDBInput>
            <CDBInput type="checkbox"></CDBInput>
            <CDBInput class="mt-5" type="textarea"></CDBInput>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">Input Sizing</div>
          <div class="description"></div>
          <div class="example2">
            <CDBInput label="Your e-mail" type="email" size="sm"></CDBInput>
            <CDBInput type="number" label="Your Number" size="lg"></CDBInput>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">Input with Icons</div>
          <div class="description"></div>
          <div class="example2">
            <CDBInput label="Username" type="text" icon="user"></CDBInput>
            <CDBInput label="Password" type="password" icon="bell"></CDBInput>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">Input with MD</div>
          <div class="description"></div>
          <div class="example2">
            <CDBInput [material]="true" label="Your e-mail" type="email"></CDBInput>
            <CDBInput [material]="true" type="number" label="Your Number"></CDBInput>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">Placeholder</div>
          <div class="description"></div>
          <div class="example2">
            <CDBInput hint="Your e-mail" type="email"></CDBInput>
            <CDBInput type="number" hint="Your Number"></CDBInput>
          </div>
        </div>
      
        <div class="blockcode">
          <div class="header">Disabled Input</div>
          <div class="description"></div>
          <div class="example2">
            <CDBInput hint="Your e-mail" type="email" [disabled]="true"></CDBInput>
            <CDBInput type="number" hint="Your Number" [disabled]="true"></CDBInput>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">TextArea</div>
          <div class="description"></div>
          <div class="example2">
            <CDBInput label="Your name" type="textarea"></CDBInput>
            <CDBInput
              label="Your story"
              type="textarea"
              [material]="true"
              rows="20"
              cols="30"
            ></CDBInput>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">Background</div>
          <div class="description"></div>
          <div class="example2">
            <CDBInput hint="Your e-mail" type="email" [background]="true"></CDBInput>
            <CDBInput type="number" hint="Your Number" [background]="true"></CDBInput>
          </div>
        </div>
      </section>
</page-layout>