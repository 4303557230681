<div class="tables">
  <div class="d-flex">
    <div style="height: 100vh; overflow: hidden initial">
      <Sidebar bgColor="#fff" color="#333"></Sidebar>
    </div>
    <div class="text-dark"
      style="
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        height: 100vh;
        overflow-y: scroll;
      "
    >
      <div style="height: 100%; width: 100%; line-height: 2em; padding: 16px;">
        <div class="blockcode w-100 pt-5">
          <div class="header">Tables</div>
          <div class="description">Default Table</div>
          <table CdbTable>
            <thead>
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Table Head Options</div>
          <table CdbTable>
            <thead class="text-white thead-dark">
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
          <table CdbTable>
            <thead class="text-white thead-light">
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Striped Table</div>
          <table CdbTable striped="true">
            <thead>
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Bordered Table</div>
          <table CdbTable bordered="true">
            <thead>
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Borderless Table</div>
          <table CdbTable borderless="true">
            <thead>
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Hoverable Rows</div>
          <table CdbTable hoverable="true">
            <thead>
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Small Table</div>
          <table CdbTable small="true">
            <thead>
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Captions</div>
          <table CdbTable>
            <caption>
              List of users
            </caption>
            <thead>
              <tr>
                <th *ngFor="let head of headElements" scope="col">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let el of elements">
                <th scope="row">{{ el.id }}</th>
                <td>{{ el.first }}</td>
                <td>{{ el.last }}</td>
                <td>{{ el.handle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Responsive Table</div>
          <div class="table-responsive">
            <table CdbTable responsive="true">
              <thead>
                <tr>
                  <th *ngFor="let head of headElements2" scope="col">
                    {{ head }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let el of elements2">
                  <th scope="row">{{ el.id }}</th>
                  <td>{{ el.heading1 }}</td>
                  <td>{{ el.heading2 }}</td>
                  <td>{{ el.heading3 }}</td>
                  <td>{{ el.heading4 }}</td>
                  <td>{{ el.heading5 }}</td>
                  <td>{{ el.heading6 }}</td>
                  <td>{{ el.heading7 }}</td>
                  <td>{{ el.heading8 }}</td>
                  <td>{{ el.heading9 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Responsive Table SM</div>
          <div class="table-responsive-sm">
            <table CdbTable responsive="true">
              <thead>
                <tr>
                  <th *ngFor="let head of headElements2" scope="col">
                    {{ head }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let el of elements2">
                  <th scope="row">{{ el.id }}</th>
                  <td>{{ el.heading1 }}</td>
                  <td>{{ el.heading2 }}</td>
                  <td>{{ el.heading3 }}</td>
                  <td>{{ el.heading4 }}</td>
                  <td>{{ el.heading5 }}</td>
                  <td>{{ el.heading6 }}</td>
                  <td>{{ el.heading7 }}</td>
                  <td>{{ el.heading8 }}</td>
                  <td>{{ el.heading9 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Responsive Table MD</div>
          <div class="table-responsive-md">
            <table CdbTable responsive="true">
              <thead>
                <tr>
                  <th *ngFor="let head of headElements2" scope="col">
                    {{ head }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let el of elements2">
                  <th scope="row">{{ el.id }}</th>
                  <td>{{ el.heading1 }}</td>
                  <td>{{ el.heading2 }}</td>
                  <td>{{ el.heading3 }}</td>
                  <td>{{ el.heading4 }}</td>
                  <td>{{ el.heading5 }}</td>
                  <td>{{ el.heading6 }}</td>
                  <td>{{ el.heading7 }}</td>
                  <td>{{ el.heading8 }}</td>
                  <td>{{ el.heading9 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Responsive Table LG</div>
          <div class="table-responsive-lg">
            <table CdbTable responsive="true">
              <thead>
                <tr>
                  <th *ngFor="let head of headElements2" scope="col">
                    {{ head }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let el of elements2">
                  <th scope="row">{{ el.id }}</th>
                  <td>{{ el.heading1 }}</td>
                  <td>{{ el.heading2 }}</td>
                  <td>{{ el.heading3 }}</td>
                  <td>{{ el.heading4 }}</td>
                  <td>{{ el.heading5 }}</td>
                  <td>{{ el.heading6 }}</td>
                  <td>{{ el.heading7 }}</td>
                  <td>{{ el.heading8 }}</td>
                  <td>{{ el.heading9 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Responsive Table XL</div>
          <div class="table-responsive-xl">
            <table CdbTable responsive="true">
              <thead>
                <tr>
                  <th *ngFor="let head of headElements2" scope="col">
                    {{ head }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let el of elements2">
                  <th scope="row">{{ el.id }}</th>
                  <td>{{ el.heading1 }}</td>
                  <td>{{ el.heading2 }}</td>
                  <td>{{ el.heading3 }}</td>
                  <td>{{ el.heading4 }}</td>
                  <td>{{ el.heading5 }}</td>
                  <td>{{ el.heading6 }}</td>
                  <td>{{ el.heading7 }}</td>
                  <td>{{ el.heading8 }}</td>
                  <td>{{ el.heading9 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="blockcode w-100">
          <div class="header">Tables</div>
          <div class="description">Responsive Table SCROLL-Y</div>
          <div class="table-wrapper-scroll-y">
            <table CdbTable responsive="true">
              <thead>
                <tr>
                  <th *ngFor="let head of headElements2" scope="col">
                    {{ head }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let el of elements2">
                  <th scope="row">{{ el.id }}</th>
                  <td>{{ el.heading1 }}</td>
                  <td>{{ el.heading2 }}</td>
                  <td>{{ el.heading3 }}</td>
                  <td>{{ el.heading4 }}</td>
                  <td>{{ el.heading5 }}</td>
                  <td>{{ el.heading6 }}</td>
                  <td>{{ el.heading7 }}</td>
                  <td>{{ el.heading8 }}</td>
                  <td>{{ el.heading9 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
