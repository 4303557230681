<page-layout>
    <div class="blockcode pt-5">
        <div class="header">Slider</div>
        <div class="example3">
            <CDBSlider style="max-width: 300px;margin: auto;"></CDBSlider>
            <CDBSlider variant='slider-warning' tooltipPlacement="bottom"
                style="max-width: 300px;margin: auto; margin-top: 50px;">
            </CDBSlider>
            <CDBSlider tooltip="auto" style="max-width: 300px;margin: auto; margin-top: 50px;"></CDBSlider>
        </div>
    </div>
</page-layout>