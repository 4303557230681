<CDBNavbar class="px-0 mx-auto bg-transparent" style="width: 80%" [light]=true expand="md" [scrolling]=true>
    <CDBNavBrand href="/" class="d-flex align-items-center">
        <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
        <span class="ml-3 h4 my-0 font-weight-bold">Devwares</span>
    </CDBNavBrand>
</CDBNavbar>
<div class="d-flex align-items-center justify-content-center">
    <div style="width: 80%; max-width: 400px">
        <CDBCard  class="border-0 h-75">
            <CDBCardBody class="m-3">
                <div class="mt-4 mb-5">
                    <p class="h4 text-center font-weight-bold">Forgot Password</p>
                </div>
                <label htmlFor="defaultRegisterEmail" class="text-muted m-0">
                    Email Address
                </label>
                <CDBInput id="defaultRegisterEmail" hint="Email Address" class="mt-n3" background type="email"></CDBInput>
                <CDBBtn color="dark" class="btn-block mb-3 mt-5">
                    Recover Password
                </CDBBtn>
                <p style="margin-top: 18rem" class="text-muted text-center">Return to <CDBLink style="font-weight: 600"
                        class="d-inline p-0 text-dark" to="#">Sign in</CDBLink>
                </p>
            </CDBCardBody>
        </CDBCard>
    </div>
</div>