<page-layout>
    <section id="pane" class="panecontainer pt-5">
        <div class="blockcode">
            <div class="header">
                Basic Styles
                <CDBBadge style="background-color: #9871ff" size="large">
                    Pro Component
                </CDBBadge>
            </div>
            <div class="description">Pane with Location</div>
            <div class="example2">
                <CDBPanel class="pan">
                    <CDBPane>
                        <CDBPaneImage size="md"
                            src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170">
                        </CDBPaneImage>
                        <div class="ml-3">
                            <CDBPanelTitle>Warren Briggs</CDBPanelTitle>
                            <CDBPanelText small>Australia</CDBPanelText>
                        </div>
                        <p class="ms-auto mb-0 text-danger">
                            300m
                            <CDBIcon [fas]=true icon="map-marker-alt"></CDBIcon>
                        </p>
                    </CDBPane>
                </CDBPanel>
            </div>
        </div>
    
        <div class="blockcode">
            <div class="description">
                Pane with Dropdown
                <CDBBadge style="background-color: #9871ff" size="large">
                    Pro Component
                </CDBBadge>
            </div>
            <div class="example2">
                <CDBPanel class="pan">
                    <CDBPane>
                        <CDBPaneImage size="md"
                            src="https://media.istockphoto.com/photos/beautiful-woman-wearing-brown-curly-hairstyle-picture-id495006065?s=170x170">
                        </CDBPaneImage>
                        <div class="ml-3">
                            <CDBPanelTitle>Warren Briggs</CDBPanelTitle>
                            <CDBPanelText small>Breakfast</CDBPanelText>
                        </div>
                        <div class="ms-auto">
                            <CDBDropDown>
                                <CDBDropDownToggle color="white" (click)="paneMenu.toggleDropdown($event)">
                                    <CDBIcon class="text-muted" [fas]=true icon="ellipsis-h"></CDBIcon>
                                </CDBDropDownToggle>
                                <CDBDropDownMenu #paneMenu="cdbDropdownMenu" placement="bottom">
                                    <CDBDropDownItem [disabled]='true'>Edit Profile</CDBDropDownItem>
                                    <CDBDropDownItem [divider]='true'></CDBDropDownItem>
                                    <CDBDropDownItem>Action 1</CDBDropDownItem>
                                    <CDBDropDownItem>Action 2</CDBDropDownItem>
                                </CDBDropDownMenu>
                            </CDBDropDown>
                        </div>
                    </CDBPane>
                </CDBPanel>
            </div>
        </div>
    </section>
</page-layout>