<Header [style.color]='color ? color :null'  [style.backgroundColor]='bg ? bg :null' color="color">
    <CDBNavbar class="justify-content-between" [dark]='true' expand="md" [scrolling]='true'>
        <CDBInput type="search" size="md" hint="Search" class="mb-n4 mt-n3 input-nav"></CDBInput>
        <div class="ms-auto d-flex">
            <div class="d-flex align-items-center">
                <CDBIcon [fas]='true' icon="moon" class="mr-2"></CDBIcon>
                <CDBIcon [fas]='true' icon="bell" class="space"></CDBIcon>
                <CDBIcon [fas]='true' icon="comment-alt"></CDBIcon>
                <CDBBtn [flat]='true' color="none" class="px-2 mx-1" [style]="btnStyle">Hello, <span
                        style="font-weight:600;">user</span></CDBBtn>
            </div>
            <CDBPaneImage size="sm" src="../../../assets/img/pane/pane3.png"></CDBPaneImage>
        </div>
    </CDBNavbar>
</Header>