import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HeroComponent } from './pages/hero/hero.component';
import { Hero2Component } from './pages/hero2/hero2.component';
import { Hero3Component } from './pages/hero3/hero3.component';
import { Hero4Component } from './pages/hero4/hero4.component';
import { Hero5Component } from './pages/hero5/hero5.component';
import { AboutComponent } from './pages/about/about.component';
import { About2Component } from './pages/about2/about2.component';
import { Hero404Component } from './pages/hero404/hero404.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PostComponent } from './pages/post/post.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotPassword2Component } from './pages/forgot-password2/forgot-password2.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { Signin3Component } from './pages/signin3/signin3.component';
import { Signin2Component } from './pages/signin2/signin2.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { Signup2Component } from './pages/signup2/signup2.component';
import { ChatuiComponent } from './pages/chatui/chatui.component';
import { ProdashboardComponent } from './pages/prodashboard/prodashboard.component';
import { Prodashboard2Component } from './pages/prodashboard2/prodashboard2.component';
import { Prodashboard3Component } from './pages/prodashboard3/prodashboard3.component';
import { Prodashboard4Component } from './pages/prodashboard4/prodashboard4.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { ForgotPasswordPopupComponent } from './pages/forgot-password-popup/forgot-password-popup.component';
import { ProProfileComponent } from './pages/pro-profile/pro-profile.component';
import { TableComponent } from './pages/tables/tables.component';
import { AnimationComponent } from './pages/animation/animation.component';
import { AccordionComponent } from './pages/accordion/accordion.component';
import { AutocompleteComponent } from './pages/autocomplete/autocomplete.component';
import { BoxComponent } from './pages/box/box.component';
import { CardComponent } from './pages/card/card.component';
import { CarouselComponent } from './pages/carousel/carousel.component';
import { CheckboxComponent } from './pages/checkbox/checkbox.component';
import { CollapseComponent } from './pages/collapse/collapse.component';
import { DropdownComponent } from './pages/dropdown/dropdown.component';
import { FormsComponent } from './pages/forms/forms.component';
import { IconComponent } from './pages/icon/icon.component';
import { IframeComponent } from './pages/iframe/iframe.component';
import { InputComponent } from './pages/input/input.component';
import { InputGroupComponent } from './pages/input-group/input-group.component';
import { ListGroupComponent } from './pages/list-group/list-group.component';
import { MaskComponent } from './pages/mask/mask.component';
import { MultiselectComponent } from './pages/multiselect/multiselect.component';
import { PaneComponent } from './pages/pane/pane.component';
import { PanelComponent } from './pages/panel/panel.component';
import { RadioComponent } from './pages/radio/radio.component';
import { RatingComponent } from './pages/rating/rating.component';
import { SelectComponent } from './pages/select/select.component';
import { Select2Component } from './pages/select2/select2.component';
import { Sidebar } from './pages/sidebar/sidebar.component';
import { StepperComponent } from './pages/stepper/stepper.component';
import { SwitchComponent } from './pages/switch/switch.component';
import { TabsComponent } from './pages/tabs/tabs.component';
import { Sidebar2Component } from './pages/sidebar2/sidebar2.component';
import { BadgeComponent } from './pages/badge/badge.component';
import { ButtonComponent } from './pages/button/button.component';
import { ButtonGroupComponent } from './pages/button-group/button-group.component';
import { ButtonToolbarComponent } from './pages/button-toolbar/button-toolbar.component';
import { BreadcrumbComponent } from './pages/breadcrumb/breadcrumb.component';
import { FooterComponent } from './pages/footer/footer.component';
import { Navbar } from './pages/navbar/navbar.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PaginationComponent } from './pages/pagination/pagination.component';
import { AlertComponent } from './pages/alert/alert.component';
import { ModalComponent } from './pages/modal/modal.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { ProgressComponent } from './pages/progress/progress.component';
import { SliderComponent } from './pages/slider/slider.component';
import { SpinnerComponent } from './pages/spinner/spinner.component';
import { DatePickerComponent } from './pages/date-picker/date-picker.component';
import { TimePickerComponent } from './pages/time-picker/time-picker.component';
import { Navbar2Component } from './pages/navbar2/navbar2.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';


const routes: Routes = [
  { path: '', component: DashboardComponent},
  { path: 'hero', component: HeroComponent },
  { path: 'hero2', component: Hero2Component },
  { path: 'hero3', component: Hero3Component },
  { path: 'hero4', component: Hero4Component },
  { path: 'hero5', component: Hero5Component },
  { path: 'about', component: AboutComponent },
  { path: 'about2', component: About2Component },
  { path: 'hero404', component: Hero404Component },
  { path: 'landing', component: LandingComponent },
  { path: 'post', component: PostComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password2', component: ForgotPassword2Component },
  { path: 'forgot-password-popup', component: ForgotPasswordPopupComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'signin2', component: Signin2Component },
  { path: 'signin3', component: Signin3Component },
  { path: 'signup2', component: Signup2Component },
  { path: 'signup', component: SignupComponent },
  { path: 'create-account', component: CreateAccountComponent },
  { path: 'prodashboard', component: ProdashboardComponent },
  { path: 'prodashboard2', component: Prodashboard2Component },
  { path: 'prodashboard3', component: Prodashboard3Component },
  { path: 'prodashboard4', component: Prodashboard4Component },
  { path: 'profile', component: ProProfileComponent },
  { path: 'chatui', component: ChatuiComponent },

  {path: 'tables', component: TableComponent},
  {path: 'accordion', component: AccordionComponent},
  {path: 'animation', component: AnimationComponent},
  {path: 'autocomplete', component: AutocompleteComponent},
  {path: 'box', component: BoxComponent},
  {path: 'card', component: CardComponent},
  {path:'carousel', component: CarouselComponent},
  {path:'checkbox', component: CheckboxComponent},
  {path: 'collapse', component: CollapseComponent},
  {path: 'dropdown', component: DropdownComponent},
  {path:'forms', component: FormsComponent},
  {path:'icon', component: IconComponent},
  {path: 'iframe', component: IframeComponent},
  {path:'input', component: InputComponent},
  {path:'input-group', component: InputGroupComponent},
  {path: 'list-group', component: ListGroupComponent},
  {path: 'mask', component: MaskComponent},
  {path: 'multiselect', component: MultiselectComponent},
  {path: 'pane', component: PaneComponent},
  {path: 'panel', component: PanelComponent},
  {path: 'radio', component: RadioComponent},
  {path: 'rating', component: RatingComponent},
  {path: 'select', component: SelectComponent},
  {path: 'select2', component: Select2Component},
  {path: 'sidebar', component: Sidebar2Component},
  {path: 'stepper', component: StepperComponent},
  {path: 'switch', component: SwitchComponent},
  {path: 'tabs', component: TabsComponent},

  {path: 'badge', component: BadgeComponent},
  {path: 'button', component: ButtonComponent},
  {path: 'button-group', component: ButtonGroupComponent},
  {path:'button-toolbar', component: ButtonToolbarComponent},

  {path: 'breadcrumb', component: BreadcrumbComponent},
  {path: 'footer', component: FooterComponent},
  {path: 'navbar', component: Navbar2Component},
  {path: 'pagination', component: PaginationComponent},

  {path: 'alert', component: AlertComponent},
  {path: 'modal', component: ModalComponent},
  {path: 'notification', component: NotificationComponent},

  {path: 'progress', component: ProgressComponent},
  {path: 'slider', component: SliderComponent},
  {path: 'spinner', component: SpinnerComponent},

  {path: 'date-picker', component: DatePickerComponent},
  {path: 'time-picker', component: TimePickerComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
