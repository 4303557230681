<div class="prodashboard4" style="background:#FAFAFA">
    <div class="d-flex">
        <div style="height:100vh; overflow:hidden initial">
            <Sidebar bgColor="#fff" color="#333" ></Sidebar>
        </div>
        <div class="text-dark"
            style="flex:1 1 auto; display:flex; flex-flow:column; height:100vh; overflow-y:hidden">
            <Navbar ></Navbar>
            <div style="height:100%">
                <div class="scroll-container">
                    <div class="cards-container">
                            <CDBCard class="card-boundary card1">
                                <CDBCardBody class="d-flex flex-column">
                                    <div class="d-flex align-items-center">
                                        <CDBIcon [fas]='true' size="lg" icon="suitcase"></CDBIcon>
                                        <CDBCardTitle class="h6 ml-3" style="font-weight:600">Total Orders
                                        </CDBCardTitle>
                                    </div>
                                    <div class="d-flex mt-4">
                                        <div>
                                            <CDBCircularProgress color='success' size='xl' value=60 max=100 min=0 class="m-0 circularProgress" text="80%" strokeWidth=10>
                                                <CDBCircularProgress color='danger' size='xl' value=60 max=100 min=0 class="m-0 circularProgress" text="80%"
                                                    strokeWidth=10>
                                                </CDBCircularProgress>
                                            </CDBCircularProgress>
                                        </div>
                                        <div class="w-25 card1-desc">
                                            <div class="mt-2 d-flex align-items-center justify-content-between text-primary">
                                                <span style="font-size:3em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                                <span style="font-weight:600">Google</span>
                                            </div>
                                            <div class="mt-2 d-flex align-items-center justify-content-between" style="color:#05944F">
                                                <span style="font-size:3em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                                <span style="font-weight:600">Yahoo</span>
                                            </div>
                                            <div class="mt-2 d-flex align-items-center justify-content-between text-warning">
                                                <span style="font-size:3em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                                <span style="font-weight:600">Bing</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms-auto">
                                        <CDBBtn [flat]='true' class="mt-5 py-2 px-3 bg-dark text-white border-0" style="font-size:0.8em">
                                            View Details
                                            <CDBIcon class="ml-2" [fas]='true' icon="arrow-right"></CDBIcon>
                                        </CDBBtn>
                                    </div>
                                </CDBCardBody>
                            </CDBCard>
                            <CDBCard class="card-boundary card2">
                                <CDBCardBody class="d-flex flex-column">
                                    <div class="d-flex align-items-center">
                                        <CDBIcon [fas]='true' size="lg" icon="suitcase" ></CDBIcon>
                                        <CDBCardTitle class="h6 ml-3" style="font-weight:600">Sales</CDBCardTitle>
                                    </div>
                                    <div class="d-flex flex-wrap mt-4">
                                        <div class="card2-prog">
                                            <CDBCircularProgress [roundedStroke]=false color='success' size='xl' value=60 max=100 min=0 class="m-0 circularProgress" text="80%"
                                                strokeWidth=30>
                                            </CDBCircularProgress>
                                        </div>
                                        <div class="card2-desc">
                                            <div class="d-flex align-items-center justify-content-between text-success">
                                                <span
                                                    style="font-size:3em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                                <span style="font-size:0.9em; font-weight:600">Ongoing</span>
                                            </div>
                                            <div class="mt-2 d-flex align-items-center justify-content-between"
                                                style="color:#E0E0E0">
                                                <span
                                                    style="font-size:3em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                                <span style="font-size:0.9em; font-weight:600">Completed</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms-auto mt-auto">
                                        <CDBBtn [flat]='true' class=" py-2 px-3 bg-dark text-white border-0"
                                            style="font-size:0.8em; margin-top: 57px;">
                                            View Details
                                            <CDBIcon class="ml-2" [fas]='true' icon="arrow-right" ></CDBIcon>
                                        </CDBBtn>
                                    </div>
                                </CDBCardBody>
                            </CDBCard>
                        <CDBCard class="card-boundary card3">
                            <CDBCardBody class="d-flex flex-column">
                                <div class="d-flex flex-wrap align-items-center">
                                    <CDBIcon [fas]='true' size="lg" icon="suitcase"></CDBIcon>
                                    <CDBCardTitle class="h6 ml-3" style="font-weight:600">Sales</CDBCardTitle>
                                    <div class="ms-auto d-flex">
                                        <div class="d-flex align-items-center text-warning mr-2">
                                            <span
                                                style="font-size:3em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                            <span style="font-size:0.9em; font-weight:600">Bing</span>
                                        </div>
                                        <div class="d-flex align-items-center" style="color:#2D9CDB">
                                            <span
                                                style="font-size:3em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                            <span style="font-size:0.9em; font-weight:600">Google</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <div class="p-0 mt-auto">
                                        <canvas baseChart height=235 [datasets]="data.chart2.datasets" [labels]="data.chart2.labels"
                                            [options]="options.option2" chartType="bar"></canvas>
                                        <!-- <Bar height={235} data={data.chart2} options={options.option2} /> -->
                                    </div>
                                </div>
                                <div class="ms-auto">
                                    <CDBBtn [flat]='true' class="ms-auto mt-4 py-2 px-3 bg-dark text-white border-0"
                                        style="font-size:0.8em">
                                        View Details
                                        <CDBIcon class="ml-2" [fas]='true' icon="arrow-right"></CDBIcon>
                                    </CDBBtn>
                                </div>
                            </CDBCardBody>
                        </CDBCard>
                        <CDBPanel class="card-boundary card4 card-body">
                            <CDBPane>
                                <CDBIcon [fas]='true' size="lg" icon="comment-alt" ></CDBIcon>
                                <CDBPanelTitle class="ml-3 mb-2" style="font-weight:600">Messages</CDBPanelTitle>
                            </CDBPane>
                            <CDBPane class="mt-4 align-items-start">
                                <div class="position-relative">
                                    <CDBPaneImage src="../../../assets/img/pane/pane2.png" size="sm"
                                        class="align-self-center pane-image" ></CDBPaneImage>
                                    <span class="text-success"
                                        style="position:absolute; bottom:0px; right:-1px; font-size:0.7rem">
                                        <CDBIcon [fas]='true' class="p-0 border rounded-circle" size="xs" icon="circle" ></CDBIcon>
                                    </span>
                                </div>
                                <div class="ml-3 h-100 d-flex flex-column">
                                    <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                    <CDBPanelText style="font-size:0.75rem" class="mt-2">Lorem ipsum dolor sit
                                        amet, consectetur adipisicing elit.</CDBPanelText>
                                </div>
                                <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                    <span style="font-size:0.8em">Online</span>
                                </CDBPanelText>
                            </CDBPane>
                            <CDBPane class="mt-3 align-items-start">
                                <div class="position-relative">
                                    <CDBPaneImage src="../../../assets/img/pane/pane3.png" size="sm"
                                        class="align-self-center pane-image" ></CDBPaneImage>
                                    <span class="text-success"
                                        style="position:absolute; bottom:0px; right:-1px; font-size:0.7rem">
                                        <CDBIcon [fas]='true' class="p-0 border rounded-circle" size="xs" icon="circle" ></CDBIcon>
                                    </span>
                                </div>
                                <div class="ml-3 h-100 d-flex flex-column">
                                    <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                    <CDBPanelText style="font-size:0.75rem" class="mt-2">Lorem ipsum dolor sit
                                        amet, consectetur adipisicing elit.</CDBPanelText>
                                </div>
                                <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                    <span style="font-size:0.8em">Online</span>
                                </CDBPanelText>
                            </CDBPane>
                            <CDBPane class="mt-3 align-items-start">
                                <div class="position-relative">
                                    <CDBPaneImage src="../../../assets/img/pane/pane4.png" size="sm"
                                        class="align-self-center pane-image" ></CDBPaneImage>
                                    <span class="text-success"
                                        style="position:absolute; bottom:0px; right:-1px; font-size:0.7rem">
                                        <CDBIcon [fas]='true' class="p-0 border rounded-circle" size="xs" icon="circle" ></CDBIcon>
                                    </span>
                                </div>
                                <div class="ml-3 h-100 d-flex flex-column">
                                    <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                    <CDBPanelText style="font-size:0.75rem" class="mt-2">Lorem ipsum dolor sit
                                        amet, consectetur adipisicing elit.</CDBPanelText>
                                </div>
                                <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                    <span style="font-size:0.8em">Online</span>
                                </CDBPanelText>
                            </CDBPane>
                            <CDBPane class="mt-3 align-items-start">
                                <div class="position-relative">
                                    <CDBPaneImage src="../../../assets/img/pane/pane5.png" size="sm"
                                        class="align-self-center pane-image" ></CDBPaneImage>
                                    <span class="text-success"
                                        style="position:absolute; bottom:0px; right:-1px; font-size:0.7rem">
                                        <CDBIcon [fas]='true' class="p-0 border rounded-circle" size="xs" icon="circle"></CDBIcon>
                                    </span>
                                </div>
                                <div class="ml-3 h-100 d-flex flex-column">
                                    <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                    <CDBPanelText style="font-size:0.75rem" class="mt-2">Lorem ipsum dolor sit
                                        amet, consectetur adipisicing elit.</CDBPanelText>
                                </div>
                                <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                    <span style="font-size:0.8em">Online</span>
                                </CDBPanelText>
                            </CDBPane>
                            <CDBPane class="mt-3 align-items-start">
                                <div class="position-relative">
                                    <CDBPaneImage src="../../../assets/img/pane/pane1.png" size="sm"
                                        class="align-self-center pane-image" ></CDBPaneImage>
                                    <span class="text-success"
                                        style="position:absolute; bottom:0px; right:-1px; font-size:0.7rem">
                                        <CDBIcon [fas]='true' class="p-0 border rounded-circle" size="xs" icon="circle" ></CDBIcon>
                                    </span>
                                </div>
                                <div class="ml-3 h-100 d-flex flex-column">
                                    <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                    <CDBPanelText style="font-size:0.75rem" class="mt-2">Lorem ipsum dolor sit
                                        amet, consectetur adipisicing elit.</CDBPanelText>
                                </div>
                                <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                    <span style="font-size:0.8em">Online</span>
                                </CDBPanelText>
                            </CDBPane>
                            <div class="ms-auto">
                                <CDBBtn [flat]='true' class="mt-4 py-2 px-3 bg-dark text-white border-0"
                                    style="font-size:0.8em">
                                    View Details
                                    <CDBIcon class="ml-2" [fas]='true' icon="arrow-right" ></CDBIcon>
                                </CDBBtn>
                            </div>
                        </CDBPanel>
                        <div class="card5 card-boundary">
                            <CDBCard style="height: 100%; border: none;">
                                <CDBCardBody style="height: 100%; border: none;" class="d-flex flex-column">
                                    <div class="d-flex align-items-center">
                                        <CDBIcon [fas]='true' size="lg" icon="suitcase"></CDBIcon>
                                        <CDBCardTitle class="h6 ml-3" style="font-weight:600">Page Views
                                        </CDBCardTitle>
                                    </div>
                                    <div class="d-flex flex-column ml-5 mb-4">
                                        <CDBCardTitle class="mb-2 h4 font-weight-bold">Traffic</CDBCardTitle>
                                        <CDBCardText class="mb-0">
                                            <span class="font-weight-bold">14.3%</span>
                                            <span class="text-success ml-2" style="font-weight:600; font-size:0.8em">+2.3%</span>
                                        </CDBCardText>
                                    </div>
                                    <div style="margin-top: 100px;">
                                        <canvas baseChart  [datasets]="data.chart1.datasets"
                                            [labels]="data.chart1.labels" [options]="options.option1" chartType="line"></canvas>
                                        <!-- <Line height={window.innerWidth> 375 ? 274 : 200} data={data.chart1}
                                                                    options={options.option1} /> -->
                                    </div>
                                    <div class="ms-auto">
                                        <CDBBtn [flat]='true' class="mt-4 py-2 px-3 bg-dark text-white border-0" style="font-size:0.8em">
                                            View Details
                                            <CDBIcon class="ml-2" [fas]='true' icon="arrow-right"></CDBIcon>
                                        </CDBBtn>
                                    </div>
                                </CDBCardBody>
                            </CDBCard>
                        </div>                        
                    </div>
                    <CDBFooter>
                        <div class="footer">
                            <CDBLink to="/" class="footer-link">
                                <img alt="logo" src="../../../assets/img/pages/logo.png" width="25px" />
                                <span class="ml-4 mb-0 h5 font-weight-bold">Devwares</span>
                            </CDBLink>
                            <small>&copy; Devwares, 2020. All rights reserved.</small>
                            <div class="footer-rem">
                                <CDBBtn [flat]='true' class="p-1 border-0" style="background:#4F4F4F">
                                    <CDBIcon [fab]='true' icon="facebook-f"></CDBIcon>
                                </CDBBtn>
                                <CDBBtn [flat]='true' class="mx-3 p-1 border-0" style="background:#4F4F4F">
                                    <CDBIcon [fab]='true' icon="twitter"></CDBIcon>
                                </CDBBtn>
                                <CDBBtn [flat]='true' class="p-1 border-0" style="background:#4F4F4F">
                                    <CDBIcon [fab]='true' icon="instagram"></CDBIcon>
                                </CDBBtn>
                            </div>
                        </div>
                    </CDBFooter>
                </div>
            </div>
        </div>
    </div>
</div>