import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss'],
})
export class TableComponent implements OnInit {
  elements: any = [
    { id: 1, first: 'Mark', last: 'Otto', handle: '@mdo' },
    { id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat' },
    { id: 3, first: 'Larry', last: 'the Bird', handle: '@twitter' },
  ];

  headElements = ['#', 'First', 'Last', 'Handle'];

  elements2: any = [
    {
      id: 1,
      heading1: 'Cell',
      heading2: 'Cell',
      heading3: 'Cell',
      heading4: 'Cell',
      heading5: 'Cell',
      heading6: 'Cell',
      heading7: 'Cell',
      heading8: 'Cell',
      heading9: 'Cell',
    },
    {
      id: 2,
      heading1: 'Cell',
      heading2: 'Cell',
      heading3: 'Cell',
      heading4: 'Cell',
      heading5: 'Cell',
      heading6: 'Cell',
      heading7: 'Cell',
      heading8: 'Cell',
      heading9: 'Cell',
    },
    {
      id: 3,
      heading1: 'Cell',
      heading2: 'Cell',
      heading3: 'Cell',
      heading4: 'Cell',
      heading5: 'Cell',
      heading6: 'Cell',
      heading7: 'Cell',
      heading8: 'Cell',
      heading9: 'Cell',
    },
  ];
  headElements2 = [
    '#',
    'Heading',
    'Heading',
    'Heading',
    'Heading',
    'Heading',
    'Heading',
    'Heading',
    'Heading',
    'Heading',
  ];

  constructor() {}

  ngOnInit(): void {}
}