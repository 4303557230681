import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about2',
  templateUrl: './about2.component.html',
  styleUrls: ['./about2.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class About2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
