<div class="d-flex profile" style="height:100vh">
    <Sidebar bgColor="#fff" color="#333" ></Sidebar>
    <div class="w-100">
        <Navbar ></Navbar>
        <div class="mx-auto" style="height:calc(100vh - 65px); overflow-y:scroll; max-width:1320px">
            <div>
                <div class="panel-container">
                    <div class="card-1">
                        <CDBCard class="scroll-panel shadow">
                            <CDBCardImage class="img-fluid w-100" src="../../../assets/img/uber.png" ></CDBCardImage>
                            <CDBPaneImage style="margin-top: -5rem; border-style: solid; border-width: 4px" 
                                class="mx-auto border-white" width="130px" src="../../../assets/img/ellipse.png" ></CDBPaneImage>
                            <CDBCardBody class="d-flex flex-column align-items-center mb-4 pt-1">
                                <CDBCardTitle>Chukwudi Mezue</CDBCardTitle>
                                <CDBCardText class="text-muted">Product Designer</CDBCardText>
                                <CDBCardText style="color: #333333; margin:20px 0 30px" >
                                    Creating High Quality Resources and tools to Aid developers during the developement
                                    of
                                    their projects
                                </CDBCardText>
                                <div class="w-100">
                                    <CDBBtn class="w-100 mb-0" color="dark">Send Message</CDBBtn>
                                </div>
                            </CDBCardBody>
                        </CDBCard>
                    </div>
                    <div class="form-2">
                        <CDBPanel style="width: 100%; padding: 0px;" class="scroll-panel shadow card-2 d-flex flex-column">
                            <div style="width:100%; height:100%; background-color: #ffffff;
                                padding: 40px 20px 20px">
                                <CDBCardText style="font-weight:bold"  muted size="lg">Profile</CDBCardText>
                                <CDBInputGroup [background]="true" label="Name" class="mb-3" ></CDBInputGroup>
                                <CDBInputGroup [background]="true" label="Email Address" type="email" class="mb-3" ></CDBInputGroup>
                                <CDBInputGroup [background]="true" label="Username" class="mb-3" ></CDBInputGroup>
                                <CDBInputGroup [background]="true" label="Phone Number" class="mb-5" ></CDBInputGroup>
                                <CDBCardText style="font-weight: bold"  class="mb-5" muted size="lg">Account
                                </CDBCardText>
                                <CDBInputGroup [background]="true" label="Old Password" type="password" class="mb-3" ></CDBInputGroup>
                                <CDBInputGroup [background]="true" label="New Password" type="password" class="mb-5" ></CDBInputGroup>
                                <div class="d-flex justify-content-end">
                                    <CDBBtn class="mb-0" color="dark">Save Changes</CDBBtn>
                                </div>
                            </div>
                        </CDBPanel>
                    </div>
                </div>
                <CDBFooter>
                    <div class="d-flex justify-content-between align-items-center mx-auto py-4"
                        style="width:80%">
                        <CDBLink to="/" class="d-flex align-items-center p-0 text-dark footer-rem">
                            <img alt="logo" src="../../../assets/img/pages/logo.png" width="25px" />
                            <span class="ml-4 h5 font-weight-bold">Devwares</span>
                        </CDBLink>
                        <small>&copy; Devwares, 2020. All rights reserved.</small>
                        <div class="footer-rem">
                            <CDBBtn [flat]="true" class="p-1 bg-dark border-0">
                                <CDBIcon [fab]="true" icon="facebook-f" ></CDBIcon>
                            </CDBBtn>
                            <CDBBtn [flat]="true" class="mx-3 p-1 bg-dark border-0">
                                <CDBIcon [fab]="true" icon="twitter" ></CDBIcon>
                            </CDBBtn>
                            <CDBBtn [flat]="true" class="p-1 bg-dark border-0">
                                <CDBIcon [fab]="true" icon="instagram" ></CDBIcon>
                            </CDBBtn>
                        </div>
                    </div>
                </CDBFooter>
            </div>
        </div>
    </div>
</div>