import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-prodashboard3',
  templateUrl: './prodashboard3.component.html',
  styleUrls: ['./prodashboard3.component.scss', '../pages.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Prodashboard3Component implements OnInit {

  //Chart options
  options = {
		responsive: true, 
		maintainAspectRatio: false,
		legend: {display:false},
	    scales: {
	      xAxes: [{
	        gridLines: {
	        	display:false,
	        },
	        ticks: {
	          display: false
	        }
	      }],
	      yAxes: [{
	        gridLines: {
	        	display:false,
	        }   ,
	        ticks: {
	          display: false,
		        beginAtZero: true,
	        }
	      }]
	    }
  }
  
  //chart data
  data = {
    chart1: {
    	labels: [
	      "Eating",
	      "Drinking",
	      "Sleeping",
	      "Designing",
	      "Coding",
	      "Cycling",
	      "Running",
	    ],
	    datasets: [
	      {
	        label: "My Dataset",
	        pointRadius: 0,
	        backgroundColor: "rgba(47, 128, 237, 0.3)",
	        borderColor: "rgb(47, 128, 237)",
	        borderWidth: 2,
	        data: [3.5, 3.8, 4.5, 5, 6, 8, 10],
	      },
	    ],
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
