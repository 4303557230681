import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-forgot-password-popup',
  templateUrl: './forgot-password-popup.component.html',
  styleUrls: ['./forgot-password-popup.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ForgotPasswordPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
