<div class="prodashboard3">
    <div class="d-flex">
        <div style="height:100vh; overflow:hidden initial">
            <Sidebar bgColor="#fff" color="#333" ></Sidebar>
        </div>
        <div class="text-dark"
            style="flex:1 1 auto; display:flex; flex-flow:column; height:100vh; overflow-y:hidden">
            <Navbar ></Navbar>
            <div style="height:100%">
                <div class="scroll-container">
                    <div class="cards-container">
                        <div class="card-container1">
                            <CDBCard class="card-boundary card1">
                                <CDBCardBody class="d-flex body flex-column">
                                    <div class="ms-auto">
                                        <CDBBtn [flat]='true' class="ms-auto p-2 bg-dark text-white border-0"
                                            style="font-size:0.8em">
                                            View Details
                                            <CDBIcon class="ml-2" [fas]='true' icon="arrow-right" ></CDBIcon>
                                        </CDBBtn>
                                    </div>
                                    <div class="d-flex flex-wrap mt-3">
                                        <CDBCircularProgress color='success' value=60 max=100 min=0
                                            class="m-0 circularProgress" text="80%" strokeWidth=7>
                                        </CDBCircularProgress>
                                        <div
                                            class="my-auto d-flex flex-column align-items-center text-right ms-auto">
                                            <CDBCardTitle class="mb-2 card1-text font-weight-bold">$30,000
                                            </CDBCardTitle>
                                            <CDBCardText class="text-success mb-0 ml-3" style="font-weight:600">
                                                <span class="ml-2"
                                                    style="font-weight:600; font-size:0.9em">+27.4%</span>
                                                <span style="font-size:0.8em"
                                                    class="mb-0 ml-3 text-dark d-block">Since last month</span>
                                            </CDBCardText>
                                        </div>
                                    </div>
                                </CDBCardBody>
                            </CDBCard>
                            <CDBPanel class="card-boundary w-100 d-flex flex-column card-body mt-4">
                                <CDBPane>
                                    <CDBPanelTitle style="font-weight:600">Messages</CDBPanelTitle>
                                    <div class="ms-auto">
                                        <CDBBtn [flat]='true' class="ms-auto p-2 bg-dark text-white border-0"
                                            style="font-size:0.8em">
                                            View Details
                                            <CDBIcon class="ml-2" [fas]='true' icon="arrow-right" ></CDBIcon>
                                        </CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-3" style="height:38px">
                                    <CDBPaneImage src="./../../assets/img/pane/pane2.png" size="sm"
                                        style="min-width:2rem; height:auto" ></CDBPaneImage>
                                    <div class="ml-3 h-100 d-flex flex-column">
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText small class="mt-auto">Hey, how are you.</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">
                                        <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                            <span style="font-size:0.8em">Online</span>
                                            <span
                                                class="ms-auto mt-auto p-2 small bg-secondary text-white rounded-circle w-100 text-center">44</span>
                                        </CDBPanelText>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-3" style="height:38px">
                                    <CDBPaneImage src="./../../assets/img/pane/pane3.png" size="sm"
                                        style="min-width:2rem; height:auto" ></CDBPaneImage>
                                    <div class="ml-3 h-100 d-flex flex-column">
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText small class="mt-auto">Hey, how are you.</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">
                                        <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                            <span style="font-size:0.8em">Online</span>
                                            <span
                                                class="ms-auto mt-auto p-2 small bg-secondary text-white rounded-circle w-100 text-center">44</span>
                                        </CDBPanelText>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-3" style="height:38px">
                                    <CDBPaneImage src="./../../assets/img/pane/pane4.png" size="sm"
                                        style="min-width:2rem; height:auto" ></CDBPaneImage>
                                    <div class="ml-3 h-100 d-flex flex-column">
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText small class="mt-auto">Hey, how are you.</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">
                                        <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                            <span style="font-size:0.8em">Online</span>
                                            <span
                                                class="ms-auto mt-auto p-2 small bg-secondary text-white rounded-circle w-100 text-center">44</span>
                                        </CDBPanelText>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-3" style="height:38px">
                                    <CDBPaneImage src="./../../assets/img/pane/pane5.png" size="sm"
                                        style="min-width:2rem; height:auto" ></CDBPaneImage>
                                    <div class="ml-3 h-100 d-flex flex-column">
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText small class="mt-auto">Hey, how are you.</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">
                                        <CDBPanelText class="text-muted ms-auto d-flex flex-column h-100">
                                            <span style="font-size:0.8em">Online</span>
                                            <span
                                                class="ms-auto mt-auto p-2 small bg-secondary text-white rounded-circle w-100 text-center">44</span>
                                        </CDBPanelText>
                                    </div>
                                </CDBPane>
                            </CDBPanel>
                            <CDBCard class="card-boundary mt-4">
                                <CDBCardBody class="d-flex flex-column">
                                    <div class="d-flex align-items-center">
                                        <CDBCardTitle class="h6" style="font-weight:600">Page Views
                                        </CDBCardTitle>
                                        <div class="ms-auto">
                                            <CDBBtn [flat]='true' class="ms-auto p-2 bg-dark text-white border-0"
                                                style="font-size:0.8em">
                                                View Details
                                                <CDBIcon class="ml-2" [fas]='true' icon="arrow-right" ></CDBIcon>
                                            </CDBBtn>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <CDBCardTitle class="mt-3 mb-0 h1 font-weight-bold">10,000</CDBCardTitle>
                                        <span class="text-success"
                                            style="font-weight:600; font-size:0.9em">+27.4%</span>
                                    </div>
                                    <div style="margin:-1.5rem 0 -0.77rem -0.75rem">
                                        <canvas baseChart height="180" [datasets]="data.chart1.datasets" [labels]="data.chart1.labels"
                                            [options]="options" chartType="line"></canvas>
                                    </div>
                                </CDBCardBody>
                            </CDBCard>
                        </div>
                        <div class="card-container2">
                            <CDBCard class="card-boundary card4">
                                <CDBCardBody>
                                    <div class="d-flex align-items-center">
                                        <CDBCardTitle class="h6" style="font-weight:600">Total Orders
                                        </CDBCardTitle>
                                        <div class="ms-auto">
                                            <CDBBtn [flat]='true' class="ms-auto p-2 bg-dark text-white border-0"
                                                style="font-size:0.8em">
                                                View Details
                                                <CDBIcon class="ml-2" [fas]='true' icon="arrow-right" ></CDBIcon>
                                            </CDBBtn>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap mx-3 mt-4">
                                        <div class="progress-label text-warning">
                                            <span
                                                style="font-size:3.5em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                            <span class="small">Bing</span>
                                        </div>
                                        <div class="progress-label" style="color:#9B51E0">
                                            <span
                                                style="font-size:3.5em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                            <span class="small">Yahoo</span>
                                        </div>
                                        <div class="progress-label text-primary">
                                            <span
                                                style="font-size:3.5em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                            <span class="small">Google</span>
                                        </div>
                                        <div class="progress-label text-success">
                                            <span
                                                style="font-size:3.5em; margin:-2rem 0px -1.5rem 0px">&#8226;</span>
                                            <span class="small">Mozilla</span>
                                        </div>
                                    </div>
                                    <div class="progress-container" style="margin-top:1.8rem">
                                        <span>
                                            <CDBCircularProgress color='warning' value=60 max=100 min=0
                                                class="circularProgress2" text="80%" strokeWidth=7>
                                            </CDBCircularProgress>
                                            <CDBCircularProgress color='info' value=60 max=100 min=0
                                                class="circularProgress2" text="80%" strokeWidth=7>
                                            </CDBCircularProgress>
                                        </span>
                                        <span>
                                            <CDBCircularProgress color='primary' value=60 max=100 min=0
                                                class="circularProgress2" text="80%" strokeWidth=7>
                                            </CDBCircularProgress>
                                            <CDBCircularProgress color='success' value=60 max=100 min=0
                                                class="circularProgress2" text="80%" strokeWidth=7>
                                            </CDBCircularProgress>
                                        </span>
                                    </div>
                                    <CDBCardText class="mt-5 mb-4 text-muted small">Lorem ipsum dolor sit amet,
                                        consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate
                                        esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur
                                        accusamus veniam.</CDBCardText>
                                </CDBCardBody>
                            </CDBCard>
                            <CDBCard class="card-boundary card5">
                                <CDBCardBody>
                                    <div class="d-flex align-items-center">
                                        <CDBCardTitle class="h6 mt-3" style="font-weight:600">Sales
                                        </CDBCardTitle>
                                        <div class="ms-auto">
                                            <CDBBtn [flat]='true' class="ms-auto p-2 bg-dark text-white border-0"
                                                style="font-size:0.8em">
                                                View Details
                                                <CDBIcon class="ml-2" [fas]='true' icon="arrow-right" ></CDBIcon>
                                            </CDBBtn>
                                        </div>
                                    </div>
                                    <div class="mt-4 px-2">
                                        <div class="d-flex align-items-center line-progress">
                                            <CDBCardText class="text-muted mb-0">28 Jun 2019</CDBCardText>
                                            <CDBProgress value=80 color="danger"></CDBProgress>
                                        </div>
                                        <div class="d-flex align-items-center line-progress">
                                            <CDBCardText class="text-muted mb-0">28 Jun 2019</CDBCardText>
                                            <CDBProgress value=80 color="danger"></CDBProgress>
                                        </div>
                                        <div class="d-flex align-items-center line-progress">
                                            <CDBCardText class="text-muted mb-0">07 Jan 2019</CDBCardText>
                                            <CDBProgress value=50 color="danger"></CDBProgress>
                                        </div>
                                        <div class="d-flex align-items-center line-progress">
                                            <CDBCardText class="text-muted mb-0">18 May 2019</CDBCardText>
                                            <CDBProgress value=60 color="danger"></CDBProgress>
                                        </div>
                                        <div class="d-flex align-items-center line-progress">
                                            <CDBCardText class="text-muted mb-0">23 May 2019</CDBCardText>
                                            <CDBProgress value=40 color="danger"></CDBProgress>
                                        </div>
                                        <div class="d-flex align-items-center line-progress">
                                            <CDBCardText class="text-muted mb-0">17 Feb 2019</CDBCardText>
                                            <CDBProgress value=70 color="danger"></CDBProgress>
                                        </div>
                                    </div>
                                    <div class="ticks d-flex justify-content-between">
                                        <p class="small text-muted rem">01</p>
                                        <p class="small text-muted rem">02</p>
                                        <p class="small text-muted">03</p>
                                        <p class="small text-muted rem">04</p>
                                        <p class="small text-muted rem">05</p>
                                        <p class="small text-muted">06</p>
                                        <p class="small text-muted rem">07</p>
                                        <p class="small text-muted rem">08</p>
                                        <p class="small text-muted">09</p>
                                        <p class="small text-muted rem">10</p>
                                        <p class="small text-muted rem">11</p>
                                        <p class="small text-muted">12</p>
                                        <p class="small text-muted rem">13</p>
                                        <p class="small text-muted rem">14</p>
                                        <p class="small text-muted">15</p>
                                    </div>
                                </CDBCardBody>
                            </CDBCard>
                        </div>
                    </div>
                    <CDBFooter>
                        <div class="footer">
                            <CDBLink to="/" class="footer-link">
                                <img alt="logo" src="./../../assets/img/pages/logo.png" width="25px" />
                                <span class="ml-4 mb-0 h5 font-weight-bold">Devwares</span>
                            </CDBLink>
                            <small>&copy; Devwares, 2020. All rights reserved.</small>
                            <div class="footer-rem">
                                <CDBBtn [flat]='true' class="p-1 border-0" style="background:#4F4F4F">
                                    <CDBIcon [fab]='true' icon="facebook-f" ></CDBIcon>
                                </CDBBtn>
                                <CDBBtn [flat]='true' class="mx-3 p-1 border-0" style="background:#4F4F4F">
                                    <CDBIcon [fab]='true' icon="twitter" ></CDBIcon>
                                </CDBBtn>
                                <CDBBtn [flat]='true' class="p-1 border-0" style="background:#4F4F4F">
                                    <CDBIcon [fab]='true' icon="instagram" ></CDBIcon>
                                </CDBBtn>
                            </div>
                        </div>
                    </CDBFooter>
                </div>
            </div>
        </div>
    </div>
</div>