import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-chatui',
  templateUrl: './chatui.component.html',
  styleUrls: ['./chatui.component.scss', '../pages.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ChatuiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
