<page-layout>
    <section id="forms">
        <div class="blockcode pt-5">
            <div class="header">Login/Sign in Form</div>
            <div class="description">Default form login</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4"> Sign in </p>
                        </div>
                        <CDBInput [material]=true hint="E-mail" type="email"></CDBInput>
                        <CDBInput [material]=true hint="Password" type="password"></CDBInput>
                        <div class="d-flex flex-wrap flex-wrap justify-content-center align-items-center">
                            <CDBInput type="checkbox"></CDBInput>
                            <p class="m-0">Remember me</p>
                            <CDBLink to="/">Forgot Password ?</CDBLink>
                        </div>
                        <CDBBtn color="dark" class="btn-block my-3 mx-0">
                            Sign in
                        </CDBBtn>
                        <p class="text-center">Not a member? <CDBLink class="d-inline p-0" to="/">Register</CDBLink>
                        </p>
                        <p class="text-center"> or sign in with</p>
                        <div class="row my-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn color="white" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                            </CDBBtn>
                        </div>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="description">Contrast Form Login</div>
            <div class="example2">
                <CDBCard>
                    <div style="background: black" class="text-center text-white">
                        <p class="h5 mt-2 py-4 font-weight-bold">Sign in</p>
                    </div>
                    <CDBCardBody class="container">
                        <CDBInput label="Email" type="email"></CDBInput>
                        <CDBInput label="Password" type="password"></CDBInput>
                        <div class="mt-5 d-flex flex-wrap flex-wrap justify-content-center align-items-center">
                            <CDBCheckbox class="mr-2"></CDBCheckbox>
                            <p class="m-0">Remember me</p>
                            <CDBLink to="/">Forgot Password ?</CDBLink>
                        </div>
                        <CDBBtn color="dark" [outline]=true class="btn-block my-3 mx-0">
                            Sign in
                        </CDBBtn>
                        <p class="text-center">Not a member? <CDBLink class="d-inline p-0" to="/">Register</CDBLink>
                        </p>
                        <p class="text-center"> or sign in with</p>
                        <div class="row my-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn color="white" class="m-0" [outline]=true>
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" [outline]=true>
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" [outline]=true>
                                <CDBIcon [fab]=true icon="linkedin-in"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" [outline]=true>
                                <CDBIcon [fab]=true icon="github"></CDBIcon>
                            </CDBBtn>
                        </div>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Register/Sign up Form</div>
            <div class="description">Default form register</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4"> Sign up </p>
                        </div>
                        <div class="form-row mb-n4">
                            <div class="col-12 col-md-6">
                                <CDBInput [material]=true hint="First name" type="text"></CDBInput>
                            </div>
                            <div class="col-12 col-md-6">
                                <CDBInput [material]=true hint="Last name" type="text"></CDBInput>
                            </div>
                        </div>
                        <CDBInput [material]=true hint="E-mail" type="email"></CDBInput>
                        <p class="text-muted text-center small mt-n4">At least 8 characters and 1 digit</p>
                        <CDBInput [material]=true hint="Password" type="password"></CDBInput>
                        <CDBInput [material]=true hint="Phone number" type="text"></CDBInput>
                        <p class="text-muted text-center small mt-n4">Optional - for two step authentication</p>
                        <div class="d-flex flex-wrap justify-content-center align-items-center mt-4">
                            <CDBInput type="checkbox"></CDBInput>
                            <p class="m-0">Subscribe to our newsletter</p>
                        </div>
                        <CDBBtn color="dark" class="btn-block my-3 mx-0">
                            Sign up
                        </CDBBtn>
                        <p class="text-center"> or sign up with</p>
                        <div class="row mb-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn color="white" class="m-0" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                            </CDBBtn>
                        </div>
                        <p class="text-center m-0">Already have an account? <CDBLink class="d-inline p-0" to="/">Sign In
                            </CDBLink>
                        </p>
                        <hr />
                        <p class="text-center">By clicking <em>Sign up</em> you agree to our <CDBLink class="d-inline p-0"
                                to="/">terms of service</CDBLink>
                        </p>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="description">Contrast form register</div>
            <div class="example2">
                <CDBCard>
                    <div class="text-center text-white" style="background: black">
                        <p class="h5 mt-2 py-4 font-weight-bold"> Sign up </p>
                    </div>
                    <CDBCardBody class="container">
                        <div class="form-row mb-n4">
                            <div class="col-12 col-md-6">
                                <CDBInput label="First name" type="text"></CDBInput>
                            </div>
                            <div class="col-12 col-md-6">
                                <CDBInput label="Last name" type="text"></CDBInput>
                            </div>
                        </div>
                        <CDBInput label="E-mail" type="email"></CDBInput>
                        <p class="text-muted text-center small mt-n4">At least 8 characters and 1 digit</p>
                        <CDBInput label="Password" type="password"></CDBInput>
                        <CDBInput label="Phone number" type="text"></CDBInput>
                        <p class="text-muted text-center small mt-n4">Optional - for two step authentication</p>
                        <div class="d-flex flex-wrap justify-content-center align-items-center mt-4">
                            <CDBCheckbox class="mr-2"></CDBCheckbox>
                            <p class="m-0">Subscribe to our newsletter</p>
                        </div>
                        <CDBBtn color="dark" [outline]=true class="btn-block my-3 mx-0">
                            Sign up
                        </CDBBtn>
                        <p class="text-center"> or sign up with</p>
                        <div class="row mb-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn color="white" class="m-0" [outline]=true>
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" [outline]=true>
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" [outline]=true>
                                <CDBIcon [fab]=true icon="linkedin-in"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0" [outline]=true>
                                <CDBIcon [fab]=true icon="github"></CDBIcon>
                            </CDBBtn>
                        </div>
                        <p class="text-center m-0">Already have an account? <CDBLink class="d-inline p-0" to="/">Sign In
                            </CDBLink>
                        </p>
                        <hr />
                        <p class="text-center">By clicking <em>Sign up</em> you agree to our <CDBLink class="d-inline p-0"
                                to="/">terms of service</CDBLink>
                        </p>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Subscription Form</div>
            <div class="description">Default form Subscription</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4">Subscribe</p>
                        </div>
                        <p class="text-center mt-4 font-weight-light">Join our mailing list. We write rarely, but only the
                            best content.</p>
                        <CDBLink class="text-center p-0 font-weight-light" to="/">See the last newsletter</CDBLink>
                        <CDBInput [material]=true hint="Name" type="text"></CDBInput>
                        <CDBInput [material]=true hint="E-mail" type="email"></CDBInput>
                        <CDBBtn color="dark" class="btn-block my-3 mx-0">
                            Subscribe
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="description">Contrast form Subscription</div>
            <div class="example2">
                <CDBCard>
                    <div class="text-center text-white" style="background: black">
                        <p class="h5 mt-2 py-4 font-weight-bold">Subscribe</p>
                    </div>
                    <CDBCardBody class="container">
                        <p class="text-center mt-2">Join our mailing list. We write rarely, but only the best content.</p>
                        <CDBLink class="text-center p-0" to="/">See the last newsletter</CDBLink>
                        <CDBInput label="Name" type="text"></CDBInput>
                        <CDBInput label="E-mail" type="email"></CDBInput>
                        <CDBBtn color="dark" [outline]=true class="btn-block my-3 mx-0">
                            Subscribe
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Contact Form</div>
            <div class="description">Default form contact</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4"> Contact us </p>
                        </div>
                        <CDBInput [material]=true hint="Name" type="text"></CDBInput>
                        <CDBInput [material]=true hint="E-mail" type="email"></CDBInput>
                        <p class="text-center m-0">Subject</p>
                        <CDBSelect [options]="option" selected="Feedback"></CDBSelect>
                        <CDBInput hint="Message" type="textarea"></CDBInput>
                        <div class="d-flex flex-wrap justify-content-center align-items-center mt-4">
                            <CDBInput [material]=true type="checkbox"></CDBInput>
                            <p class="m-0">Send me a copy of this message</p>
                        </div>
                        <CDBBtn color="dark" class="btn-block my-3 mx-0">
                            Send
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="description">Contrast form contact</div>
            <div class="example2">
                <CDBCard>
                    <div style="background: black" class="text-center text-white">
                        <p class="h5 mt-2 py-4 font-weight-bold">Contact Us</p>
                    </div>
                    <CDBCardBody class="container">
                        <CDBInput label="Name" type="text"></CDBInput>
                        <CDBInput label="E-mail" type="email"></CDBInput>
                        <p class="text-center m-0 text-muted">Subject</p>
                        <CDBSelect2 color="white" [options]="option" selected="Feedback"></CDBSelect2>
                        <CDBInput label="Message" type="textarea"></CDBInput>
                        <div class="d-flex flex-wrap justify-content-center align-items-center mt-4">
                            <CDBInput type="checkbox"></CDBInput>
                            <p class="m-0">Send me a copy of this message</p>
                        </div>
                        <CDBBtn color="dark" [outline]=true class="btn-block my-3 mx-0">
                            Send
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Login/Sign in Form</div>
            <div class="description">Login form with icon</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4 font-weight-bold"> Sign in </p>
                        </div>
                        <CDBInput label="E-mail" type="email" icon="envelope"></CDBInput>
                        <CDBInput label="Password" type="password" icon="lock"></CDBInput>
                        <CDBBtn color="success" class="btn-block mt-5 mx-auto">
                            Login
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Login/Sign in Form</div>
            <div class="description">Login form with outside label</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-4">
                            <p class="h4"> Sign in </p>
                        </div>
                        <label htmlFor="defaultLoginEmail" class="text-muted m-0">
                            Your email
                        </label>
                        <CDBInput id="defaultLoginEmail" class="mt-n3" type="email"></CDBInput>
                        <label htmlFor="defaultLoginPassword" class="text-muted m-0">
                            Your password
                        </label>
                        <CDBInput id="defaultLoginPassword" class="mt-n3" type="password"></CDBInput>
                        <CDBBtn color="primary" class="btn-block mt-5 mx-auto">
                            Login
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Form Register</div>
            <div class="description">Sign Up form with icon</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4 font-weight-bold"> Sign up </p>
                        </div>
                        <CDBInput label="Your name" type="text" icon="user" iconClass="text-muted"></CDBInput>
                        <CDBInput label="Your email" type="email" icon="envelope" iconClass="text-muted"></CDBInput>
                        <CDBInput label="Confirm your email" type="email" icon="exclamation-triangle" iconClass="text-muted">
                        </CDBInput>
                        <CDBInput label="Your Password" type="password" icon="lock" iconClass="text-muted"></CDBInput>
                        <CDBBtn color="primary" class="btn-block mt-5 mx-auto">
                            Register
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Form Regiser</div>
            <div class="description">Sign Up form outside label</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4"> Sign up </p>
                        </div>
                        <label htmlFor="defaultRegisterName" class="text-muted m-0">
                            Your name
                        </label>
                        <CDBInput id="defaultRegisterName" class="mt-n3" type="text"></CDBInput>
                        <label htmlFor="defaultRegisterEmail" class="text-muted m-0">
                            Your email
                        </label>
                        <CDBInput id="defaultRegisterEmail" class="mt-n3" type="email"></CDBInput>
                        <label htmlFor="defaultRegisterEmailConfirm" class="text-muted m-0">
                            Confirm your email
                        </label>
                        <CDBInput id="defaultRegisterEmailConfirm" class="mt-n3" type="email"></CDBInput>
                        <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                            Your password
                        </label>
                        <CDBInput id="defaultRegisterPassword" class="mt-n3" type="password"></CDBInput>
                        <CDBBtn color="danger" class="btn-block mt-5 mx-auto">
                            Register
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Form Subscription</div>
            <div class="description">Subscription form with icon</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4 font-weight-bold"> Subscribe </p>
                        </div>
                        <CDBInput label="Your name" type="text" icon="user" iconClass="text-muted"></CDBInput>
                        <CDBInput label="Your email" type="email" icon="envelope" iconClass="text-muted"></CDBInput>
                        <CDBBtn [outline]=true color="primary" class="btn-block mt-5 mx-auto">
                            Send
                            <CDBIcon [far]=true icon="paper-plane"></CDBIcon>
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Form Subscription</div>
            <div class="description">Subscription form with outside label</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4"> Subscribe </p>
                        </div>
                        <label htmlFor="defaultSubscribeName" class="text-muted m-0">
                            Your name
                        </label>
                        <CDBInput id="defaultSubscribeName" class="mt-n3" type="text"></CDBInput>
                        <label htmlFor="defaultSubscribeEmail" class="text-muted m-0">
                            Your email
                        </label>
                        <CDBInput id="defaultSubscribeEmail" class="mt-n3" type="email"></CDBInput>
                        <CDBBtn [outline]=true color="danger" class="btn-block mt-5 mx-auto">
                            Send
                            <CDBIcon [far]=true icon="paper-plane"></CDBIcon>
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Form Contact</div>
            <div class="description">Contact form with icon</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4 font-weight-bold"> Write to Us </p>
                        </div>
                        <CDBInput label="Your name" type="text" icon="user" iconClass="text-muted"></CDBInput>
                        <CDBInput label="Your email" type="email" icon="envelope" iconClass="text-muted"></CDBInput>
                        <CDBInput label="Subject" type="text" icon="tags" iconClass="text-muted"></CDBInput>
                        <CDBInput label="Your message" type="textarea" icon="pencil-alt" iconClass="text-muted"></CDBInput>
                        <CDBBtn [outline]=true color="secondary" class="btn-block mt-5 mx-auto">
                            Send
                            <CDBIcon [far]=true icon="paper-plane"></CDBIcon>
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Form Contact</div>
            <div class="description">Contact form with icon</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-4">
                            <p class="h4"> Write to Us </p>
                        </div>
                        <label htmlFor="defaultContactName" class="text-muted m-0">
                            Your name
                        </label>
                        <CDBInput id="defaultContactName" class="mt-n3" type="text"></CDBInput>
                        <label htmlFor="defaultContactEmail" class="text-muted m-0">
                            Your email
                        </label>
                        <CDBInput id="defaultContactEmail" class="mt-n3" type="email"></CDBInput>
                        <label htmlFor="defaultContactSubject" class="text-muted m-0">
                            Subject
                        </label>
                        <CDBInput id="defaultContactSubject" class="mt-n3" type="text"></CDBInput>
                        <label htmlFor="defaultContactMessage" class="text-muted m-0">
                            Your message
                        </label>
                        <CDBInput id="defaultContactMessage" class="mt-n3" type="textarea"></CDBInput>
                        <CDBBtn [outline]=true color="secondary" class="btn-block mt-5 mx-auto">
                            Send
                            <CDBIcon [far]=true icon="paper-plane"></CDBIcon>
                        </CDBBtn>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Login/Sign in Form</div>
            <div class="description">form login</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4 font-weight-bold"> Sign in </p>
                        </div>
                        <CDBInput label="E-mail" type="email"></CDBInput>
                        <CDBInput label="Password" type="password"></CDBInput>
                        <p class="mt-n3 text-right">
                            <CDBLink class="p-0" to="/">Forgot Password ?</CDBLink>
                        </p>
                        <CDBBtn color="dark" class="btn-block my-4 mx-0">
                            Sign in
                        </CDBBtn>
                        <p class="text-center"> or sign in with</p>
                        <div class="row my-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn color="white" class="m-0">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="white" class="m-0">
                                <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                            </CDBBtn>
                        </div>
                        <hr />
                        <p class="text-center">Not a member? <CDBLink class="d-inline p-0" to="/">Sign up</CDBLink>
                        </p>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Login/Sign in Form</div>
            <div class="description">Sign Up</div>
            <div class="example2">
                <CDBCard>
                    <CDBCardBody class="container">
                        <div class="text-center mt-4 mb-2">
                            <p class="h4"> Sign up </p>
                        </div>
                        <CDBInput label="E-mail" type="email"></CDBInput>
                        <CDBInput label="Password" type="password"></CDBInput>
                        <div class="d-flex flex-wrap align-items-center">
                            <CDBCheckbox class="mr-2"></CDBCheckbox>
                            <p class="mb-0 ml-2">Accept the <CDBLink class="d-inline p-0" to="/">terms and conditions
                                </CDBLink>
                            </p>
                        </div>
                        <div class="d-flex flex-wrap mt-4 align-items-center">
                            <CDBBtn color="none"
                                style="background: linear-gradient(0deg, rgba(37,212,214,1) 0%, rgba(110,112,200,1) 100%)"
                                class="btn-block mx-0">
                                Sign up
                            </CDBBtn>
                            <p class="ms-auto mb-0">Have an account? <CDBLink class="d-inline p-0" to="/">Log in</CDBLink>
                            </p>
                        </div>
                    </CDBCardBody>
                    <div class="pt-4"
                        style="background: linear-gradient(0deg, rgba(37,212,214,1) 0%, rgba(110,112,200,1) 100%)">
                        <p class="text-center m-0"> or sign up with</p>
                        <div class="row mb-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn color="none" class="m-0">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="none" class="m-0">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="none" class="m-0">
                                <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                            </CDBBtn>
                        </div>
                    </div>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="description">Login form</div>
            <div class="example2">
                <CDBCard>
                    <div style="background: linear-gradient(0deg, rgba(37,212,214,1) 0%, rgba(110,112,200,1) 100%)"
                        class="text-center">
                        <p class="h5 mt-2 mt-5 mb-2 font-weight-bold">Login</p>
                        <div class="row mb-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn color="none" class="m-0" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="none" class="m-0" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="none" class="m-0" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="linkedin-in"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="none" class="m-0" style="box-shadow: none">
                                <CDBIcon [fab]=true icon="github"></CDBIcon>
                            </CDBBtn>
                        </div>
                    </div>
                    <CDBCardBody class="container">
                        <CDBInput label="E-mail" type="email"></CDBInput>
                        <CDBInput label="Password" type="password"></CDBInput>
                        <p class="text-right">Forgot <CDBLink class="d-inline p-0 ms-auto" to="/">Password ?</CDBLink>
                        </p>
                        <CDBBtn color="none"
                            style="background: linear-gradient(0deg, rgba(37,212,214,1) 0%, rgba(110,112,200,1) 100%)"
                            class="btn-block my-3 mx-0 font-weight-bold">
                            Login
                        </CDBBtn>
                        <p class=" text-right mt-4">Don't have an account? <CDBLink class="d-inline p-0" to="/">Sign Up
                            </CDBLink>
                        </p>
        
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Login/Sign in Form</div>
            <div class="description">Sign Up with background</div>
            <div class="example2">
                <CDBCard
                    style=" background: url(https://images.unsplash.com/photo-1528287942171-fbe365d1d9ac?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&w=1200&cs=srgb&ixid=eyJh); background-size: cover; position: relative;">
                    <CDBMask overlay="darkStrong" class="flex-center"></CDBMask>
                    <div style="z-index: 2">
                        <CDBCardBody class="container">
                            <div class="text-center mt-5 mb-2">
                                <p class="h4 font-weight-bold text-primary"> Sign <span class="h4 font-weight-bold">up</span>
                                </p>
                            </div>
                            <CDBInput [material]=true label="E-mail" type="email"></CDBInput>
                            <CDBInput [material]=true label="Password" type="password"></CDBInput>
                            <div class="d-flex flex-wrap mt-3 align-items-center justify-content-center">
                                <CDBCheckbox></CDBCheckbox>
                                <p class="text-white mb-0 ml-2">Accept the <CDBLink class="d-inline p-0" to="/">terms and
                                        conditions</CDBLink>
                                </p>
                            </div>
                            <CDBBtn color="primary" class="btn-block mx-0 my-4">
                                Sign up
                            </CDBBtn>
                            <p class="text-white text-center mb-5">Have an account? <CDBLink class="d-inline p-0" to="/">Log
                                    in
                                </CDBLink>
                            </p>
                        </CDBCardBody>
                    </div>
                </CDBCard>
            </div>
        </div>
        <div class="blockcode">
            <div class="header">Sign In forms
                <CDBBadge size="large" style="background-color: #9871ff">Pro Component</CDBBadge>
            </div>
            <div class="description">Sign In Form in card</div>
            <div class="example2">
                <CDBCard class="shadow">
                    <CDBCardBody class="container">
                        <div class="mt-4 mb-5">
                            <p class="h2 font-weight-bold">Sign up</p>
                        </div>
                        <label htmlFor="defaultRegisterUsername" class="text-muted m-0">
                            Username
                        </label>
                        <CDBInput id="defaultRegisterUsername" [background]=true hint="Username" class="mt-n3" type="text">
                        </CDBInput>
                        <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                            Password
                        </label>
                        <CDBInput id="defaultRegisterPassword" [background]=true hint="Password" class="mt-n3" type="password">
                        </CDBInput>
                        <CDBBtn color="dark" class="btn-block mb-3 mt-5">
                            Sign up
                        </CDBBtn>
                        <p class="text-muted">By Clicking sign up, you agree to the Contrast <CDBLink style="font-weight: 600"
                                class="d-inline p-0 text-dark" to="/">terms and conditions
                            </CDBLink>
                        </p>
                        <div class="d-flex flex-wrap align-items-center">
                            <span style="border: 0.5px solid black; width: 45%"></span>
                            <span class="text-center" style="width: 10%">OR</span>
                            <span style="border: 0.5px solid black; width: 45%"></span>
                        </div>
                        <div class="row my-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn [flat]=true color="dark">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="dark" [flat]=true class="mx-2">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn [flat]=true color="dark">
                                <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                            </CDBBtn>
                        </div>
                        <p class="text-muted text-center">Have an Account? <CDBLink style="font-weight: 600"
                                class="d-inline p-0 text-dark" to="/">Sign In</CDBLink>
                        </p>
                    </CDBCardBody>
                </CDBCard>
                <CDBCard class="shadow">
                    <CDBCardBody class="container">
                        <div class="my-4 d-flex flex-wrap align-items-center">
                            <div>
                                <p class="h4 font-weight-bold">Sign up</p>
                                <p class="m-0" style="font-weight: 600">Let's get started</p>
                            </div>
                            <div class="ms-auto">
                                <CDBBtn [outline]=true color="dark" class="px-4" style="font-weight: 600">Sign Up
                                </CDBBtn>
                            </div>
                        </div>
                        <label htmlFor="defaultRegisterUsername" class="text-muted m-0">
                            Username
                        </label>
                        <CDBInput id="defaultRegisterUsername" [background]=true hint="Username" class="mt-n3" type="text">
                        </CDBInput>
                        <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                            Password
                        </label>
                        <CDBInput id="defaultRegisterPassword" [background]=true hint="Password" class="mt-n3" type="password">
                        </CDBInput>
                        <CDBBtn color="dark" class="btn-block mb-3 mt-5">
                            Sign up
                        </CDBBtn>
                        <p class="text-muted">By Clicking sign up, you agree to the Contrast <CDBLink style="font-weight: 600"
                                class="d-inline p-0 text-dark" to="/">terms and conditions
                            </CDBLink>
                        </p>
                        <div class="d-flex flex-wrap align-items-center">
                            <span style="border: 0.5px solid black; width: 45%"></span>
                            <span class="text-center" style="width: 10%">OR</span>
                            <span style="border: 0.5px solid black; width: 45%"></span>
                        </div>
                        <div class="row my-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn [flat]=true color="dark">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="dark" [flat]=true class="mx-2">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn [flat]=true color="dark">
                                <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                            </CDBBtn>
                        </div>
                        <p class="text-muted text-center">Have an Account? <CDBLink style="font-weight: 600"
                                class="d-inline p-0 text-dark" to="/">Sign In</CDBLink>
                        </p>
                    </CDBCardBody>
                </CDBCard>
            </div>
            <div class="example2">
                <CDBCard class="shadow">
                    <CDBCardBody class="container">
                        <div class="mt-4 mb-5">
                            <p class="h2 font-weight-bold">Sign in</p>
                        </div>
                        <label htmlFor="defaultRegisterUsername" class="text-muted m-0">
                            Username
                        </label>
                        <CDBInput id="defaultRegisterUsername" [background]=true hint="Username" class="mt-n3" type="text">
                        </CDBInput>
                        <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                            Password
                        </label>
                        <CDBInput id="defaultRegisterPassword" [background]=true hint="Password" class="mt-n3" type="password">
                        </CDBInput>
                        <CDBLink style="font-weight: 600" class="d-inline p-0 text-dark" to="/">Forgot Password?</CDBLink>
                        <CDBBtn color="dark" class="btn-block mb-3 mt-5">
                            Sign in
                        </CDBBtn>
                        <div class="d-flex flex-wrap align-items-center">
                            <span style="border: 0.5px solid #555; width: 45%"></span>
                            <span class="text-center" style="width: 10%">OR</span>
                            <span style="border: 0.5px solid #555; width: 45%"></span>
                        </div>
                        <div class="row my-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn [flat]=true color="dark">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="dark" [flat]=true class="mx-2">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn [flat]=true color="dark">
                                <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                            </CDBBtn>
                        </div>
                        <p class="text-muted text-center">Don't have an Account? <CDBLink style="font-weight: 600"
                                class="d-inline p-0 text-dark" to="/">Sign Up</CDBLink>
                        </p>
                    </CDBCardBody>
                </CDBCard>
                <CDBCard class="shadow">
                    <CDBCardBody class="container">
                        <div class="my-4 d-flex flex-wrap align-items-center">
                            <div>
                                <p class="h4 font-weight-bold">Sign in</p>
                                <p class="m-0" style="font-weight: 600">Welcome Back.</p>
                            </div>
                            <div class="ms-auto">
                                <CDBBtn [outline]=true color="dark" class="px-4" style="font-weight: 600">Sign Up
                                </CDBBtn>
                            </div>
                        </div>
                        <label htmlFor="defaultRegisterUsername" class="text-muted m-0">
                            Username
                        </label>
                        <CDBInput id="defaultRegisterUsername" [background]=true hint="Username" class="mt-n3" type="text">
                        </CDBInput>
                        <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                            Password
                        </label>
                        <CDBInput id="defaultRegisterPassword" [background]=true hint="Password" class="mt-n3" type="password">
                        </CDBInput>
                        <CDBLink style="font-weight: 600" class="d-inline p-0 text-dark" to="/">Forgot Password?</CDBLink>
                        <CDBBtn color="dark" class="btn-block mb-3 mt-5">
                            Sign in
                        </CDBBtn>
                        <div class="d-flex flex-wrap align-items-center">
                            <span style="border: 0.5px solid #555; width: 45%"></span>
                            <span class="text-center" style="width: 10%">OR</span>
                            <span style="border: 0.5px solid #555; width: 45%"></span>
                        </div>
                        <div class="row my-3 d-flex flex-wrap justify-content-center">
                            <CDBBtn [flat]=true color="dark">
                                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn color="dark" [flat]=true class="mx-2">
                                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn [flat]=true color="dark">
                                <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                            </CDBBtn>
                        </div>
                        <p class="text-muted text-center">Don't have an Account? <CDBLink style="font-weight: 600"
                                class="d-inline p-0 text-dark" to="/">Sign Up</CDBLink>
                        </p>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
        </section>
</page-layout>