import { Component } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent {
  option = [
    {
      text: 'Value 1',
      showing: true,
    },
    {
      text: 'Second Value',
      showing: true,
    },
    {
      text: 'Third Value',
      showing: true,
    },
    {
      text: 'Final Value',
      showing: true,
    },
  ];

  selectOptions = [
    {
      text: 'Value 1',
      showing: true,
    },
    {
      text: 'Second Value',
      showing: true,
    },
    {
      text: 'Third Value',
      showing: true,
    },
    {
      text: 'Final Value',
      showing: true,
    },
  ];
}
