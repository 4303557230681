import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CDBProModule } from 'cdbangular-pro';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';

import { HeroComponent } from './pages/hero/hero.component';
import { Hero2Component } from './pages/hero2/hero2.component';
import { Hero3Component } from './pages/hero3/hero3.component';
import { Hero4Component } from './pages/hero4/hero4.component';
import { Hero5Component } from './pages/hero5/hero5.component';
import { AboutComponent } from './pages/about/about.component';
import { About2Component } from './pages/about2/about2.component';
import { Hero404Component } from './pages/hero404/hero404.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PostComponent } from './pages/post/post.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotPassword2Component } from './pages/forgot-password2/forgot-password2.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { Signin2Component } from './pages/signin2/signin2.component';
import { Signup2Component } from './pages/signup2/signup2.component';
import { ProdashboardComponent } from './pages/prodashboard/prodashboard.component';
import { Sidebar } from './pages/sidebar/sidebar.component';
import { Navbar } from './pages/navbar/navbar.component';
import { Prodashboard2Component } from './pages/prodashboard2/prodashboard2.component';
import { Prodashboard3Component } from './pages/prodashboard3/prodashboard3.component';
import { Prodashboard4Component } from './pages/prodashboard4/prodashboard4.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { ForgotPasswordPopupComponent } from './pages/forgot-password-popup/forgot-password-popup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { Signin3Component } from './pages/signin3/signin3.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ChatuiComponent } from './pages/chatui/chatui.component';
import { ProProfileComponent } from './pages/pro-profile/pro-profile.component';
import { TableComponent } from './pages/tables/tables.component';
import { AccordionComponent } from './pages/accordion/accordion.component';
import { AnimationComponent } from './pages/animation/animation.component';
import { AutocompleteComponent } from './pages/autocomplete/autocomplete.component';
import { BoxComponent } from './pages/box/box.component';
import { CardComponent } from './pages/card/card.component';
import { CarouselComponent } from './pages/carousel/carousel.component';
import { CheckboxComponent } from './pages/checkbox/checkbox.component';
import { CollapseComponent } from './pages/collapse/collapse.component';
import { DropdownComponent } from './pages/dropdown/dropdown.component';
import { FormsComponent } from './pages/forms/forms.component';
import { IconComponent } from './pages/icon/icon.component';
import { IframeComponent } from './pages/iframe/iframe.component';
import { InputComponent } from './pages/input/input.component';
import { InputGroupComponent } from './pages/input-group/input-group.component';
import { ListGroupComponent } from './pages/list-group/list-group.component';
import { MaskComponent } from './pages/mask/mask.component';
import { MultiselectComponent } from './pages/multiselect/multiselect.component';
import { PaneComponent } from './pages/pane/pane.component';
import { PanelComponent } from './pages/panel/panel.component';
import { RadioComponent } from './pages/radio/radio.component';
import { RatingComponent } from './pages/rating/rating.component';
import { SelectComponent } from './pages/select/select.component';
import { Select2Component } from './pages/select2/select2.component';
import { StepperComponent } from './pages/stepper/stepper.component';
import { SwitchComponent } from './pages/switch/switch.component';
import { TabsComponent } from './pages/tabs/tabs.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { Sidebar2Component } from './pages/sidebar2/sidebar2.component';
import { BadgeComponent } from './pages/badge/badge.component';
import { ButtonComponent } from './pages/button/button.component';
import { ButtonGroupComponent } from './pages/button-group/button-group.component';
import { ButtonToolbarComponent } from './pages/button-toolbar/button-toolbar.component';
import { BreadcrumbComponent } from './pages/breadcrumb/breadcrumb.component';
import { FooterComponent } from './pages/footer/footer.component';
import { PaginationComponent } from './pages/pagination/pagination.component';
import { AlertComponent } from './pages/alert/alert.component';
import { ModalComponent } from './pages/modal/modal.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { ProgressComponent } from './pages/progress/progress.component';
import { SliderComponent } from './pages/slider/slider.component';
import { SpinnerComponent } from './pages/spinner/spinner.component';
import { DatePickerComponent } from './pages/date-picker/date-picker.component';
import { TimePickerComponent } from './pages/time-picker/time-picker.component';
import { Navbar2Component } from './pages/navbar2/navbar2.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    Hero2Component,
    Hero3Component,
    Hero4Component,
    Hero5Component,
    AboutComponent,
    About2Component,
    Hero404Component,
    LandingComponent,
    PostComponent,
    ForgotPasswordComponent,
    ForgotPassword2Component,
    PricingComponent,
    Signin2Component,
    Signup2Component,
    ProdashboardComponent,
    Sidebar,
    Navbar,
    Prodashboard2Component,
    Prodashboard3Component,
    Prodashboard4Component,
    CreateAccountComponent,
    ForgotPasswordPopupComponent,
    SigninComponent,
    Signin3Component,
    SignupComponent,
    ChatuiComponent,
    ProProfileComponent,
    TableComponent,
    AccordionComponent,
    AnimationComponent,
    AutocompleteComponent,
    BoxComponent,
    CardComponent,
    CarouselComponent,
    CheckboxComponent,
    CollapseComponent,
    DropdownComponent,
    FormsComponent,
    IconComponent,
    IframeComponent,
    InputComponent,
    InputGroupComponent,
    ListGroupComponent,
    MaskComponent,
    MultiselectComponent,
    PaneComponent,
    PanelComponent,
    RadioComponent,
    RatingComponent,
    SelectComponent,
    Select2Component,
    StepperComponent,
    SwitchComponent,
    TabsComponent,
    PageLayoutComponent,
    Sidebar2Component,
    BadgeComponent,
    ButtonComponent,
    ButtonGroupComponent,
    ButtonToolbarComponent,
    BreadcrumbComponent,
    FooterComponent,
    PaginationComponent,
    AlertComponent,
    ModalComponent,
    NotificationComponent,
    ProgressComponent,
    SliderComponent,
    SpinnerComponent,
    DatePickerComponent,
    TimePickerComponent,
    Navbar2Component,
    DashboardComponent,
  ],
  imports: [
    ChartsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    CDBProModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}