<page-layout>
    <section id="list-group">
        <div class="blockcode pt-5">
          <div class="header">Default List Style</div>
          <div class="description">Default List Style</div>
          <div class="example">
            <CDBListGroup style="width: 30rem">
              <CDBListGroupItem>List Group </CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">List Group as Link</div>
          <div class="description">List Group as Link</div>
          <div class="example">
            <CDBListGroup style="width: 30rem">
              <CDBListGroupItem href="/" [active]="true">
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem href="/" [hover]="true">
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem href="/" [hover]="true">
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem href="/" [hover]="true">
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem href="/" [hover]="true">
                List Group Link
              </CDBListGroupItem>
              <CDBListGroupItem href="/" [hover]="true">
                List Group
              </CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">Label</div>
          <div class="description">Label</div>
          <div class="example">
            <CDBListGroup style="width: 30rem">
              <CDBListGroupItem
                class="d-flex justify-content-between align-items-center"
              >
                List Group <CDBBadge color="primary">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem
                class="d-flex justify-content-between align-items-center"
              >
                List Group
                <CDBBadge color="secondary">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem
                class="d-flex justify-content-between align-items-center"
              >
                List Group
                <CDBBadge color="success">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem
                class="d-flex justify-content-between align-items-center"
              >
                List Group
                <CDBBadge color="danger">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem
                class="d-flex justify-content-between align-items-center"
              >
                List Group<CDBBadge color="info">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem
                class="d-flex justify-content-between align-items-center"
              >
                List Group<CDBBadge color="warning">19</CDBBadge>
              </CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">Color ListGroup</div>
          <div class="description">Color ListGroup</div>
          <div class="example">
            <CDBListGroup style="width: 30rem">
              <CDBListGroupItem color="primary">List Group </CDBListGroupItem>
              <CDBListGroupItem color="secondary">List Group</CDBListGroupItem>
              <CDBListGroupItem color="success">List Group</CDBListGroupItem>
              <CDBListGroupItem color="danger">List Group</CDBListGroupItem>
              <CDBListGroupItem color="info">List Group Link</CDBListGroupItem>
              <CDBListGroupItem color="warning">List Group</CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Icons
            <CDBBadge size="large" style="background-color: #9871ff"
              >Pro Component</CDBBadge
            >
          </div>
          <div class="description">Icons</div>
          <div class="example">
            <CDBListGroup style="width: 30rem">
              <CDBListGroupItem>
                <CDBIcon [fab]="true" icon="facebook" class="mr-3"></CDBIcon>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon
                  [fab]="true"
                  icon="facebook"
                  size="2x"
                  class="mr-3"
                ></CDBIcon>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon
                  [fab]="true"
                  icon="facebook"
                  size="2x"
                  class="mr-3"
                ></CDBIcon>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon
                  [fab]="true"
                  icon="facebook"
                  size="2x"
                  class="mr-3"
                ></CDBIcon>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon
                  [fab]="true"
                  icon="facebook"
                  size="2x"
                  class="mr-3"
                ></CDBIcon>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon
                  [fab]="true"
                  icon="facebook"
                  size="2x"
                  class="mr-3"
                ></CDBIcon>
                List Group
              </CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
      </section>
</page-layout>