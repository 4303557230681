import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-prodashboard',
  templateUrl: './prodashboard.component.html',
	styleUrls: ['./prodashboard.component.scss', '../pages.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProdashboardComponent implements OnInit {

  //Chart options

  options = {
		option1: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {display:false},
	    scales: {
	      xAxes: [{
	        gridLines: {
	        	display:false,
	        },
	        ticks: {
	          display: false
	        }
	      }],
	      yAxes: [{
	        gridLines: {
	        	display:false,
	        }   ,
	        ticks: {
	          display: false,
		        beginAtZero: true,
	        }
	      }]
	    }
		},
		option2: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {display:false},
	    scales: {
	      xAxes: [{
	        gridLines: {
	        	display:false,
	        },
	        ticks: {
	          display: false
	        }
	      }],
	      yAxes: [{
	        ticks: {
		        beginAtZero: true,
	        }
	      }],
	    }
		}
  }
  
  //CHART DATA
  data = {
    chart1: {
    	labels: [
	      "Eating",
	      "Drinking",
	      "Sleeping",
	      "Designing",
	      "Coding",
	      "Cycling",
	      "Running",
	    ],
	    datasets: [
	      {
	        label: "My Dataset",
	        lineTension: 0.1,
	        pointRadius: 0,
	        backgroundColor: "rgba(193, 240, 193, 1)",
	        borderColor: "rgb(39, 174, 96)",
	        borderWidth: 2,
	        data: [35, 45, 55, 50, 65, 57, 55],
	      },
	    ],
    },
    chart2: {
    	labels: [
	      "Eating",
	      "Drinking",
	      "Sleeping",
	      "Designing",
	      "Coding",
	      "Cycling",
	      "Running",
	    ],
	    datasets: [
	      {
	        label: "My Dataset",
	        lineTension: 0.1,
	        pointRadius: 0,
	        backgroundColor: "rgba(161, 198, 247, 1)",
	        borderColor: "rgb(47, 128, 237)",
	        borderWidth: 2,
	        data: [35, 45, 55, 50, 65, 57, 55],
	      },
	    ],
    },
    chart3: {
    	labels: [
	      "Eating",
	      "Drinking",
	      "Sleeping",
	      "Designing",
	      "Coding",
	      "Cycling",
	      "Running",
	    ],
      datasets: [
        {
          label: "My First dataset",
	        lineTension: 0.1,
	        pointRadius: 0,
	        backgroundColor: "rgba(153, 255, 255, 0.7)",
	        borderColor: "rgb(0, 179, 179)",
	        borderWidth: 2,
	        data: [35, 45, 55, 50, 65, 57, 55],
        },
        {
          label: "My Second dataset",
	        lineTension: 0.1,
	        pointRadius: 0,
	        backgroundColor: "rgba(193, 240, 193, 0.7)",
	        borderColor: "rgb(39, 174, 96)",
	        borderWidth: 2,
	        data: [58, 55, 46, 45, 49, 43, 35],
        },
	      {
	        label: "My Third dataset",
	        lineTension: 0.1,
	        pointRadius: 0,
	        backgroundColor: "rgba(161, 198, 247, 0.7)",
	        borderColor: "rgb(47, 128, 237)",
	        borderWidth: 2,
	        data: [50, 57, 56, 55, 59, 53, 45],
	      },
      ],
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
