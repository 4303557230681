<page-layout>
    <section id="tabs">
        <div class="blockcode pt-5">
          <div class="header">
            Default Tab
            <CDBBadge size="large" style="background-color: #9871ff"
              >Pro Component
            </CDBBadge>
          </div>
          <div class="description"></div>
          <div class="example3">
            <div class="container">
              <CDBNav [tab]="true" class="mt-5">
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab.activeTab === '1'"
                  (click)="customTab.setActiveTab('1')"
                >
                  Label 1
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab.activeTab === '2'"
                  (click)="customTab.setActiveTab('2')"
                >
                  Label 2
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab.activeTab === '3'"
                  (click)="customTab.setActiveTab('3')"
                >
                  Label 3
                </CDBTabLink>
              </CDBNav>
              <CDBTabContent #customTab="cdbTabContent" activeTab="1">
                <CDBTabPane tabId="1" [active]="customTab.activeTab === '1'">
                  <p class="mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil
                    odit magnam minima, soluta doloribus reiciendis molestiae placeat
                    unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat
                    ratione porro voluptate odit minima.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="2" [active]="customTab.activeTab === '2'">
                  <p class="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                  <p>
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="3" [active]="customTab.activeTab === '3'">
                  <p class="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                </CDBTabPane>
              </CDBTabContent>
            </div>
          </div>
        </div>
      
        <div class="blockcode">
          <div class="header">
            Tab Justified
            <CDBBadge size="large" style="background-color: #9871ff"
              >Pro Component
            </CDBBadge>
          </div>
          <div class="description"></div>
          <div class="example3">
            <div class="container">
              <CDBNav [tab]="true" [justified]="true" class="mt-5">
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab2.activeTab === '1'"
                  (click)="customTab2.setActiveTab('1')"
                >
                  Label 1
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab2.activeTab === '2'"
                  (click)="customTab2.setActiveTab('2')"
                >
                  Label 2
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab2.activeTab === '3'"
                  (click)="customTab2.setActiveTab('3')"
                >
                  Label 3
                </CDBTabLink>
              </CDBNav>
              <CDBTabContent #customTab2="cdbTabContent" activeTab="2">
                <CDBTabPane tabId="1" [active]="customTab2.activeTab === '1'">
                  <p class="mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil
                    odit magnam minima, soluta doloribus reiciendis molestiae placeat
                    unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat
                    ratione porro voluptate odit minima.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="2" [active]="customTab2.activeTab === '2'">
                  <p class="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                  <p>
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="3" [active]="customTab2.activeTab === '3'">
                  <p class="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                </CDBTabPane>
              </CDBTabContent>
            </div>
          </div>
        </div>
      
        <div class="blockcode">
          <div class="header">
            Tab with Icons
            <CDBBadge size="large" style="background-color: #9871ff"
              >Pro Component
            </CDBBadge>
          </div>
          <div class="description"></div>
          <div class="example3">
            <div class="container">
              <CDBNav [tab]="true" [justified]="true" class="mt-5">
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab3.activeTab === '1'"
                  (click)="customTab3.setActiveTab('1')"
                >
                  <CDBIcon icon="bomb" class="mr-2"></CDBIcon>
                  Label 1
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab3.activeTab === '2'"
                  (click)="customTab3.setActiveTab('2')"
                >
                  <CDBIcon icon="bomb" class="mr-2"></CDBIcon>
                  Label 2
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="/tabs"
                  [active]="customTab3.activeTab === '3'"
                  (click)="customTab3.setActiveTab('3')"
                >
                  <CDBIcon icon="bomb" class="mr-2"></CDBIcon>
                  Label 3
                </CDBTabLink>
              </CDBNav>
              <CDBTabContent
                #customTab3="cdbTabContent"
                activeTab="3"
                class="mt-5"
                style="margin-top: 30px"
              >
                <CDBTabPane tabId="1" [active]="customTab3.activeTab === '1'">
                  <p class="mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil
                    odit magnam minima, soluta doloribus reiciendis molestiae placeat
                    unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat
                    ratione porro voluptate odit minima.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="2" [active]="customTab3.activeTab === '2'">
                  <p class="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                  <p>
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="3" [active]="customTab3.activeTab === '3'">
                  <p class="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Nihil odit magnam minima, soluta doloribus
                    reiciendis molestiae placeat unde eos molestias.
                  </p>
                </CDBTabPane>
              </CDBTabContent>
            </div>
          </div>
        </div>
      </section>
</page-layout>