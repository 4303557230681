import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'Sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Sidebar implements OnInit {
  @Input() bgColor: String;
  @Input() color: String;
  constructor() { }

  ngOnInit(): void {
  }

}
