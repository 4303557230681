<page-layout>
    <div class="blockcode">
        <div class="header">Rect Progress Default </div>
        <div class="description"></div>
        <div class="example2">
            <div>
                <CDBProgress wrapperStyle="" value=69 text="69%"></CDBProgress>
            </div>
        </div>
    </div>
    <div class="blockcode">
        <div class="header">Rect Progress color variants </div>
        <div class="description"></div>
        <div class="example2">
            <div>
                <CDBProgress value=10 text="10%" color="primary"></CDBProgress>
            </div>
            <div>
                <CDBProgress value=20 text="20%" color="secondary"></CDBProgress>
            </div>
            <div>
                <CDBProgress value=70 text="70%" color="success"></CDBProgress>
            </div>
            <div>
                <CDBProgress value=40 text="40%" color="danger"></CDBProgress>
            </div>
            <div>
                <CDBProgress value=90 text="90%" color="info"></CDBProgress>
            </div>
            <div>
                <CDBProgress value=60 text="60%" color="warning"></CDBProgress>
            </div>
        </div>
    </div>
    <div class="blockcode">
        <div class="header">Fixed height</div>
        <div class="description"></div>
        <div class="example2">
            <div>
                <CDBProgress value=45 color="dark" height=30 text="45%"></CDBProgress>
            </div>
            <div>
                <CDBProgress value=60 color="success" height=20 text="60%"></CDBProgress>
            </div>
            <div>
                <CDBProgress value=80 color="danger" height=40 text="80%"></CDBProgress>
            </div>
        </div>
    </div>
</page-layout>