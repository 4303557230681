<div class="about2">
    <header class="header">
        <CDBNavbar class="px-0 bg-transparent" [light]=true expand="md" [scrolling]=true>
            <CDBNavBrand href="/">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
            </CDBNavBrand>
            <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
            <CDBCollapse #content expand="md" class="w-100" [navbar]=true>
                <CDBNavbarNav [left]=true>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Resources</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Blog</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Contact</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Support</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
                <CDBNavbarNav [right]=true>
                    <CDBNavItem class="d-flex align-items-center mr-3">
                        <CDBNavLink to="#">
                            <CDBIcon [fas]=true icon="moon"></CDBIcon>
                        </CDBNavLink>
                    </CDBNavItem>
                    <CDBNavItem [active]=true>
                        <CDBBtn [flat]=true [outline]=true
                            class="d-flex align-items-center py-1 px-3 m-0 bg-transparent"
                            style="border-color: #0000cc">
                            <CDBNavLink style="color: #0000cc" to="#">Hi, Sammy</CDBNavLink>
                            <CDBIcon style="color: #0000cc" icon="location-arrow"></CDBIcon>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
            </CDBCollapse>
        </CDBNavbar>
    </header>
    <div class="d-flex align-items-center">
        <CDBCardTitle class="page-title">About Us</CDBCardTitle>
        <img alt="Background" src="/assets/img/pages/about2.png" class="image" />
    </div>

    <section class="mb-5 text-center">
        <CDBCardTitle class="intro-title big">Our Amazing Team</CDBCardTitle>
        <CDBCardText>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate
            esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.
        </CDBCardText>
    </section>

    <section class="card-group">
        <CDBCard class="card">
            <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card1.png"></CDBCardImage>
            <CDBCardBody class="py-0">
                <CDBCardTitle class="card-title mt-0">Clark Williams</CDBCardTitle>
                <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Fugit, error amet numquam iure provident voluptate esse quasi.</CDBCardText>
                <div class="mt-3">
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="mx-2 p-2">
                        <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                    </CDBBtn>
                </div>
            </CDBCardBody>
        </CDBCard>
        <CDBCard class="card card-right">
            <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card2.png"></CDBCardImage>
            <CDBCardBody class="py-0">
                <CDBCardTitle class="card-title mt-0">Madison Jane</CDBCardTitle>
                <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Fugit, error amet numquam iure provident voluptate esse quasi.
                </CDBCardText>
                <div class="mt-3">
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                        <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                    </CDBBtn>
                </div>
            </CDBCardBody>
        </CDBCard>
        <CDBCard class="card">
            <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card3.png"></CDBCardImage>
            <CDBCardBody class="py-0">
                <CDBCardTitle class="card-title mt-0">Sarina Micheal</CDBCardTitle>
                <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Fugit, error amet numquam iure provident voluptate esse quasi.
                </CDBCardText>
                <div class="mt-3">
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                        <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                    </CDBBtn>
                </div>
            </CDBCardBody>
        </CDBCard>
        <CDBCard class="card card-right">
            <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card4.png"></CDBCardImage>
            <CDBCardBody class="py-0">
                <CDBCardTitle class="card-title mt-0">Stephanie Dani</CDBCardTitle>
                <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Fugit, error amet numquam iure provident voluptate esse quasi.
                </CDBCardText>
                <div class="mt-3">
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                        <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                    </CDBBtn>
                </div>
            </CDBCardBody>
        </CDBCard>
        <CDBCard class="card">
            <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card5.png"></CDBCardImage>
            <CDBCardBody class="py-0">
                <CDBCardTitle class="card-title mt-0">Tiff Amanda</CDBCardTitle>
                <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Fugit, error amet numquam iure provident voluptate esse quasi.
                </CDBCardText>
                <div class="mt-3">
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                        <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                    </CDBBtn>
                </div>
            </CDBCardBody>
        </CDBCard>
        <CDBCard class="card card-right">
            <CDBCardImage class="img-fluid w-100" src="/assets/img/pages/card6.png"></CDBCardImage>
            <CDBCardBody class="py-0">
                <CDBCardTitle class="card-title mt-0">Amara Wayne</CDBCardTitle>
                <CDBCardText [muted]=true>CEO/Founder</CDBCardText>
                <CDBCardText [muted]=true class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Fugit, error amet numquam iure provident voluptate esse quasi.
                </CDBCardText>
                <div class="mt-3">
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2 mx-2">
                        <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="p-2">
                        <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                    </CDBBtn>
                </div>
            </CDBCardBody>
        </CDBCard>
    </section>

    <section class="information align-items-center">
        <div>
            <CDBCardTitle class="mb-4 big">We create awesome products</CDBCardTitle>
            <CDBCardText>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure
                provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus
                veniam.</CDBCardText>
        </div>
        <CDBView class="mt-5">
            <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
        </CDBView>
    </section>

    <section class="contact-section">
        <div class="contact">
            <CDBCardTitle class="font-weight-bold mb-5" style="width: 300px; line-height: 1.5em">Want to get in
                touch?</CDBCardTitle>
            <!-- <CDBContainer> -->
            <div class="container">
                <CDBCardTitle class="mb-4">Contact Information</CDBCardTitle>
                <CDBCardText>
                    <CDBIcon class="mr-4" icon="map-marker-alt"></CDBIcon>New York, 94126 USA
                </CDBCardText>
                <CDBCardText>
                    <CDBIcon class="mr-4" icon="phone-alt"></CDBIcon>+ 01 234 567 89
                </CDBCardText>
                <CDBCardText>
                    <CDBIcon class="mr-4" icon="comment-alt"></CDBIcon>contact@example.com
                </CDBCardText>
            </div>
            <!-- </CDBContainer> -->
        </div>
        <!-- <CDBContainer class="contact-form"> -->
        <div class="container contact-form">
            <label htmlFor="contactName" class="text-muted m-0">
                Name
            </label>
            <CDBInput id="contactName" hint="Name" background class="mt-n3" type="text"></CDBInput>
            <label htmlFor="contactEmail" class="text-muted m-0">
                Email Address
            </label>
            <CDBInput id="contactEmail" hint="Email Address" background class="mt-n3" type="email"></CDBInput>
            <label htmlFor="contactMessage" class="text-muted m-0">
                Your Message
            </label>
            <CDBInput id="contactMessage" height="150px" hint="Type Message" background class="mt-n3" type="textarea">
            </CDBInput>
            <CDBBtn color="dark" class="btn-block">
                Send Message
            </CDBBtn>
        </div>
        <!-- </CDBContainer> -->
    </section>


    <footer class="footer mx-auto py-4">
        <div class="footer-head">
            <div class="align-self-center">
                <CDBLink to="/" class="d-flex align-items-center p-0 text-dark">
                    <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                    <span class="ml-3 h5 font-weight-bold">Devwares</span>
                </CDBLink>
                <div class="mt-5">
                    <CDBBtn [flat]=true color="dark">
                        <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark" class="mx-3">
                        <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark">
                        <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                    </CDBBtn>
                </div>
            </div>
            <div>
                <p class="h5 mb-4" style="font-weight: 600">Devwares</p>
                <ul style="list-style: none; cursor: pointer">
                    <li class="my-2"><a class="text-dark" href="/">Resources</a></li>
                    <li class="my-2"><a class="text-dark" href="/">About Us</a></li>
                    <li class="my-2"><a class="text-dark" href="/">Contact</a></li>
                    <li class="my-2"><a class="text-dark" href="/">Blog</a></li>
                </ul>
            </div>
            <div>
                <p class="h5 mb-4" style="font-weight: 600">Help</p>
                <ul style="list-style: none; cursor: pointer">
                    <li class="my-2"><a class="text-dark" href="/">Support</a></li>
                    <li class="my-2"><a class="text-dark" href="/">Sign Up</a></li>
                    <li class="my-2"><a class="text-dark" href="/">Sign In</a></li>
                </ul>
            </div>
            <div>
                <p class="h5 mb-4" style="font-weight: 600">Help</p>
                <ul style="list-style: none; cursor: pointer">
                    <li class="my-2"><a class="text-dark" href="/">Support</a></li>
                    <li class="my-2"><a class="text-dark" href="/">Sign Up</a></li>
                    <li class="my-2"><a class="text-dark" href="/">Sign In</a></li>
                </ul>
            </div>
        </div>
        <small class="text-center mt-5">&copy; Devwares, 2020. All rights reserved.</small>
    </footer>
    <!-- {/*<footer class="footer">
        <CDBLink to="/" class="footer-logo" style="cursor:"pointer">
            <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
            <span class="mb-0 ml-4 h5 font-weight-bold">Devwares</span>
        </CDBLink>
        <small>&copy; Devwares, 2020. All rights reserved.</small>
        <div class="footer-contact">
            <CDBBtn [flat]=true color="dark">
                <CDBIcon [fab]=true icon="facebook-f" />
            </CDBBtn>
            <CDBBtn [flat]=true color="dark" class="mx-3">
                <CDBIcon [fab]=true icon="twitter" />
            </CDBBtn>
            <CDBBtn [flat]=true color="dark">
                <CDBIcon [fab]=true icon="instagram" />
            </CDBBtn>
        </div>
    </footer>*/} -->
</div>