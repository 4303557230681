<div class="forgotPassword">
    <div class="position-relative">
        <img alt="Background" src="/assets/img/pages/signIn.png"
            style="position: absolute; width: 100%; height: 100vh" />
    </div>
    <div class="body">
        <CDBNavbar class="navigation bg-transparent" [light]=true expand="md" [scrolling]=true>
            <CDBNavBrand href="/" class="d-flex align-items-center">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                <span class="ml-3 h4 my-0 font-weight-bold">Devwares</span>
            </CDBNavBrand>
        </CDBNavbar>
        <CDBCard class="form">
            <div class="form-body">
                <CDBCardBody >
                    <div class="mt-4 mb-5">
                        <p class="h3 font-weight-bold">Forgot Password</p>
                    </div>
                    <label htmlFor="defaultRegisterEmail" class="text-muted m-0">
                        Email Address
                    </label>
                    <CDBInput id="defaultRegisterEmail" hint="Email Address" class="mt-n3" [background]=true type="email">
                    </CDBInput>
                    <CDBBtn color="dark" class="btn-block mb-3 mt-5">
                        Recover Password
                    </CDBBtn>
                    <p class="text-muted text-center">Return to <CDBLink style="font-weight: 600"
                            class="d-inline p-0 text-dark" to="#">Sign in</CDBLink>
                    </p>
                </CDBCardBody>
            </div>
        </CDBCard>
    </div>
</div>