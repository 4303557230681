<page-layout>
    <div class="blockcode pt-5">
        <div class="header">Spinner Types</div>
        <div class="description"></div>
        <div class="example">
            <CDBSpinner></CDBSpinner>
            <CDBSpinner color='danger'></CDBSpinner>
            <CDBSpinner color='success'></CDBSpinner>
            <CDBSpinner color='warning'></CDBSpinner>
            <CDBSpinner color='info'></CDBSpinner>
            <CDBSpinner color='dark'></CDBSpinner>
            <CDBSpinner color='secondary'></CDBSpinner>
        </div>
    </div>
    <div class="blockcode">
        <div class="header">Multicolor Spinner</div>
        <div class="description"></div>
        <div class="example">
            <CDBSpinner [multicolor]=true></CDBSpinner>
        </div>
    </div>
    <div class="blockcode">
        <div class="header">Size Variations</div>
        <div class="description"></div>
        <div class="example">
            <CDBSpinner size="big"></CDBSpinner>
            <CDBSpinner color='secondary'></CDBSpinner>
            <CDBSpinner color='success' size="small"></CDBSpinner>
        </div>
    </div>
</page-layout>