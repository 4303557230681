import { Component } from '@angular/core';

@Component({
  selector: 'app-button-toolbar',
  templateUrl: './button-toolbar.component.html',
  styleUrls: ['./button-toolbar.component.scss']
})
export class ButtonToolbarComponent {

}
