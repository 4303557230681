import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-signin3',
  templateUrl: './signin3.component.html',
  styleUrls: ['./signin3.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class Signin3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
