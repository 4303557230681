<page-layout>
    <section id="icon">
        <div class="blockcode pt-5">
          <div class="header">
            Default Icon
            <CDBBadge size="large" style="background-color: #9871ff">
              Pro Component
            </CDBBadge>
          </div>
          <div class="description">Default Icons</div>
          <div class="example">
            <CDBBtn color="primary">
              <CDBIcon [fab]="true" icon="stack-overflow"></CDBIcon>
              click me
            </CDBBtn>
            <CDBBtn color="secondary">
              <CDBIcon [fab]="true" icon="facebook"></CDBIcon>
              click me
            </CDBBtn>
            <CDBBtn color="success">
              <CDBIcon [fab]="true" icon="reddit"></CDBIcon>
              click me
            </CDBBtn>
            <CDBBtn color="danger">
              <CDBIcon [fab]="true" icon="twitter"></CDBIcon>
              click me
            </CDBBtn>
            <CDBBtn color="dark">
              <CDBIcon [fab]="true" icon="instagram"></CDBIcon>
              click me
            </CDBBtn>
            <CDBBtn color="warning">
              <CDBIcon [fab]="true" icon="google"></CDBIcon>
              click me
            </CDBBtn>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Border Icons
            <CDBBadge size="large" style="background-color: #9871ff">
              Pro Component
            </CDBBadge>
          </div>
          <div class="description">Border Icons</div>
          <div class="example">
            <CDBIcon [fab]="true" [border]="true" icon="stack-overflow"></CDBIcon>
            <CDBIcon [fab]="true" [border]="true" icon="facebook"></CDBIcon>
            <CDBIcon [fab]="true" [border]="true" icon="reddit"></CDBIcon>
            <CDBIcon [fab]="true" [border]="true" icon="twitter"></CDBIcon>
            <CDBIcon [fab]="true" [border]="true" icon="instagram"></CDBIcon>
            <CDBIcon [fab]="true" [border]="true" icon="google"></CDBIcon>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Spin Icons
            <CDBBadge size="large" style="background-color: #9871ff">
              Pro Component
            </CDBBadge>
          </div>
          <div class="description">Spin Icons</div>
          <div class="example">
            <CDBIcon [fab]="true" [spin]="true" icon="stack-overflow"></CDBIcon>
            <CDBIcon [fab]="true" [spin]="true" icon="facebook"></CDBIcon>
            <CDBIcon [fab]="true" [spin]="true" icon="reddit"></CDBIcon>
            <CDBIcon [fab]="true" [spin]="true" icon="twitter"></CDBIcon>
            <CDBIcon [fab]="true" [spin]="true" icon="instagram"></CDBIcon>
            <CDBIcon [fab]="true" [spin]="true" icon="google"></CDBIcon>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Pull Icons
            <CDBBadge size="large" style="background-color: #9871ff">
              Pro Component
            </CDBBadge>
          </div>
          <div class="description">Pull Icons</div>
          <div class="example">
            <CDBIcon [fab]="true" pull="left" icon="stack-overflow"></CDBIcon>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Pulse Icons
            <CDBBadge style="background-color: #9871ff" size="large">
              Pro Component
            </CDBBadge>
          </div>
          <div class="description">Pulse Icons</div>
          <div class="example">
            <CDBIcon [fab]="true" [pulse]="true" icon="stack-overflow"></CDBIcon>
            <CDBIcon [fab]="true" [pulse]="true" icon="facebook"></CDBIcon>
            <CDBIcon [fab]="true" [pulse]="true" icon="reddit"></CDBIcon>
            <CDBIcon [fab]="true" [pulse]="true" icon="twitter"></CDBIcon>
            <CDBIcon [fab]="true" [pulse]="true" icon="instagram"></CDBIcon>
            <CDBIcon [fab]="true" [pulse]="true" icon="google"></CDBIcon>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Flip Icons
            <CDBBadge size="large" style="background-color: #9871ff">
              Pro Component
            </CDBBadge>
          </div>
          <div class="description">Flip Icons</div>
          <div class="example">
            <CDBIcon [fab]="true" flip="horizontal" icon="stack-overflow"></CDBIcon>
            <CDBIcon [fab]="true" flip="vertical" icon="facebook"></CDBIcon>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Rotate Icons
            <CDBBadge size="large" style="background-color: #9871ff">
              Pro Component
            </CDBBadge>
          </div>
          <div class="description">Rotate Icons</div>
          <div class="example">
            <CDBIcon [fab]="true" rotate="90" icon="stack-overflow"></CDBIcon>
            <CDBIcon [fab]="true" rotate="180" icon="facebook"></CDBIcon>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Sizes
            <CDBBadge size="large" style="background-color: #9871ff">
              Pro Component
            </CDBBadge>
          </div>
          <div class="description">Sizes</div>
          <div class="example">
            <CDBIcon icon="facebook" [fab]="true" size="lg"></CDBIcon>
            <CDBIcon [fab]="true" icon="facebook" size="2x"></CDBIcon>
            <CDBIcon [fab]="true" icon="facebook" size="3x"></CDBIcon>
            <CDBIcon [fab]="true" icon="facebook" size="4x"></CDBIcon>
            <CDBIcon [fab]="true" icon="facebook" size="5x"></CDBIcon>
          </div>
        </div>
      </section>
</page-layout>