<page-layout>
    <div class="blockcode">
        <div class="header">Sidebar</div>
        <CDBSidebar textColor="#ffffff" backgroundColor="#333333">
            <CDBSidebarHeader [prefix]="icon">
                <ng-template #icon>
                    <CDBIcon icon="bars" size="lg"></CDBIcon>
                </ng-template>
    
                Contrast
            </CDBSidebarHeader>
    
            <CDBSidebarContent>
                <CDBSidebarMenu>
                    <CDBSidebarMenuItem icon="th-large" [suffix]="badge1" link="/sidebar">
                        <ng-template #badge1>
                            <CDBBadge color="danger" size="small" borderType="pill">new</CDBBadge>
                        </ng-template>
    
                        Dashboard
                    </CDBSidebarMenuItem>
    
                    <CDBSidebarMenuItem icon="sticky-note" [suffix]="badge2" link="/sidebar">
                        <ng-template #badge2>
                            <CDBBadge color="danger" size="small" borderType="pill">new</CDBBadge>
                        </ng-template>
    
                        Components
                    </CDBSidebarMenuItem>
    
                    <CDBSidebarMenuItem icon="directions" [suffix]="badge3" href="https://www.google.com">
                        <ng-template #badge3>
                            <CDBBadge color="danger" size="small" borderType="pill">new</CDBBadge>
                        </ng-template>
    
                        External Page
                    </CDBSidebarMenuItem>
                </CDBSidebarMenu>
    
                <CDBSidebarMenu>
                    <CDBSidebarSubMenu title="Sidemenu" icon="th">
                        <CDBSidebarMenuItem>submenu 1</CDBSidebarMenuItem>
                        <CDBSidebarMenuItem>submenu 2</CDBSidebarMenuItem>
                        <CDBSidebarMenuItem>submenu 3</CDBSidebarMenuItem>
                    </CDBSidebarSubMenu>
    
                    <CDBSidebarSubMenu title="Sidemenu2" icon="book" [suffix]="badge3">
                        <ng-template #badge3>
                            <CDBBadge color="danger" size="small" borderType="pill">new</CDBBadge>
                        </ng-template>
    
                        <CDBSidebarMenuItem>submenu 1</CDBSidebarMenuItem>
                        <CDBSidebarMenuItem>submenu 2</CDBSidebarMenuItem>
                        <CDBSidebarMenuItem>submenu 3</CDBSidebarMenuItem>
                    </CDBSidebarSubMenu>
    
                    <CDBSidebarSubMenu title="MultiLevel" icon="table" [suffix]="badge3">
                        <CDBSidebarMenuItem>submenu 1</CDBSidebarMenuItem>
                        <CDBSidebarMenuItem>submenu 2</CDBSidebarMenuItem>
    
                        <CDBSidebarSubMenu title="submenu 3">
                            <CDBSidebarMenuItem>submenu 3.1</CDBSidebarMenuItem>
                            <CDBSidebarMenuItem>submenu 3.2</CDBSidebarMenuItem>
    
                            <CDBSidebarSubMenu title="subnt">
                                <CDBSidebarMenuItem>submenu 3.3.1</CDBSidebarMenuItem>
                                <CDBSidebarMenuItem>submenu 3.3.2</CDBSidebarMenuItem>
                                <CDBSidebarMenuItem>submenu 3.3.3</CDBSidebarMenuItem>
                            </CDBSidebarSubMenu>
                        </CDBSidebarSubMenu>
                    </CDBSidebarSubMenu>
                </CDBSidebarMenu>
            </CDBSidebarContent>
    
            <div class="mt-auto">
                <CDBSidebarFooter style="text-align: center">
                    <div class="sidebar-btn-wrapper" style="padding: 20px 5px">
                        Sidebar Footer
                    </div>
                </CDBSidebarFooter>
            </div>
        </CDBSidebar>
    </div>
</page-layout>