import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-signin2',
  templateUrl: './signin2.component.html',
  styleUrls: ['./signin2.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class Signin2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
