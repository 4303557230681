<div class="chatui d-flex" style="height:100vh">
    <Sidebar bgColor="#333" color="#fff"></Sidebar>
    <div style="height:100%; width:100%; line-height:2em">
        <Navbar bg="#333" color="#fff"></Navbar>
        <div style="height:calc(100vh - 65px);overflow-y:scroll">
            <div class="d-flex w-100 panel-container">
                <div class="scroll-panel panel1">
                    <CDBPanel style="width: 100%;">
                        <CDBPane style="color: #fff;background-color: #333333;padding:15px" class="mt-3">
                            <CDBPaneImage src="../../../assets/img/pane/pane1.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size: 1.4em;color: #fff">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size: 1.4em; font-weight: bold; color: #fff">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane3.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane4.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane5.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane1.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane5.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane3.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane4.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane5.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane1.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane4.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane3.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                        <CDBPane class="message-card" style="padding: 15px">
                            <CDBPaneImage src="../../../assets/img/pane/pane4.png" style="min-width:2.5em" size="sm"></CDBPaneImage>
                            <div class="ml-3">
                                <CDBPanelTitle>Mezue</CDBPanelTitle>
                                <p class="small mb-0">Hey, how are you.</p>
                            </div>
                            <div class="d-flex flex-column ms-auto">
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em">Online</span>
                                </CDBPanelText>
                                <CDBPanelText style="font-size: 0.5em" class="px-3 text-muted">
                                    <span style="font-size:1.4em;font-weight: bold">19:32</span>
                                </CDBPanelText>
                            </div>
                        </CDBPane>
                    </CDBPanel>
                </div>
                <div class="scroll-panel panel2">
                    <CDBPanel style="width: 100%;">
                        <div style="overflow-y:scroll; overflow-x:hidden" class="scroll-panel">
                            <div class="d-flex">
                                <CDBPaneImage src="../../../assets/img/pane/pane1.png" class="mr-3 mt-5 pane-image"></CDBPaneImage>
                                <CDBPane style="align-self: start; width: 60%;color:#333333;
                                                    background-color: #f2f2f2; padding: 15px; position:relative" class="mt-4">
                                    <span style=" position: absolute; top: 0; left: -38px; z-index: 100;
                                                        display: block; width: 8px;height:13px"><svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 8 13" width="50" height="50">
                                            <path opacity=".13" fill="#f4f4f4"
                                                d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path>
                                            <path fill="#f4f4f4" d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path>
                                        </svg></span>
                                    <div class="ml-3">
                                        <CDBPanelText small>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                                            error amet numquam iure provident voluptate esse quasi.</CDBPanelText>
                                        <CDBPanelText style="font-size: 0.5em; display: flex; justify-content: end" class="mt-3 text-muted">
                                            <span style=" font-size: 1.4em; font-weight: bold; color: #333;
                                                                margin-left: auto">19:32</span>
                                        </CDBPanelText>
                                    </div>
                                </CDBPane>
                            </div>
                            <CDBPane style=" align-self: end; width: 60%; color: #fff; background-color: #333333;
                                                padding: 15px; margin-left: auto; margin-right: 30px; position: relative" class="mt-4">
                                <span style=" position: absolute; top: 0; right: 2px; z-index: 100;
                                                    display: block;width: 8px; height: 13px">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="50" height="50">
                                        <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z">
                                        </path>
                                        <path fill="#333333" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z">
                                        </path>
                                    </svg>
                                </span>
                                <div class="ml-3">
                                    <CDBPanelText small>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                                        error
                                        amet numquam iure provident voluptate esse quasi.</CDBPanelText>
                                    <CDBPanelText style="font-size: 0.5em" class="mt-3 text-muted">
                                        <span style="font-size:1.4em;font-weight: bold; color:#fff">19:32</span>
                                    </CDBPanelText>
                                </div>
                            </CDBPane>
                            <div class="d-flex">
                                <CDBPaneImage src="../../../assets/img/pane/pane1.png" class="mr-3 mt-5 pane-image"></CDBPaneImage>
                                <CDBPane style=" align-self: start; width: 60%; color: #333333;
                                                    background-color: #f2f2f2; padding: 15px; position: relative" class="mt-4">
                                    <span style="position: absolute; top: 0; left: -38px; z-index: 100;
                                                        display: block; width: 8px; height: 13px"><svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 8 13" width="50" height="50">
                                            <path opacity=".13" fill="#f4f4f4"
                                                d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path>
                                            <path fill="#f4f4f4" d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path>
                                        </svg></span>
                                    <div class="ml-3">
                                        <CDBPanelText small>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                                            error amet numquam iure provident voluptate esse quasi.</CDBPanelText>
                                        <CDBPanelText style="font-size: 0.5em; display: flex; justify-content: end" class="mt-3 text-muted">
                                            <span style=" font-size: 1.4em; font-weight: bold; color: #333;
                                                                margin-left: auto">19:32</span>
                                        </CDBPanelText>
                                    </div>
                                </CDBPane>
                            </div>
                            <CDBPane style=" align-self: end; width: 60%; color: #fff; background-color: #333333;
                                                padding: 15px; margin-left: auto; margin-right: 30px; position: relative" class="mt-4">
                                <span style=" position: absolute; top: 0; right: 2px; z-index: 100;
                                                    display: block;width: 8px; height: 13px">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="50" height="50">
                                        <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z">
                                        </path>
                                        <path fill="#333333" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z">
                                        </path>
                                    </svg>
                                </span>
                                <div class="ml-3">
                                    <CDBPanelText small>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                                        error
                                        amet numquam iure provident voluptate esse quasi.</CDBPanelText>
                                    <CDBPanelText style="font-size: 0.5em" class="mt-3 text-muted">
                                        <span style="font-size:1.4em;font-weight: bold; color: #fff">19:32</span>
                                    </CDBPanelText>
                                </div>
                            </CDBPane>
                            <CDBPane style=" align-self: end; width: 60%; color: #fff; background-color: #333333;
                                                padding: 15px; margin-left: auto; margin-right: 30px; position: relative" class="mt-4">
                                <span style=" position: absolute; top: 0; right: 2px; z-index: 100;
                                                    display: block;width: 8px; height: 13px">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="50" height="50">
                                        <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z">
                                        </path>
                                        <path fill="#333333" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z">
                                        </path>
                                    </svg>
                                </span>
                                <div class="ml-3">
                                    <CDBPanelText small>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                                        error
                                        amet numquam iure provident voluptate esse quasi.</CDBPanelText>
                                    <CDBPanelText style="font-size: 0.5em" class="mt-3 text-muted">
                                        <span style="font-size:1.4em;font-weight: bold; color: #fff">19:32</span>
                                    </CDBPanelText>
                                </div>
                            </CDBPane>
                            <div class="d-flex">
                                <CDBPaneImage src="../../../assets/img/pane/pane1.png" class="mr-3 mt-5 pane-image"></CDBPaneImage>
                                <CDBPane style=" align-self: start; width: 60%; color: #333333;
                                                    background-color: #f2f2f2; padding: 15px; position: relative" class="mt-4">
                                    <span style="position: absolute; top: 0; left: -38px; z-index: 100;
                                                        display: block; width: 8px; height: 13px"><svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 8 13" width="50" height="50">
                                            <path opacity=".13" fill="#f4f4f4"
                                                d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path>
                                            <path fill="#f4f4f4" d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path>
                                        </svg></span>
                                    <div class="ml-3">
                                        <CDBPanelText small>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                                            error amet numquam iure provident voluptate esse quasi.</CDBPanelText>
                                        <CDBPanelText style="font-size: 0.5em; display: flex; justify-content: end" class="mt-3 text-muted">
                                            <span style=" font-size: 1.4em; font-weight: bold; color: #333;
                                                                margin-left: auto">19:32</span>
                                        </CDBPanelText>
                                    </div>
                                </CDBPane>
                            </div>
                        </div>
                        <div class="d-flex mt-5">
                            <div class="d-flex mx-auto">
                                <CDBInput width="40vw" label="Type Message"></CDBInput>
                                <CDBBtn color="dark" style="width: 80px; max-height:50px; margin: 21px 0">Send
                                </CDBBtn>
                            </div>
                        </div>
                    </CDBPanel>
                </div>
                
            </div>
            <CDBFooter>
                <div class="d-flex justify-content-between align-items-center mx-auto py-4" style="width:80%">
                    <CDBLink to="/" class="d-flex align-items-center p-0 text-dark footer-rem">
                        <img alt="logo" src="../../../assets/img/pages/logo.png" width="25px" />
                        <span class="ml-4 h5 font-weight-bold">Devwares</span>
                    </CDBLink>
                    <small>&copy; Devwares, 2020. All rights reserved.</small>
                    <div class="footer-rem">
                        <CDBBtn flat class="p-1 bg-dark border-0">
                            <CDBIcon fab icon="facebook-f" ></CDBIcon>
                        </CDBBtn>
                        <CDBBtn flat class="mx-3 p-1 bg-dark border-0">
                            <CDBIcon fab icon="twitter" ></CDBIcon>
                        </CDBBtn>
                        <CDBBtn flat class="p-1 bg-dark border-0">
                            <CDBIcon fab icon="instagram" ></CDBIcon>
                        </CDBBtn>
                    </div>
                </div>
            </CDBFooter>
        </div>
    </div>
</div>