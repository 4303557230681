<div class="prodashboard2">
    <div class="d-flex">
        <div style="height:100vh;overflow:hidden initial">
            <Sidebar bgColor="#333" color="#fff"></Sidebar>
        </div>
        <div style="flex:1 1 auto; display:flex; flex-flow:column; height:100vh; overflow-y:hidden">
            <Navbar bg="#333" color="#fff"></Navbar>
            <div style="height:100%">
                <div class="scroll-container">
                    <div class="cards-container">
                        <div>
                            <CDBCard class="card-bg card-width">
                                <CDBCardBody class="d-flex flex-column p-0" style="position: relative;">
                                    <div class="d-flex align-items-center mx-4 mt-3">
                                        <div class="p-2 rounded-circle" style="background:#4F4F4F">
                                            <CDBIcon [fas]='true' icon="suitcase"></CDBIcon>
                                        </div>
                                        <CDBPanelTitle class="ml-3 small">MARKET SALES</CDBPanelTitle>
                                    </div>
                                    <div class="d-flex flex-wrap align-items-center py-4 mx-4">
                                        <CDBCardTitle class="fs h1 font-weight-bold mr-3" tag='h1'>$30,000
                                        </CDBCardTitle>
                                        <CDBCardText class="text-success mb-0" style="font-weight:600">
                                            <CDBIcon [fas]='true' class="p-0" icon="angle-up"></CDBIcon>
                                            <span class="ml-2" style="font-weight:600; font-size:0.9em">27.4%</span>
                                            <span style="font-size:0.8em" class="text-white mb-0 ml-3 d-block">Since last
                                                month</span>
                                        </CDBCardText>
                                    </div>
                                    <div style="margin:auto 0 -0.70rem -0.70rem">
                                        <canvas baseChart height="180" [datasets]="data.chart1.datasets"
                                            [labels]="data.chart1.labels" [options]="options.option1"
                                            chartType="line"></canvas>
                                    </div>
                                    <div class="d-flex c-p" style="position:absolute; bottom:20px; right:20px">
                                        <div class="p-2 rounded-circle bg-dark text-white">
                                            <CDBIcon [fas]='true' icon="arrow-right"></CDBIcon>
                                        </div>
                                    </div>
                                </CDBCardBody>
                            </CDBCard>
                        </div>
                        <div style="height: 100%;" class="gc-2">
                            <CDBCard style="height: 100%;" class="card-bg card-width">
                                <div
                                    style="display: flex;margin: 0px; flex-direction: column; padding: 0px; height: 100%; position: relative;justify-content: space-between;">
                                    <div class="d-flex align-items-center mx-4 mt-3">
                                        <div class="p-1 rounded-circle" style="background:#4F4F4F">
                                            <CDBIcon [fas]='true' icon="suitcase"></CDBIcon>
                                        </div>
                                        <CDBCardTitle class="ml-3 small">TRENDS</CDBCardTitle>
                                    </div>
                                    <div style="margin:auto 0 -0.70rem -0.70rem">
                                        <canvas class="canvas2" baseChart height="100" [datasets]="data.chart2.datasets"
                                            [labels]="data.chart2.labels" [options]="options.option1"
                                            chartType="line"></canvas>
                                    </div>
                                    <div class="card2-pos">
                                        <CDBCardText class="mb-0">This site rocks:</CDBCardText>
                                        <CDBCardTitle class="my-2 fs" style="font-weight:600">$330,000</CDBCardTitle>
                                        <CDBCardText class="mb-0">in global design community.</CDBCardText>
                                    </div>
                                    <div class="d-flex c-p" style="position:absolute; bottom:20px; right:20px">
                                        <div class="p-2 rounded-circle bg-dark text-white">
                                            <CDBIcon [fas]='true' icon="arrow-right"></CDBIcon>
                                        </div>
                                    </div>
                                </div>
                            </CDBCard>
                        </div>
                        <div style="height: 100%;" class="gr-2 w-100">
                            <CDBPanel style="height: 100%;" class="card-bg members gr-2 w-100 d-flex flex-column panel-card">
                                <CDBPane>
                                    <div class="rounded-circle p-2" style="background:#4F4F4F">
                                        <CDBIcon [fas]='true' icon="user"></CDBIcon>
                                    </div>
                                    <CDBPanelTitle class="ml-3 small">TEAM MEMBERS</CDBPanelTitle>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane5.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane1.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane3.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane4.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane5.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane1.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane2.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane3.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <CDBPane class="mt-4">
                                    <CDBPaneImage class="pane-image" src="../../../assets/img/pane/pane4.png">
                                    </CDBPaneImage>
                                    <div>
                                        <CDBPanelTitle style="font-weight:600">Mezue</CDBPanelTitle>
                                        <CDBPanelText style="font-size:0.7em">Online</CDBPanelText>
                                    </div>
                                    <div class="ms-auto">

                                        <CDBBtn [outline]='true' color="dark" [flat]='true' size="small"
                                            class="bg-transparent border-white text-white ms-auto"><span class="msg-rem">Send </span>Message</CDBBtn>
                                    </div>
                                </CDBPane>
                                <div class="d-flex ms-auto mt-auto c-p">
                                    <div class="p-2 rounded-circle bg-dark text-white">
                                        <CDBIcon [fas]='true' icon="arrow-right"></CDBIcon>
                                    </div>
                                </div>
                            </CDBPanel>
                        </div>
                        <div style="height: 100%;" class="gc-2 gr-2">
                            <CDBCard style="height: 100%;" class="card-bg card-width gc-2 gr-2">
                                <CDBCardBody class="p-0 w-100 d-flex flex-column" style="justify-content: space-between;">
                                    <div class="d-flex align-items-center mx-4 mt-3">
                                        <div class="p-1 rounded-circle" style="background:#4F4F4F">
                                            <CDBIcon [fas]='true' icon="suitcase"></CDBIcon>
                                        </div>
                                        <CDBCardTitle class="ml-3 small">PAGE VIEWS</CDBCardTitle>
                                    </div>
                                    <div class="badge-container">
                                        <div class="d-flex">
                                            <div class="shadow rounded d-flex flex-column p-2 text-white bg-primary mr-2"
                                                style="width:120px; height:100px">
                                                <p class="mb-0 small">Clicks</p>
                                                <p class="mt-auto mb-0 h3" style="font-weight:600">33k</p>
                                            </div>
                                            <div class="shadow rounded d-flex flex-column p-2 text-white bg-success mr-2"
                                                style="width:120px; height:100px">
                                                <p class="mb-0 small">Impressions</p>
                                                <p class="mt-auto mb-0 h3" style="font-weight:600">248k</p>
                                            </div>
                                        </div>
                                        <div class="d-flex batch2">
                                            <div class="shadow rounded d-flex flex-column p-2 mr-2"
                                                style="width:120px; height:100px">
                                                <p class="mb-0 small">Average CTR</p>
                                                <p class="mt-auto mb-0 h3" style="font-weight:600">18k</p>
                                            </div>
                                            <div class="shadow rounded d-flex flex-column p-2"
                                                style="width:120px; height:100px">
                                                <p class="mb-0 small">onHover</p>
                                                <p class="mt-auto mb-0 h3" style="font-weight:600">11.2s</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-auto">
                                        <canvas class="canvas3" baseChart [datasets]="data.chart3.datasets"
                                            [labels]="data.chart3.labels" [options]="options.option2"
                                            chartType="line"></canvas>
                                        <!-- <Line height={400} data={data.chart3} options={options.option2} ></Line> -->
                                    </div>
                                    <div class="d-flex ms-auto mr-4 mb-4 c-p">
                                        <div class="p-2 rounded-circle bg-dark text-white">
                                            <CDBIcon [fas]='true' icon="arrow-right"></CDBIcon>
                                        </div>
                                    </div>
                                </CDBCardBody>
                            </CDBCard>
                        </div>
                    </div>
                    <CDBFooter>
                        <div class="footer">
                            <CDBLink to="/" class="footer-link">
                                <img alt="logo" src="../../../assets/img/pages/logo.png" width="25px">
                                <span class="ml-4 mb-0 h5 font-weight-bold">Devwares</span>
                            </CDBLink>
                            <small>&copy; Devwares, 2020. All rights reserved.</small>
                            <div class="footer-rem">
                                <CDBBtn [flat]='true' class="p-1 bg-dark border-0">
                                    <CDBIcon [fab]='true' icon="facebook-f"></CDBIcon>
                                </CDBBtn>
                                <CDBBtn [flat]='true' class="mx-3 p-1 bg-dark border-0">
                                    <CDBIcon [fab]='true' icon="twitter"></CDBIcon>
                                </CDBBtn>
                                <CDBBtn [flat]='true' class="p-1 bg-dark border-0">
                                    <CDBIcon [fab]='true' icon="instagram"></CDBIcon>
                                </CDBBtn>
                            </div>
                        </div>
                    </CDBFooter>
                </div>
            </div>
        </div>
    </div>
</div>