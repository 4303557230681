<page-layout>
    <section id="select2">
        <div class="blockcode pt-5">
          <div class="header">
            Select
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component
            </CDBBadge>
          </div>
          <div class="description">Select Button</div>
          <div class="example">
            <CDBSelect2
              [options]="select2Options"
              [iconBrand]="true"
              selected="Choose an option"
              color="light-white"
            >
            </CDBSelect2>
          </div>
        </div>
      
        <div class="blockcode">
          <div class="description">
            Disabled Select Button
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component</CDBBadge
            >
          </div>
          <div class="example">
            <CDBSelect2
              [options]="select2Options"
              [iconBrand]="true"
              selected="Choose an option"
              [disabled]="true"
              color="none"
            >
            </CDBSelect2>
          </div>
        </div>
      
        <div class="blockcode">
          <div class="description">
            Colored Select Button
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component</CDBBadge
            >
          </div>
          <div class="example coll">
            <CDBSelect2
              [options]="select2Options"
              [iconBrand]="true"
              selected="Choose an option"
              color="light-primary"
            >
            </CDBSelect2>
            <CDBSelect2
              [options]="select2Options"
              [iconBrand]="true"
              selected="Choose an option"
              color="light-secondary"
            >
            </CDBSelect2>
            <CDBSelect2
              [options]="select2Options"
              [iconBrand]="true"
              selected="Choose an option"
              color="light-success"
            >
            </CDBSelect2>
            <CDBSelect2
              [options]="select2Options"
              [iconBrand]="true"
              selected="Choose an option"
              color="light-warning"
            >
            </CDBSelect2>
            <CDBSelect2
              [options]="select2Options"
              [iconBrand]="true"
              selected="Choose an option"
              color="light-dark"
            >
            </CDBSelect2>
          </div>
        </div>
      
        <div class="blockcode">
          <div class="description">
            Colored Options on Hover
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component</CDBBadge
            >
          </div>
          <div class="example">
            <CDBSelect2
              [options]="select2Options"
              [iconBrand]="true"
              selected="Choose an option"
              [hoverColor]="true"
              color="light-danger"
            >
            </CDBSelect2>
          </div>
        </div>
      </section>
</page-layout>