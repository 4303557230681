import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  option = [
    {
      text: 'Select Option',
      icon: 'stack-overflow',
    },
    {
      text: 'Another Option',
      icon: 'reddit',
    },
    {
      text: 'Option 3',
      icon: 'instagram',
    },
    {
      text: 'Option 4',
    },
    {
      text: 'Last Option',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}