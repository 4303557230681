<div class="accordion">
    <div class="d-flex">
      <div style="height: 100vh; overflow: hidden initial">
        <Sidebar bgColor="#fff" color="#333"></Sidebar>
      </div>
      <div class="text-dark"
        style="
          flex: 1 1 auto;
          display: flex;
          flex-flow: column;
          height: 100vh;
          overflow-y: scroll;
        "
      >
        <div style="height: 100%; width: 100%; line-height: 2em; padding-left: 16px;">
            <div class="blockcode pt-5">
                <div class="header">Default Accordion</div>
                <div class="description">Default Accordion</div>
                <CDBAccordion [data]='data'></CDBAccordion>
            </div>
            <div class="blockcode">
                <div class="header">Accordion without Icon</div>
                <div class="description">Accordion Without icon</div>
                <CDBAccordion [data]='data' [hideIcon]=true></CDBAccordion>
            </div>
        </div>
      </div>
    </div>
</div>
