import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-hero2',
  templateUrl: './hero2.component.html',
  styleUrls: ['./hero2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Hero2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
