<div class="app" style="display: flex; height: 100%; overflow: scroll initial">
  <CDBSidebar [textColor]="color" [backgroundColor]="bgColor">
    <CDBSidebarHeader [prefix]="icon">
      <ng-template #icon>
        <CDBIcon icon="bars" size="lg"></CDBIcon>
      </ng-template>

      Contrast
    </CDBSidebarHeader>

    <CDBSidebarContent>
      <CDBSidebarMenu class="sidebar-content">
        <CDBSidebarSubMenu
          title="Dashboards"
          textColor="#000000"
          backgroundColor="white"
          icon="columns"
        >
        <CDBSidebarMenuItem link="/"> dashboard </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/prodashboard"> prodashboard </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/prodashboard2">
            prodashboard dark
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/prodashboard3">
            prodashboard 3
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/prodashboard4">
            prodashboard 4
          </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>
        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="About"
          icon="address-card"
        >
          <CDBSidebarMenuItem href="/about" backgroundColor="#000000">
            About
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/about2" backgroundColor="white">
            About 2
          </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>
        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="Hero"
          icon="chalkboard-teacher"
        >
          <CDBSidebarMenuItem href="/hero"> hero </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/hero2"> hero 2 </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/hero3"> hero 3 </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/hero4"> hero 4 </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/hero5"> hero 5 </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>
      </CDBSidebarMenu>

      <CDBSidebarMenu class="sidebar-content">
        <CDBSidebarMenuItem icon="columns" link="/tables">
          Tables
        </CDBSidebarMenuItem>
        <CDBSidebarMenuItem icon="comments" link="/chatui">
          chat ui
        </CDBSidebarMenuItem>
        <CDBSidebarMenuItem icon="plane-arrival" href="/landing">
          landing
        </CDBSidebarMenuItem>
        <CDBSidebarMenuItem icon="mail-bulk" href="/post">
          post
        </CDBSidebarMenuItem>
        <CDBSidebarMenuItem icon="id-badge" link="/profile">
          profile
        </CDBSidebarMenuItem>
        <CDBSidebarMenuItem icon="money-bill-alt" href="/pricing">
          pricing
        </CDBSidebarMenuItem>
      </CDBSidebarMenu>

      <CDBSidebarMenu class="sidebar-content">
        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="Components"
          icon="cogs"
        >
          <CDBSidebarMenuItem link="/accordion"> Accordion </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/animation"> Animation </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/autocomplete">
            Autocomplete
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/box"> Box </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/card"> Card </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/carousel"> Carousel </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/checkbox"> CheckBox </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/collapse"> Collapse </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/dropdown"> Dropdown </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/forms"> Forms </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/icon"> Icon </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/iframe"> IFrame </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/input"> Input </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/input-group">
            Input Group
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/list-group">
            List Group
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/mask"> Mask </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/multiselect">
            Multiselect
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/pane"> Pane </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/panel"> Panel </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/radio"> Radio </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/rating"> Rating </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/select"> Select </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/select2"> Select2 </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/sidebar"> Sidebar </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/stepper"> Stepper </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/switch"> Switch </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/tabs"> Tabs </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>

        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="Buttons"
          icon="mouse"
        >
          <CDBSidebarMenuItem link="/badge"> Badge </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/button"> Button </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/button-group">
            Button Group
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/button-toolbar">
            Button Toolbar
          </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>

        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="Forms"
          icon="sign-in-alt"
        >
          <CDBSidebarMenuItem href="/create-account">
            create account
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/forgot-password">
            forgot password
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/forgot-password2">
            forgot password 2
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/forgot-password-popup">
            forgotpassword-popup
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/signin"> signin </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/signin2"> signin2 </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/signin3"> signin3 </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/signup"> signup </CDBSidebarMenuItem>
          <CDBSidebarMenuItem href="/signup2"> signup2 </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>

        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="Navigation"
          icon="directions"
        >
          <CDBSidebarMenuItem link="/breadcrumb">
            Breadcrumb
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/footer"> Footer </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/navbar"> Navbar </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/pagination">
            Pagination
          </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>

        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="Notification"
          icon="flag-checkered"
        >
          <CDBSidebarMenuItem link="/alert"> Alert </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/modal"> Modal </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/notification">
            Notification
          </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>

        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="Progress"
          icon="spinner"
        >
          <CDBSidebarMenuItem link="/progress"> Progress </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/slider"> Slider </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/spinner"> Spinner </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>

        <CDBSidebarSubMenu
          textColor="#000000"
          backgroundColor="white"
          title="Section"
          icon="calendar"
        >
          <CDBSidebarMenuItem link="/date-picker">
            Date Picker
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem link="/time-picker">
            Time Picker
          </CDBSidebarMenuItem>
        </CDBSidebarSubMenu>

        <!-- <CDBSidebarSubMenu
        textColor="#000000"
        backgroundColor="white"
        title="Tables"
        icon="table"
      >
        <CDBSidebarMenuItem  href="/data-table">
          Data Table
        </CDBSidebarMenuItem>

        <CDBSidebarMenuItem  href="/editable-table">
          Editable Table
        </CDBSidebarMenuItem>

        <CDBSidebarMenuItem  href="/table">
          Table
        </CDBSidebarMenuItem>
      </CDBSidebarSubMenu> -->
      </CDBSidebarMenu>
    </CDBSidebarContent>

    <CDBSidebarFooter style="text-align: center">
      <div class="sidebar-btn-wrapper" style="padding: 20px 5px">
        Sidebar Footer
      </div>
    </CDBSidebarFooter>
  </CDBSidebar>
</div>
