<div class="signIn2">
    <div class="position-relative">
        <img alt="Background" src="/assets/img/pages/signIn.png" class="image" />
    </div>
    <div class="body">
        <CDBNavbar class="navigation bg-transparent" [light]=true expand="md" [scrolling]=true>
            <CDBNavBrand href="/" class="d-flex align-items-center">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                <span class="ml-3 h4 my-0 font-weight-bold">Devwares</span>
            </CDBNavBrand>
        </CDBNavbar>
        <CDBCard class="form">
            <div class="form-body">
                <div class="mt-4 mb-5">
                    <p class="h3 font-weight-bold">Sign in</p>
                </div>
                <label htmlFor="defaultRegisterUsername" class="text-muted m-0">
                    Username
                </label>
                <CDBInput id="defaultRegisterUsername" class="mt-n3" [background]=true hint="Username" type="text">
                </CDBInput>
                <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                    Password
                </label>
                <CDBInput id="defaultRegisterPassword" class="mt-n3" [background]=true hint="Password" type="password">
                </CDBInput>
                <CDBLink style="font-weight: 600" class="d-inline p-0 text-dark" to="#">Forgot Password?</CDBLink>
                <CDBBtn color="dark" class="btn-block mb-3 mt-5">
                    Sign in
                </CDBBtn>
                <div class="d-flex align-items-center">
                    <span style="border: 0.5px solid black; width: 45%"></span>
                    <span class="text-center" style="width: 10%">OR</span>
                    <span style="border: 0.5px solid black; width: 45%"></span>
                </div>
                <div class="row my-3 d-flex justify-content-center">
                    <CDBBtn [flat]=true color="dark">
                        <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn color="dark" [flat]=true class="mx-2">
                        <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                    </CDBBtn>
                    <CDBBtn [flat]=true color="dark">
                        <CDBIcon [fab]=true icon="google-plus-g"></CDBIcon>
                    </CDBBtn>
                </div>
                <p class="text-muted text-center">Don't have an Account? <CDBLink style="font-weight: 600"
                        class="d-inline p-0 text-dark" to="#">Sign Up</CDBLink>
                </p>
            </div>
        </CDBCard>
    </div>
</div>