<div class="hero3">
    <header class="navigation">
        <CDBNavbar class="px-0 bg-transparent" [light]=true expand="lg" [scrolling]=true>
            <CDBNavBrand href="/">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
            </CDBNavBrand>
            <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
            <CDBCollapse #content [navbar]=true expand="lg" class="w-100">
                <CDBNavbarNav [left]=true>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" [active]=true to="#">Home</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Resources</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Blog</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Contact</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Support</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
                <CDBNavbarNav [right]=true>
                    <CDBNavItem>
                        <CDBBtn [flat]=true [outline]=true class="py-1 px-3 m-2 border-dark bg-transparent">
                            <CDBNavLink style="font-weight: 600;" [active]=true to="#">Sign In</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
            </CDBCollapse>
        </CDBNavbar>
    </header>
    <div class="page-body">
        <div class="page-info">
            <CDBCardTitle class="h1 big">Creativity is but skin deep</CDBCardTitle>
            <CDBCardText class="my-4" style="max-width: 500px">Lorem ipsum dolor sit amet, consectetur adipisicing
                elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum
                quisquam eum porro a pariatur accusamus veniam.</CDBCardText>
            <CDBBtn [flat]=true style="background: #8080ff; border: 2px #8080ff solid; width: 40%; max-width: 200px;">
                Start Now</CDBBtn>
        </div>
        <div class="image-container">
            <img alt="sideImage" src="/assets/img/pages/heroImage2.png" class="image1" />
            <img alt="sideImage" class="image2" src="/assets/img/pages/heroImage.png" />
        </div>
    </div>
    <div class="scroll-down">
        <CDBIcon [fas]=true icon="angle-double-down"></CDBIcon>
        <span class="ml-2">scroll down</span>
    </div>
</div>