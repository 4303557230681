<CDBNavbar class="px-0 mx-auto bg-transparent" style="width:80%" [light]='true' expand="md" [scrolling]='true'>
    <CDBNavBrand href="/" class="d-flex align-items-center">
        <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
        <span class="ml-3 h4 my-0 font-weight-bold">Devwares</span>
    </CDBNavBrand>
</CDBNavbar>
<div class="d-flex align-items-center justify-content-center">
    <div style="width:80%; max-width:375px">
        <CDBCard  class="border-0">
            <CDBCardBody class="my-3 p-0 mx-auto">
                <div class="mt-4 mb-5">
                    <p class="h4 text-center font-weight-bold">Create Account</p>
                </div>
                <div class="d-flex my-3">
                    <div style="width:80%">
                        <CDBBtn color="primary" style="width: 100%;" class="mr-1">
                            <CDBIcon class="float-left mt-1" [fab]='true' icon="facebook-f" ></CDBIcon>
                            Continue with facebook
                        </CDBBtn>
                    </div>
                    <div style="width:19%; margin-left: auto;">
                        <CDBBtn color="info" style="width:100%">
                            <CDBIcon [fab]='true' icon="twitter" ></CDBIcon>
                        </CDBBtn>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <span style="border:0.5px solid black; width:45%" ></span>
                    <span class="text-center" style="width:10%">OR</span>
                    <span style="border:0.5px solid black; width:45%" ></span>
                </div>
                <div style="min-width:250px">
                    <label htmlFor="defaultRegisterUsername" class="text-muted m-0">
                        Username
                    </label>
                    <CDBInput id="defaultRegisterUsername" class="mt-n3" background hint="Username" type="text" ></CDBInput>
                    <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                        Password
                    </label>
                    <CDBInput id="defaultRegisterPassword" class="mt-n3" background hint="Password" type="password" ></CDBInput>
                </div>
                <CDBBtn color="dark" class="btn-block w-100 mt-5">
                    Sign up
                </CDBBtn>
                <p class="text-muted mt-4 text-center">By clicking sign up you agree to the Contrast<CDBLink
                        style="font-weight:600" class="d-inline p-0 text-dark" to="#"> terms and conditions</CDBLink>
                </p>
                <p class="text-muted text-center my-5">Have an Account? <CDBLink style="font-weight:600"
                        class="d-inline p-0 text-dark" to="#">Sign in</CDBLink>
                </p>
            </CDBCardBody>
        </CDBCard>
    </div>
</div>