<page-layout>
    <div class="blockcode pt-5">
        <div class="header">Pill Badges</div>
        <div class="description">Default Styles Badges</div>
        <div class="example">
            <CDBBadge color="primary">Primary</CDBBadge>
            <CDBBadge color="secondary">Secondary</CDBBadge>
            <CDBBadge color="success">Success</CDBBadge>
            <CDBBadge color="danger">Danger</CDBBadge>
            <CDBBadge color="warning">Warning</CDBBadge>
            <CDBBadge color="info">Info</CDBBadge>
            <CDBBadge color="dark">Dark</CDBBadge>
        </div>
    </div>
    <div class="blockcode">
        <div class="header">Pill Badges</div>
        <div class="description">Pill Badges</div>
        <div class="example">
            <CDBBadge color="primary" borderType="pill">
                Primary
            </CDBBadge>
            <CDBBadge color="secondary" borderType="pill">
                Secondary Badge
            </CDBBadge>
            <CDBBadge color="success" borderType="pill">
                Success Badge
            </CDBBadge>
            <CDBBadge color="danger" borderType="pill">
                Danger Badge
            </CDBBadge>
            <CDBBadge color="warning" borderType="pill">
                Warning Badge
            </CDBBadge>
            <CDBBadge color="info" borderType="pill">
                Info Badge
            </CDBBadge>
            <CDBBadge color="dark" borderType="pill">
                Dark Badge
            </CDBBadge>
        </div>
    </div>
    <div class="blockcode">
        <div class="header">Sizes Variations</div>
        <div class="description">Different variations of Badges Sizes</div>
        <div class="example">
            <CDBBadge color="secondary" size="large">
                Large
            </CDBBadge>
            <CDBBadge color="primary">Medium</CDBBadge>
            <CDBBadge color="success" size="small">
                Small
            </CDBBadge>
        </div>
    </div>
    <div class="blockcode">
        <div class="header">Flat Badges</div>
        <div class="description">Flat badges</div>
        <div class="example">
            <CDBBadge color="primary" flat=true>
                Flat Primary
            </CDBBadge>
            <CDBBadge color="secondary" flat=true>
                Flat Secondary
            </CDBBadge>
            <CDBBadge color="success" flat=true>
                Flat Success
            </CDBBadge>
            <CDBBadge color="danger" flat=true>
                Flat Danger
            </CDBBadge>
            <CDBBadge color="warning" flat=true>
                Flat Warning
            </CDBBadge>
            <CDBBadge color="info" flat=true>
                Flat Info
            </CDBBadge>
            <CDBBadge color="dark" flat=true>
                Flat Dark
            </CDBBadge>
        </div>
    </div>
</page-layout>
