<div class="post">
    <header class="navigation">
        <CDBNavbar class="px-0 bg-transparent" [light]=true expand="md" [scrolling]=true>
            <CDBNavBrand href="/">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
            </CDBNavBrand>
            <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
            <CDBCollapse #content expand="md" class="w-100" [navbar]=true>
                <CDBNavbarNav [left]=true>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Resources</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink [active]=true to="#">Blog</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Contact</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Support</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
            </CDBCollapse>
        </CDBNavbar>
    </header>
    <div class="page-body">
        <img alt="postImage" src="/assets/img/pages/postImage.png" width="100%" />
        <p class="page-title">iPhone 12: Apple makes jump to 5g</p>
        <CDBPane class="mt-3">
            <CDBPaneImage src="/assets/img/pages/pane.png" class="my-auto"></CDBPaneImage>
            <div class="ml-4">
                <CDBCardTitle class="h6 fw-600 my-0">By Leo Kelion</CDBCardTitle>
                <CDBCardText [small]=true>Technology desk editor</CDBCardText>
            </div>
        </CDBPane>
        <section class="section1">
            <main class="main">
                <p class="fw-600">Apple has confirmed its iPhone 12 handsets will be its first to work on faster 5G
                    networks.</p>
                <p>The company has also extended the range to include a new "Mini" model that has a smaller 5.4in
                    screen.</p>
                <p>The US firm bucked a wider industry downturn by increasing its handset sales over the past year.</p>
                <p>But some experts say the new features give Apple its best opportunity for growth since 2014, when it
                    revamped its line-up with the iPhone 6.</p>
                <p>"5G will bring a new level of performance for downloads and uploads, higher quality video streaming,
                    more responsive gaming, real-time interactivity and so much more," said chief executive Tim Cook.
                </p>
                <p>There has also been a cosmetic refresh this time round, with the sides of the devices getting
                    sharper, edges.</p>
                <img alt="postImage" src="/assets/img/pages/postImage2.png" width="100%" class="mb-3" />
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                    deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                    provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum
                    fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                    placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem
                    quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates
                    repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut
                    aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus.</p>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                    deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                    provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum
                    fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                    placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem
                    quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates
                    repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut
                    aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus.</p>
                <div class="demo">
                    <span>"</span>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                        deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                        provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et
                        dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                </div>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                    deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                    provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum
                    fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                    placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem
                    quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates
                    repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut
                    aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus.</p>
            </main>
            <aside class="aside">
                <p class="h4 fw-600 aside-title">Related Posts</p>
                <CDBCard class="aside-card" style="border: none; margin-bottom: 1rem;">
                    <CDBCardBody class="p-0">
                        <CDBCardImage src="/assets/img/pages/post1.png"></CDBCardImage>
                        <CDBCardTitle class="fw-600 h5">Global Warming: Is humanity doomed to a new ice age
                        </CDBCardTitle>
                        <CDBCardText style="cursor: pointer" class="fw-600 text-right mt-auto">
                            See More
                            <CDBIcon [fas]=true class="ml-1" icon="arrow-right"></CDBIcon>
                        </CDBCardText>
                    </CDBCardBody>
                </CDBCard>
                <CDBCard class="aside-card" style="border: none; margin-bottom: 1rem;">
                    <CDBCardBody class="p-0">
                        <CDBCardImage src="/assets/img/pages/post2.png"></CDBCardImage>
                        <CDBCardTitle class="fw-600 h5">Five Reasons to keep your massage palor reservation
                        </CDBCardTitle>
                        <CDBCardText style="cursor: pointer" class="fw-600 text-right mt-auto">
                            See More
                            <CDBIcon [fas]=true class="ml-1" icon="arrow-right"></CDBIcon>
                        </CDBCardText>
                    </CDBCardBody>
                </CDBCard>
                <CDBCard class="aside-card" style="border: none;margin-bottom: 1rem;">
                    <CDBCardBody class="p-0">
                        <CDBCardImage src="/assets/img/pages/post3.png"></CDBCardImage>
                        <CDBCardTitle class="fw-600 h5">Top 10 best rated tech companies in the world</CDBCardTitle>
                        <CDBCardText style="cursor: pointer" class="fw-600 text-right mt-auto">
                            See More
                            <CDBIcon [fas]=true class="ml-1" icon="arrow-right"></CDBIcon>
                        </CDBCardText>
                    </CDBCardBody>
                </CDBCard>
                <CDBCard class="aside-card" style="border: none;margin-bottom: 1rem;">
                    <CDBCardBody class="p-0">
                        <CDBCardImage src="/assets/img/pages/post4.png"></CDBCardImage>
                        <CDBCardTitle class="fw-600 h5">Ways to raise your market value</CDBCardTitle>
                        <CDBCardText style="cursor: pointer" class="fw-600 text-right mt-auto">
                            See More
                            <CDBIcon [fas]=true class="ml-1" icon="arrow-right"></CDBIcon>
                        </CDBCardText>
                    </CDBCardBody>
                </CDBCard>
            </aside>
        </section>
        <section class="section2">
            <p class="h4 fw-600">Comments</p>
            <CDBPane class="write-comment">
                <CDBPaneImage src="/assets/img/pane/pane1.png"></CDBPaneImage>
                <CDBInput hint="Write Comment" class="mb-0" type="text"></CDBInput>
                <CDBBtn [flat]=true color="dark">Post</CDBBtn>
            </CDBPane>
            <CDBPane class="pane">
                <CDBPaneImage src="/assets/img/pane/pane2.png"></CDBPaneImage>
                <div>
                    <p class="fw-600">Clark Williams</p>
                    <p class="comment-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
                        amet numquam iure provident voluptate esse quasi.</p>
                </div>
            </CDBPane>
            <CDBPane class="pane">
                <CDBPaneImage src="/assets/img/pane/pane3.png"></CDBPaneImage>
                <div>
                    <p class="fw-600">Clark Williams</p>
                    <p class="comment-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
                        amet numquam iure provident voluptate esse quasi.</p>
                </div>
            </CDBPane>
            <CDBPane class="pane">
                <CDBPaneImage src="/assets/img/pane/pane4.png"></CDBPaneImage>
                <div>
                    <p class="fw-600">Clark Williams</p>
                    <p class="comment-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
                        amet numquam iure provident voluptate esse quasi.</p>
                </div>
            </CDBPane>
            <CDBPane class="pane">
                <CDBPaneImage src="/assets/img/pane/pane5.png"></CDBPaneImage>
                <div>
                    <p class="fw-600">Clark Williams</p>
                    <p class="comment-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
                        amet numquam iure provident voluptate esse quasi.</p>
                </div>
            </CDBPane>
            <p class="text-center mt-3" style="cursor: pointer">View more comments
                <CDBIcon [fas]=true icon="angle-down"></CDBIcon>
            </p>
        </section>
    </div>
    <footer class="footer">
        <CDBLink href="/" class="footer-logo">
            <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
            <span class="mb-0 ml-4 h5 font-weight-bold">Devwares</span>
        </CDBLink>
        <small>&copy; Devwares, 2020. All rights reserved.</small>
        <div class="footer-contact">
            <CDBBtn [flat]=true color="dark" class="p-2">
                <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
            </CDBBtn>
            <CDBBtn [flat]=true color="dark" class="p-2 mx-3">
                <CDBIcon [fab]=true icon="twitter"></CDBIcon>
            </CDBBtn>
            <CDBBtn [flat]=true color="dark" class="p-2">
                <CDBIcon [fab]=true icon="instagram"></CDBIcon>
            </CDBBtn>
        </div>
    </footer>
</div>