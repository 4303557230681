import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-prodashboard4',
  templateUrl: './prodashboard4.component.html',
  styleUrls: ['./prodashboard4.component.scss', '../pages.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Prodashboard4Component implements OnInit {


  //chart data
  data = {
    chart1: {
    	labels: [
	      "Eating",
	      "Drinking",
	      "Sleeping",
	      "Designing",
	      "Coding",
	      "Cycling",
	      "Running",
	    ],
	    datasets: [
	      {
	        label: "My Dataset",
	        pointRadius: 0,
	        backgroundColor: "rgba(47, 128, 237, 0.3)",
	        borderColor: "rgb(47, 128, 237)",
	        borderWidth: 2,
	        data: [35, 50, 55, 50, 55, 50, 53],
	      },
	    ],
    },
    chart2: {
		  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		  datasets: [
		    {
		      label: '# of Blue Votes',
		      data: [2, 3, 20, 5, 1, 4, 3, 10, 13, 15, 22, 30],
		      backgroundColor: '#F2994A',
		    },
		    {
		      label: '# of Green Votes',
		      data: [3, 10, 13, 15, 22, 30, 2, 3, 20, 5, 1, 4],
		      backgroundColor: '#2D9CDB',
		    },
		  ],
		}
  }

  //chart options 
  options = {
		option1: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {display:false},
	    scales: {
	      xAxes: [{
	        gridLines: {
	          display: false
	        },
	        ticks: {
	          display: false
	        }
	      }],
	      yAxes: [{
	        ticks: {
	          display: false
	        }
	      }],
	    }
		},
		option2: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {display:false},
		  scales: {
		    yAxes: [{
	        stacked: true,
	        ticks: {
	          beginAtZero: true,
	        },
		    }],
		    xAxes: [{
	        gridLines: {
	          display: false
	        },
		      stacked: true,
		    }],
		  },
		}
  }  
  height = window.innerHeight
  constructor() { }

  ngOnInit(): void {
  }

}
