<page-layout>
    <div class="blockcode pt-5">
        <div class="header">Date Picker</div>
        <div class="example">
            <CDBDatePicker [config]="exampleOptions" theme="light" placeholder="Pick a date!">
            </CDBDatePicker>
            <!-- <CDBDatePicker [config]="exampleOptions2" theme="dark" placeholder="Pick a date!">
            </CDBDatePicker>
            <CDBDatePicker [config]="exampleOptions" theme="material_green" placeholder="Pick a date!">
            </CDBDatePicker>
            <CDBDatePicker [config]="exampleOptions2" theme="material_blue" placeholder="Pick a date!">
            </CDBDatePicker>
            <CDBDatePicker [config]="exampleOptions" theme="material_red" placeholder="Pick a date!">
            </CDBDatePicker>
            <CDBDatePicker [config]="exampleOptions2" theme="airbnb" placeholder="Pick a date!">
            </CDBDatePicker>
            <CDBDatePicker [config]="exampleOptions" theme="material_orange" placeholder="Pick a date!">
            </CDBDatePicker>
            <CDBDatePicker [config]="exampleOptions2" theme="confetti" placeholder="Pick a date!">
            </CDBDatePicker> -->
        </div>
    </div>
</page-layout>