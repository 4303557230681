import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-hero5',
  templateUrl: './hero5.component.html',
  styleUrls: ['./hero5.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class Hero5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
