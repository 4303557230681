<div class="pricing">
    <header class="navigation">
        <CDBNavbar class="bg-transparent p-0" expand="md" [light]=true [scrolling]=true>
            <CDBNavBrand href="/">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
            </CDBNavBrand>
            <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
            <CDBCollapse #content [navbar]=true expand="md" class="w-100">
                <CDBNavbarNav [left]=true>
                    <CDBNavItem [active]=true>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink [active]=true to="#">Home</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Resources</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Blog</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Contact</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink to="#">Support</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
                <CDBNavbarNav right>
                    <CDBNavItem>
                        <CDBBtn [flat]=true [outline]=true class="my-2 py-1 px-5 border-dark bg-transparent">
                            <CDBNavLink [active]=true to="#">Preview</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </CDBNavbarNav>
            </CDBCollapse>
        </CDBNavbar>
    </header>
    <div class="page-body">
        <div class="page-info">
            <CDBCardTitle class="page-title">Our Pricing</CDBCardTitle>
            <CDBCardText>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure
                provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus
                veniam.</CDBCardText>
        </div>
        <div class="my-5">
            <!-- <CDBContainer class="pricing-container shadow-sm bg-light"> -->
            <div class="container pricing-container shadow-sm bg-light">

                <div class="price-info">
                    <CDBCardTitle class="h2 font-weight-bold">$0</CDBCardTitle>
                    <CDBCardText class="fw-600">Free</CDBCardText>
                    <CDBCardText>Great for personal use and for your side projects.</CDBCardText>
                </div>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <div class="btn-container">
                    <CDBBtn color="dark" class="px-4 w-100">Set Plan</CDBBtn>
                </div>
                <p class="view-details fw-600">View Details</p>
            </div>
            <!-- </CDBContainer> -->
            <!-- <CDBContainer class="pricing-container shadow-sm bg-light"> -->
            <div class="container pricing-container shadow-sm bg-light">

                <div class="price-info">
                    <CDBCardTitle class="h2 font-weight-bold">$0</CDBCardTitle>
                    <CDBCardText class="fw-600">Free</CDBCardText>
                    <CDBCardText>Great for personal use and for your side projects.</CDBCardText>
                </div>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <div class="btn-container">
                    <CDBBtn color="dark" class="px-4 w-100">Set Plan</CDBBtn>
                </div>
                <p class="view-details fw-600">View Details</p>
            </div>
            <!-- </CDBContainer> -->
            <!-- <CDBContainer class="pricing-container shadow-sm bg-light"> -->
            <div class="container pricing-container shadow-sm bg-light">

                <div class="price-info">
                    <CDBCardTitle class="h2 font-weight-bold">$0</CDBCardTitle>
                    <CDBCardText class="fw-600">Free</CDBCardText>
                    <CDBCardText>Great for personal use and for your side projects.</CDBCardText>
                </div>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <ul class="list">
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                    <li>30 Templates</li>
                </ul>
                <div class="btn-container">
                    <CDBBtn color="dark" class="px-4 w-100">Set Plan</CDBBtn>
                </div>
                <p class="view-details fw-600">View Details</p>
            </div>
            <!-- </CDBContainer> -->
        </div>
    </div>
</div>