<div class="landing">
    <div class="landing-bg">
        <img alt="landing-bg" src="/assets/img/pages/landingRectangle.png" class="page-bg" />
    </div>
    <div class="page">
        <header class="page-header">
            <CDBNavbar class="navigation bg-transparent p-0" expand="lg" [dark]=true [scrolling]=true>
                <CDBNavBrand href="/">
                    <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                </CDBNavBrand>
                <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
                <CDBCollapse #content expand="lg" [navbar]=true class="w-100">
                    <CDBNavbarNav [left]=true>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink [active]=true to="#">Home</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#">Resources</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#">Blog</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#">Contact</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#">Support</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                    </CDBNavbarNav>
                    <CDBNavbarNav right>
                        <CDBNavItem>
                            <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                                <CDBNavLink to="#">Sign In</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                            <CDBBtn [flat]=true [outline]=true class="my-2 py-1 px-3 border-white bg-transparent">
                                <CDBNavLink to="#">Sign Up</CDBNavLink>
                            </CDBBtn>
                        </CDBNavItem>
                    </CDBNavbarNav>
                </CDBCollapse>
            </CDBNavbar>
        </header>
        <div class="page-body">
            <section class="page-head">
                <img alt="landing" src="/assets/img/pages/landing.png" class="image" />
                <div class="page-info">
                    <p class="page-title">Creativity is but skin deep</p>
                    <p class="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet
                        numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro
                        a pariatur accusamus veniam.</p>
                    <CDBBtn [flat]=true style="background: #8080ff; border: 2px #8080ff solid">Start Now</CDBBtn>
                </div>
            </section>
            <section class="section1 center">
                <div class="section-info">
                    <p class="section-title">Our Product Features</p>
                    <p class="section-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
                        amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum
                        porro a pariatur accusamus veniam.</p>
                </div>
                <div class="features">
                    <div class="feature">
                        <CDBIcon [fas]=true size="4x" icon="shopping-bag"></CDBIcon>
                        <p class="fw-600">Feature 1</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure
                            provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a
                            pariatur accusamus veniam.</p>
                    </div>
                    <div class="feature">
                        <CDBIcon [fas]=true size="4x" icon="music"></CDBIcon>
                        <p class="fw-600">Feature 2</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure
                            provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a
                            pariatur accusamus veniam.</p>
                    </div>
                    <div class="feature">
                        <CDBIcon [fas]=true size="4x" icon="infinity"></CDBIcon>
                        <p class="fw-600">Feature 3</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure
                            provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a
                            pariatur accusamus veniam.</p>
                    </div>
                </div>
            </section>
            <section class="section2 center">
                <div class="section-info">
                    <p class="section-title">Our Pricing</p>
                    <p class="section-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
                        amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum
                        porro a pariatur accusamus veniam.</p>
                </div>
                <div class="my-5">
                    <!-- <CDBContainer class="pricing-container shadow-sm bg-light"> -->
                    <div class="container pricing-container shadow-sm bg-light">
                        <div class="price-info">
                            <p class="h2 font-weight-bold">$0</p>
                            <p class="fw-600">Free</p>
                            <p>Great for personal use and for your side projects.</p>
                        </div>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <div class="btn-container">
                            <CDBBtn color="dark" class="px-4 w-100">Set Plan</CDBBtn>
                        </div>
                        <p class="view-details fw-600">View Details</p>
                    </div>
                    <!-- </CDBContainer> -->
                    <!-- <CDBContainer class="pricing-container shadow-sm bg-light"> -->
                    <div class="container pricing-container shadow-sm bg-light">
                        <div class="price-info">
                            <p class="h2 font-weight-bold">$0</p>
                            <p class="fw-600">Free</p>
                            <p>Great for personal use and for your side projects.</p>
                        </div>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <div class="btn-container">
                            <CDBBtn color="dark" class="px-4 w-100">Set Plan</CDBBtn>
                        </div>
                        <p class="view-details fw-600">View Details</p>
                    </div>
                    <!-- </CDBContainer> -->
                    <!-- <CDBContainer class="pricing-container shadow-sm bg-light"> -->
                    <div class="container pricing-container shadow-sm bg-light">

                        <div class="price-info">
                            <p class="h2 font-weight-bold">$0</p>
                            <p class="fw-600">Free</p>
                            <p>Great for personal use and for your side projects.</p>
                        </div>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <ul class="list">
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                            <li>30 Templates</li>
                        </ul>
                        <div class="btn-container">
                            <CDBBtn color="dark" class="px-4 w-100">Set Plan</CDBBtn>
                        </div>
                        <p class="view-details fw-600">View Details</p>
                    </div>
                    <!-- </CDBContainer> -->
                </div>
            </section>
            <section class="section3 center">
                <div class="section-info">
                    <p class="section-title">Product Highlight</p>
                    <p class="section-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
                        amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum
                        porro a pariatur accusamus veniam.</p>
                </div>
                <CDBView>
                    <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
                </CDBView>
            </section>
            <section class="section4">
                <div class="section-info">
                    <p class="section-title center">Want to get in touch?</p>
                </div>
                <div class="contact">
                    <!-- <CDBContainer class="contact-form"> -->
                    <div class="container contact-form">
                        <label htmlFor="contactName" class="text-muted m-0">
                            Name
                        </label>
                        <CDBInput id="contactName" hint="Name" background class="mt-n3" type="text"></CDBInput>
                        <label htmlFor="contactEmail" class="text-muted m-0">
                            Email Address
                        </label>
                        <CDBInput id="contactEmail" hint="Email Address" background class="mt-n3" type="email">
                        </CDBInput>
                        <label htmlFor="contactMessage" class="text-muted m-0">
                            Your Message
                        </label>
                        <CDBInput id="contactMessage" height="150px" hint="Type Message" background class="mt-n3"
                            type="textarea"></CDBInput>
                        <CDBBtn color="dark" class="btn-block">
                            Send Message
                        </CDBBtn>
                    </div>
                    <!-- </CDBContainer> -->
                    <!-- <CDBContainer class="contact-info"> -->
                    <div class="container contact-info">
                        <p class="h2 mb-5">Contact Information</p>
                        <p>
                            <CDBIcon class="mr-5" icon="map-marker-alt"></CDBIcon>New York, 94126 USA
                        </p>
                        <p>
                            <CDBIcon class="mr-5" icon="phone-alt"></CDBIcon>+ 01 234 567 89
                        </p>
                        <p>
                            <CDBIcon class="mr-5" icon="comment-alt"></CDBIcon>contact@example.com
                        </p>
                    </div>
                    <!-- </CDBContainer> -->
                </div>
            </section>
        </div>
        <footer class="footer">
            <CDBLink href="/" class="footer-logo">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                <span class="mb-0 ml-4 h5 font-weight-bold">Devwares</span>
            </CDBLink>
            <small>&copy; Devwares, 2020. All rights reserved.</small>
            <div class="footer-contact">
                <CDBBtn [flat]=true color="dark" class="p-2">
                    <CDBIcon [fab]=true icon="facebook-f"></CDBIcon>
                </CDBBtn>
                <CDBBtn [flat]=true color="dark" class="p-2 mx-3">
                    <CDBIcon [fab]=true icon="twitter"></CDBIcon>
                </CDBBtn>
                <CDBBtn [flat]=true color="dark" class="p-2">
                    <CDBIcon [fab]=true icon="instagram"></CDBIcon>
                </CDBBtn>
            </div>
        </footer>
    </div>
</div>