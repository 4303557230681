<page-layout>
    <div class="blockcode pt-5">
        <div class="header">Time Picker</div>
        <div class="example">
            <CDBBadge size="large" style="background-color:#9871ff">
                Pro Component
            </CDBBadge>
            <CDBTimepicker placeholder="10:05 AM" theme="SoundCloud" colorPalette="dark">
            </CDBTimepicker>
        </div>
    </div>
</page-layout>