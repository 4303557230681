<page-layout>
    <section id="radio">
        <div class="blockcode pt-5">
          <div class="header">
            Single Radio Example
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component</CDBBadge
            >
          </div>
          <div class="description"></div>
          <div class="example2">
            <CDBRadio value="Choice 1" fill="#eb7434" colorfill="#eb7434"></CDBRadio>
            <CDBRadio value="Choice 2" fill="green" colorfill="red"></CDBRadio>
            <CDBRadio value="Choice 3" fill="#000000" colorfill="#000000"></CDBRadio>
          </div>
        </div>
        <div class="blockcode">
          <div class="header">
            Radio Group
            <CDBBadge style="background-color: #9871ff" size="large"
              >Pro Component
            </CDBBadge>
          </div>
          <div class="description"></div>
          <div class="example2">
            <CDBRadioGroup
              fill="#eb7434"
              colorfill="#eb7434"
              [answers]="['Choice 1', 'Choice 2', 'Choice 3', 'Choice 4']"
            >
            </CDBRadioGroup>
          </div>
        </div>
      </section>
</page-layout>