<div class="hero4">
    <header class="navigation">
        <CDBNavbar class="px-0 bg-transparent" [light]=true expand="md" [scrolling]=true>
            <CDBNavBrand class="navbar-expand-md mx-auto" href="/">
                <img alt="logo" src="/assets/img/pages/logo.png" width="25px" class="navbar-toggler p-0 border-0" />
            </CDBNavBrand>
            <CDBNavToggle (click)="content.toggleCollapse()"></CDBNavToggle>
            <CDBCollapse #content [navbar]=true expand="md" class="w-100">
                <ul class="navbar-nav mx-auto">
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Resources</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Blog</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavBrand href="/" class="d-none d-md-block my-auto mx-0 px-4 inner-logo">
                        <img alt="logo" src="/assets/img/pages/logo.png" width="25px" />
                    </CDBNavBrand>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Contact</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                    <CDBNavItem>
                        <CDBBtn [flat]=true class="p-3 border-0 bg-transparent">
                            <CDBNavLink style="font-weight: 600;" to="#">Support</CDBNavLink>
                        </CDBBtn>
                    </CDBNavItem>
                </ul>
            </CDBCollapse>
        </CDBNavbar>
    </header>
    <div class="page-body">
        <CDBCardTitle class="h1 font-weight-bold">Introducing Contrast UI Kit</CDBCardTitle>
        <CDBCardText class="my-4 mx-auto" style="max-width: 550px">Lorem ipsum dolor sit amet, consectetur
            adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas
            nostrum quisquam eum porro a pariatur accusamus veniam.</CDBCardText>
        <CDBBtn [flat]=true class="m-auto"
            style="background: #8080ff; border: 2px #8080ff solid; width: 40%; max-width: 200px;">Explore</CDBBtn>
        <CDBIcon [fas]=true class="d-block mt-4" icon="angle-double-down"></CDBIcon>
        <img alt="sideImage" src="/assets/img/pages/promotionImage.png" class="image" />
    </div>
</div>