<page-layout>
    <div class="blockcode pt-5">
        <div class="header">Breadcrumb</div>
        <div class="description">Breadcrumb</div>
        <div class="d-block mt-4" style="width: 100%">
            <CDBBreadcrumb color="none">
                <li class="breadcrumb-item">
                    <CDBLink class="p-0" to="/breadcrumb">Home</CDBLink>
                </li>
                <li class="breadcrumb-item">
                    <CDBLink class="p-0" to="/breadcrumb">Library</CDBLink>
                </li>
                <li class="breadcrumb-item active">Data</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="none">
                <li class="breadcrumb-item">
                    <CDBLink class="p-0" to="/breadcrumb">Home</CDBLink>
                </li>
                <li class="breadcrumb-item active">Library</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="none">
                <li class="breadcrumb-item active">Home</li>
            </CDBBreadcrumb>
        </div>
    </div>
    
    <div class="blockcode">
        <div class="header">Breadcrumb with Icon</div>
        <div class="description">Breadcrumb with Icon
            <CDBBadge size="large" style="background-color: #9871ff">Pro Component</CDBBadge>
        </div>
        <div class="d-block mt-4" style="width: 100%">
            <CDBBreadcrumb color="none" class="align-items-center">
                <li class="breadcrumb-item">
                    <CDBLink class="p-0" to="/breadcrumb">Home</CDBLink>
                </li>
                <CDBIcon class="mx-2 text-muted" [fas]=true icon="angle-double-right"></CDBIcon>
                <li class="breadcrumb-item">
                    <CDBLink class="p-0" to="/breadcrumb">Library</CDBLink>
                </li>
                <CDBIcon class="mx-2 text-muted" [fas]=true icon="angle-double-right"></CDBIcon>
                <li class="breadcrumb-item active">Data</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="none" class="align-items-center">
                <li class="breadcrumb-item">
                    <CDBLink class="p-0" to="/breadcrumb">Home</CDBLink>
                </li>
                <CDBIcon class="mx-2 text-muted" [fas]=true icon="angle-double-right"></CDBIcon>
                <li class="breadcrumb-item active">Library</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="none">
                <li class="breadcrumb-item active">Home</li>
            </CDBBreadcrumb>
        </div>
    </div>
    
    <div class="blockcode">
        <div class="header">Breadcrumb with Colors
            <CDBBadge size="large" style="background-color: #9871ff">Pro Component</CDBBadge>
        </div>
        <div class="description">Breadcrumb with Colors</div>
        <div class="d-block mt-4" style="width: 100%">
            <CDBBreadcrumb color="dark" class="align-items-center text-white">
                <li class="breadcrumb-item">
                    <CDBLink class="p-0 text-white" to="/breadcrumb">Home</CDBLink>
                </li>
                <CDBIcon class="mx-2 text-muted" [fas]=true icon="angle-double-right"></CDBIcon>
                <li class="breadcrumb-item">
                    <CDBLink class="p-0 text-white" to="/breadcrumb">Library</CDBLink>
                </li>
                <CDBIcon class="mx-2 text-muted" [fas]=true icon="angle-double-right"></CDBIcon>
                <li class="breadcrumb-item">Data</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb class="align-items-center">
                <li class="breadcrumb-item">
                    <CDBLink class="p-0 text-white" to="/breadcrumb">Home</CDBLink>
                </li>
                <CDBIcon class="mx-2 text-muted" [fas]=true icon="angle-double-right"></CDBIcon>
                <li class="breadcrumb-item">Library</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="info">
                <li class="breadcrumb-item">Home</li>
            </CDBBreadcrumb>
        </div>
    </div>
</page-layout>