<page-layout>
    <section id="buttontoolbar">
        <div class="blockcode pt-5">
          <div class="header">Multicolored Button Toolbar</div>
          <div class="description">Multicolored Button Toolbar</div>
          <div class="d-flex justify-content-center p-5 border">
            <CDBBtnTb>
              <CDBBtnGrp class="mr-2">
                <CDBBtn color="primary">click me</CDBBtn>
                <CDBBtn color="secondary">click me</CDBBtn>
                <CDBBtn color="success">click me</CDBBtn>
              </CDBBtnGrp>
              <CDBBtnGrp size="sm" class="mr-2">
                <CDBBtn color="primary">click me</CDBBtn>
                <CDBBtn color="secondary">click me</CDBBtn>
                <CDBBtn color="success">click me</CDBBtn>
              </CDBBtnGrp>
              <CDBBtnGrp size="lg">
                <CDBBtn color="danger">click me</CDBBtn>
                <CDBBtn color="dark">click me</CDBBtn>
                <CDBBtn color="warning">click me</CDBBtn>
              </CDBBtnGrp>
            </CDBBtnTb>
          </div>
        </div>
      </section>
</page-layout>