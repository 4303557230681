<div class="forgotPasswordPopUp">
    <div class="position-relative">
        <img alt="Background" src="../../../assets/img/pages/signIn.png" class="image" />
    </div>
    <div class="body">
        <CDBNavbar class="navigation bg-transparent" light expand="md" scrolling>
            <CDBNavBrand href="/" class="d-flex align-items-center">
                <img alt="logo" src="../../../assets/img/pages/logo.png" width="25px" />
                <span class="ml-3 h4 my-0 font-weight-bold">Devwares</span>
            </CDBNavBrand>
        </CDBNavbar>
        <CDBCard class="shadow-lg form">
            <CDBCardBody class="mx-5 py-5 px-0">
                <div class="mt-4 mb-5">
                    <p class="h4 font-weight-bold">Sign in</p>
                </div>
                <label htmlFor="defaultRegisterUsername" class="text-muted m-0">
                    Username
                </label>
                <CDBInput id="defaultRegisterUsername" background hint="Username" class="mt-n3" type="text" ></CDBInput>
                <label htmlFor="defaultRegisterPassword" class="text-muted m-0">
                    Password
                </label>
                <CDBInput id="defaultRegisterPassword" background hint="Password" class="mt-n3" type="password" ></CDBInput>
                <p (click)="modal1.toggleModal()" style="font-weight:600;cursor: pointer;" class="d-inline p-0 text-dark" to="#">
                    Forgot Password?
                </p>
                <CDBModal #modal1="cdbModal"  [centered]="true" >
                    <CDBCard>
                        <CDBBtn color="none" [flat]="true" (click)="modal1.toggleModal()" class="ms-auto border-0 mt-2 mr-2">
                            <CDBIcon [fas]='true' icon="times" ></CDBIcon>
                        </CDBBtn>
                        <CDBCardBody class="w-75 mx-auto modal-card">
                            <div class="mt-4 mb-5">
                                <p class="h3 font-weight-bold">Forgot Password</p>
                            </div>
                            <label htmlFor="defaultRegisterEmail" class="text-muted m-0">
                                Email Address
                            </label>
                            <CDBInput id="defaultRegisterEmail" hint="Email Address" class="mt-n3" background
                                type="email" ></CDBInput>
                            <CDBBtn color="dark" class="btn-block w-100 mb-3 mt-5">
                                Recover Password
                            </CDBBtn>
                            <p class="text-muted text-center">
                                Return to
                                <CDBLink style="font-weight:600" (click)="modal1.toggleModal()"
                                    class="d-inline ml-2 p-0 text-dark" to="#">
                                    Sign in
                                </CDBLink>
                            </p>
                        </CDBCardBody>
                    </CDBCard>
                </CDBModal>
                <CDBBtn color="dark" class="btn-block w-100 mb-3 mt-5">
                    Sign in
                </CDBBtn>
                <div class="d-flex align-items-center">
                    <span style="border:0.5px solid black; width:45%" ></span>
                    <span class="text-center" style="width:10%">OR</span>
                    <span style="border:0.5px solid black; width:45%" ></span>
                </div>
                <div class="row my-3 d-flex justify-content-center">
                    <CDBBtn size="sm" [flat]='true' color="dark">
                        <CDBIcon [fab]='true' icon="facebook-f" ></CDBIcon>
                    </CDBBtn>
                    <CDBBtn size="sm" color="dark" [flat]='true' class="mx-2">
                        <CDBIcon [fab]='true' icon="twitter" ></CDBIcon>
                    </CDBBtn>
                    <CDBBtn size="sm" [flat]='true' color="dark">
                        <CDBIcon [fab]='true' icon="google-plus-g" ></CDBIcon>
                    </CDBBtn>
                </div>
                <p class="text-muted text-center">Don't have an Account? <CDBLink style="font-weight:600"
                        class="d-inline p-0 text-dark" to="#">Sign Up</CDBLink>
                </p>
            </CDBCardBody>
        </CDBCard>
    </div>
</div>